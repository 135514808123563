import React, { Component } from 'react'
import Header from '../../components/Headers/Header';
import { Button, TabContent, TabPane, Nav, NavItem, NavLink, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge, Container } from 'reactstrap';
import { myTasks } from 'services/taskService'
import { getCultures } from 'services/cultureService';
import { getStatus } from 'services/roleService'
import { getZoomUser } from 'services/zoomUserService';
import ReactTable from 'react-table';
import "react-table/react-table.css";

export default class Index extends Component {
    constructor(){
        super();
        this.state = {
            tasksData: [],
            parentTaskData: [],
            userId: null,
            showTaskDetailAssets: [],
            vjTasks: [],
            editorTasks: [],
            activeTab: '1',
            approvedTasks: [],
            statusesData: [],
            cultureData: [],
        }

        this.token = window.zoomCrypt.get("token");
        this.network = window.zoomCrypt.get("networkId");
        this.sub = window.zoomCrypt.get("sub");
    }

    getStatus = async () => {
        const response = await getStatus(this.token);
        let initialStatuses = [];
            response.taskStatuses.forEach((role) => {
                initialStatuses.push({
                    role
                })
            })

            this.setState({
                statusesData: initialStatuses
            })
    }

    getCultures = async () =>{
        const response = await getCultures(this.token);
        if(response.success){
            let initialCultures = [];
            initialCultures = response.culture.map((culture) => {
                if(culture.IsActive){
                    return culture;
                }
            })

            this.setState({
                cultureData: initialCultures
            })
        }
    }

    async getTasks(){
        const response = await myTasks(this.token, this.network);

        let initialTasks = [];
        let initialapprovedTasks = [];
        let vjTasks = [];
        let editorTasks = [];
        let approvedTasks = [];

        if(response.message === undefined){
            initialTasks = response.filter(task => task.data.assignedUserId === this.state.userId || task.data.createdBy === this.state.userId);
            initialapprovedTasks = response.filter(task => task.data.assignedUserId === this.state.userId && task.data.status > 3);

            initialTasks.forEach((task) => {
                if(task.data.questType === "editor"){
                    editorTasks.push(task)
                }else if(task.data.questType === "vj"){
                    vjTasks.push(task)
                }
            })

            initialapprovedTasks.forEach((approvedTask) => {
                approvedTasks.push(approvedTask);
            })
        }

        this.setState({
            tasksData: initialTasks,
            vjTasks,
            editorTasks,
            approvedTasks
        })
    }

    async getUser(){
        const response = await getZoomUser(this.token, this.sub, this.network);
        if(response.success){
            this.setState({
                userId: response.user.Id
            })
        }
    }

    approvedTasks(){
        return this.state.approvedTasks.map((task, index) => {
            return (
                <ListGroupItem key={index} className="approvedTaskListItem">
                    <ListGroupItemHeading>{task.data.title}</ListGroupItemHeading>
                    <ListGroupItemText>
                        {task.data.description}
                    </ListGroupItemText>
                    <Badge className="taskType" color="primary" pill>Approved</Badge>
                </ListGroupItem>
            )
        })
    }

    onProcess = (taskId) => {
        this.props.history.push(`process-task/${taskId}`)
    }

    toggle = tab => {
        this.setState({
            activeTab: this.state.activeTab === '1' ? '2' : '1'
        })
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toString().toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    cultureNameConverter(cultureId) {
        return this.state.cultureData.map((culture) => {
            if(cultureId === culture?.Id){
                return culture.Name
            }
        })
    }

    async componentDidMount(){
        await this.getCultures();
        await this.getUser();
        await this.getTasks();
        await this.getStatus();
    }

    render() {
        const columns = [
            {
                Header: "Id",
                accessor: "id",
                filterable: true,
            },
            {
                Header: "Name",
                accessor: "data.title"
            },
            {
                Header: "Description",
                accessor: "data.description"
            },
            {
                Header: "Status",
                accessor: "data.status",
                filterable: false,
                Cell: props => {
                    return(
                    this.state.statusesData.map(s => props.original.data.status === s.role.id ?
                        s.role.name : "")
                    )
                }
            },
            {
                Header: "Language",
                accessor: "data.cultureId",
                Cell: props => {
                    return(
                        <span>{ this.cultureNameConverter(props.original.data.cultureId) }</span>
                    )
                },
                filterable: false
            },
            {
                Header: "Edit",
                Cell: props => {
                    return(
                       <Button onClick={() => this.onProcess(props.original.id + '?task=' + props.original.data.questType)} color="primary">Edit</Button>
                    )
                },
                accessor: "edit",
                filterable: false,
                sortable: false
            },
        ]

        let tabClass = "active:" + this.state.activeTab === '1';
        return (
            <div>
                <Header></Header>
                <Container>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={tabClass.toString()}
                                onClick={() => { this.toggle('1'); }}
                            >
                                Open Tasks
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={tabClass.toString()}
                                onClick={() => { this.toggle('2'); }}
                            >
                                Approved Tasks
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <ListGroup>
                            <ReactTable
                                className="zoom-table"
                                columns={columns}
                                filterable
                                defaultFilterMethod={this.filterCaseInsensitive}
                                showPaginationTop={true}
                                data={this.state.tasksData}>
                            </ReactTable>
                            </ListGroup>
                        </TabPane>
                        <TabPane tabId="2">
                            <ListGroup>
                                {this.approvedTasks()}
                            </ListGroup>
                        </TabPane>
                    </TabContent>
                </Container>
            </div>
        )
    }
}
