import React, { Component } from 'react'
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Header from '../../components/Headers/Header';
import { getPublisherById, updatePublisher } from 'services/publisherService';
import { getNetworks } from 'services/networkService';
import Select from 'react-select'

export default class Edit extends Component {

    constructor(props){
        super(props);
        this.state = {
            networkData: [],
            publisherName: "",
            pid: "",
            currentNetworkId: 0,
            selectedNetwork: null,
            active: null,
        }
        this.token = window.zoomCrypt.get("token")
        this.networkId = window.zoomCrypt.get("networkId")
        this.publisherId = this.props.match.params.id;
    }

    onUpdateClick = async e => {
        e && e.preventDefault();
        const response = await updatePublisher(this.token,
                                               this.networkId,
                                               this.publisherId,
                                               this.state.pid,
                                               this.state.publisherName,
                                               this.state.selectedPublisherStatus.value,
                                               this.state.selectedNetwork.value);
        if(response.status === 204){
            this.refreshPage();
        }
    }

    getCategory = async () => {
        const response = await getPublisherById(this.token, this.publisherId);
        if(response.success){
            this.setState({
                publisherName: response.publisher.Name,
                pid: response.publisher.Pid,
                active: response.publisher.IsActive,
                selectedPublisherStatus: {
                    value: response.publisher.IsActive,
                    label: response.publisher.IsActive ? "True" : "False"
                },
                currentNetworkId: response.publisher.NetworkId
            })
        }
    }

    getCultures = async () => {
        const response = await getNetworks(this.token);
        let initialNetworks = [];
        if(response.success){
            response.networks.map((network) => {
                initialNetworks.push({
                    value: network.Id,
                    label: network.Name,
                })
            })

            this.setState({
                networkData: initialNetworks
            })
        }
    }

    handleChangeName = e => {
        let publisherName = e.target.value;
        this.setState({ publisherName });
    }

    handleChangePID = e => {
        let pid = e.target.value;
        this.setState({ pid });
    }

    handleChangeStatus = selectedPublisherStatus => {
        this.setState({ selectedPublisherStatus });
    }

    handleChangeNetwork = selectedNetwork => {
        this.setState({ selectedNetwork });
    }

    refreshPage() {
        this.props.history.push('/admin/publishers');
    }

    async componentDidMount(){
        await this.getCategory();
        await this.getCultures();

        const currentNetwork = this.state.networkData.find(c => { return c.value === this.state.currentNetworkId })

        if(currentNetwork !== undefined){
            this.setState({
                selectedNetwork: {
                    value: currentNetwork.value,
                    label: currentNetwork.label
                }
            })
        }
    }

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }

    render() {
        const { selectedNetwork } = this.state;
        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                    <Form>
                        <FormGroup>
                            <Label for="publisher">Publisher Name</Label>
                            <Input type="text" name="publisherName" id="publisher_name" placeholder="Publisher Name" value={this.state.publisherName} onChange={this.handleChangeName}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="publisher">PID</Label>
                            <Input type="text" name="pid" id="p_id" placeholder="PID" value={this.state.pid} onChange={this.handleChangePID}/>
                        </FormGroup>
                        <FormGroup check className="mb-3">
                            <Label check>
                            <Input type="checkbox" checked={this.state.active || false} onChange={this.handleChangeActive}/>{' '}
                                Active
                            </Label>
                        </FormGroup>
                        <FormGroup>
                            <Label for="publisher">Publisher Network</Label>
                            <Select
                                value={selectedNetwork}
                                options={this.state.networkData}
                                onChange={this.handleChangeNetwork}
                            ></Select>
                        </FormGroup>
                        <Button onClick={this.onUpdateClick}>Update Publisher</Button>
                    </Form>
                </Container>
            </div>
        )
    }
}
