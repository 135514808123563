import React, { Component } from 'react'
import { Button, Container, Form, FormGroup, Label, Input } from 'reactstrap';
import Header from '../../components/Headers/Header';
import GeneralModal from '../../components/Modals/GeneralModal';
import { getPublishers, removePublisher, createPublisher } from 'services/publisherService';
import { getNetworks } from 'services/networkService'
import ReactTable from 'react-table';
import Select from 'react-select';
import "react-table/react-table.css";
import { withAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Index extends Component {

    constructor(){
        super();
        this.state = this.defaultState();

        this.token = window.zoomCrypt.get("token")
        this.networkId = window.zoomCrypt.get("networkId")
    }

    defaultState() {
        return {
            publisherData: [],
            networkData: [],
            openModal: false,
            publisherName: "",
            active: true,
            selectedNetwork: null
        };
    }

    refreshPage() {
        this.setState(this.defaultState(), () => this.getDatas()); 
    }

    toggle = () => {
		this.setState({openModal: !this.state.openModal})
    }

    getPublishers = async () => {
        const response = await getPublishers(this.token);
        let initialPublishers = [];
        if(response.success){
            response.publishers.map((publisher) => {
                initialPublishers.push({
                    id: publisher.Id,
                    networkId: publisher.NetworkId,
                    pid: publisher.Pid,
                    name: publisher.Name,
                    isActive: publisher.IsActive
                })
            })

            this.setState({
                publisherData: initialPublishers
            })
        }
    }

    getNetworks = async () => {
        const response = await getNetworks(this.token);
        let initialNetworks = [];
        if(response.success){
            response.networks.map((network) => {
                initialNetworks.push({
                    value: network.Id,
                    label: network.Name,
                })
            })

            this.setState({
                networkData: initialNetworks
            })
        }
    }

    onSaveClick = async e => {
        e && e.preventDefault();
        const alert = this.props.alert;
        if(this.state.selectedNetwork !== null){
            const response = await createPublisher(this.token,
                this.state.selectedNetwork.value,
                this.state.pid,
                this.state.publisherName,
                this.state.active);

                if(response.status === 200){
                    this.refreshPage();
                }                
                else {
                alert.show('An error occured while trying to delete. Please try again')
                }
        }else {            
            alert.show('Please fill in all fields !')
        }
    }

    confirm = (assetId) => {
        confirmAlert({
            title: 'Remove Publisher',
            message: 'Are you sure you want to delete ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.remove(assetId)
            },
            {
                label: 'No',
            }
            ]
        });
    };

    remove = async (assetId) => {
        const alert = this.props.alert;
        const response = await removePublisher(this.token, this.networkId, assetId);
        if(response.status === 204)
            this.refreshPage();
        else            
            alert.show('An error occured while trying to delete. Please try again');
    }

    handleChangeName = e => {
        let publisherName = e.target.value;
        this.setState({ publisherName });
    }

    handleChangePID = e => {
        let pid = e.target.value;
        this.setState({ pid });
    }

    handleChangeStatus = selectedPublisherStatus => {
        this.setState({ selectedPublisherStatus });
    }

    handleChangeNetwork = selectedNetwork => {
        this.setState({ selectedNetwork });
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    goEdit = (pId) => {
        this.props.history.push(`publisher/edit/${pId}`)
    }

    getDatas() {
        this.getPublishers();
        this.getNetworks();
    } 

    componentDidMount(){
        this.getDatas();
    }

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }

    render() {
        const columns = [
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "PID",
                accessor: "pid"
            },
            {
                Header: "Edit",
                Cell: props => {
                    return(
                        <Button onClick={() => this.goEdit(props.original.id)} color="primary">Edit</Button>
                    )
                },
                accessor: "edit",
                filterable: false,
                sortable: false
            },
            {
                Header: "Remove",
                Cell: props => {
                    return(
                        <Button onClick={() => this.confirm(props.original.id)} color="danger">Remove</Button>
                    )
                },
                accessor: "remove",
                filterable: false,
                sortable: false
            }
        ]

        const { selectedNetwork } = this.state;
        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                    <ReactTable
                        className="zoom-table"
                        columns={columns}
                        filterable
                        defaultFilterMethod={this.filterCaseInsensitive}
                        showPaginationTop={true}
                        data={this.state.publisherData}>
                    </ReactTable>
                    <GeneralModal
                        isOpen={this.state.openModal}
                        toggle={this.toggle}
                        title='Create Publisher'
                        primaryButton='Create'
                        className='modal-content bg-light'
                        secondaryButton='Cancel'
                        onAction={this.onSaveClick}>
                        <Form>
                            <FormGroup>
                                <Label for="publisher">Publisher Name</Label>
                                <Input type="text" name="publisherName" id="publisher_name" placeholder="Publisher Name" onChange={this.handleChangeName}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="publisher">PID</Label>
                                <Input type="text" name="pid" id="p_id" placeholder="PID" onChange={this.handleChangePID}/>
                            </FormGroup>
                            <FormGroup check className="mb-3">
                                <Label check>
                                <Input type="checkbox" checked={this.state.active || false} onChange={this.handleChangeActive}/>{' '}
                                    Active
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <Label for="publisher">Publisher Network</Label>
                                <Select
                                    value={selectedNetwork}
                                    options={this.state.networkData}
                                    onChange={this.handleChangeNetwork}
                                ></Select>
                            </FormGroup>
                        </Form>
                    </GeneralModal>
                    <Button className="create-button" color="success" onClick={this.toggle}>
                        Create Publisher
                    </Button>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Index)