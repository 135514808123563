import React, { Component } from 'react'
import Header from '../../components/Headers/Header';
import { Button, ListGroup, Container } from 'reactstrap';
import { getPublisherSites, deletePublisherSite } from 'services/publisherSiteService';
import { getZoomUser } from 'services/zoomUserService';
import ReactTable from 'react-table';
import { withAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import "react-table/react-table.css";

class Index extends Component {
    constructor() {
        super();
        this.state = {
            sitesData: [],
            userId: null,
            publisherSiteId: null
        }

        this.token = window.zoomCrypt.get("token");
        this.network = window.zoomCrypt.get("networkId");
        this.sub = window.zoomCrypt.get("sub");
    }



    async getSites() {
        const response = await getPublisherSites(this.token);
        if (response.success) {
            this.setState({
                sitesData: response.publisherSite
            })
        }

    }

    async getUser() {
        const response = await getZoomUser(this.token, this.sub, this.network);
        if (response.success) {
            this.setState({
                userId: response.user.Id
            })
        }
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toString().toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    }

    toggle = () => {
		this.props.history.push('/admin/whitelabel/edit/');
    }

    refreshPage() {
        this.getSites();
    }

    confirmRemove = (id) => {
        confirmAlert({
            title: 'Remove whitelist',
            message: 'Are you sure you want to delete this whitelist?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.setState({publisherSiteId: id}, () => this.onRemoveClick()) 
            },
            {
                label: 'No',
            }
            ]
        });
    };

    onRemoveClick = async e => {
        e && e.preventDefault();
        
        if(this.state.publisherSiteId !== undefined) {
            let response = await deletePublisherSite(this.token, this.state.publisherSiteId);

            if(response.status === 200){
                this.setState({publisherSiteId: null})
                this.refreshPage();
            }
        }                                   
        
    }



    componentDidMount() {
        this.getSites();
    }

    render() {
        const columns = [
            {
                Header: "Network",
                accessor: "NetworkName",
                filterable: true,
            },
            {
                Header: "Publisher",
                accessor: "PublisherName",
                filterable: true,
            },
            {
                Header: "Site Url",
                accessor: "siteurl",
                filterable: true,
            },
            {
                Header: "Edit",
                Cell: props => {
                    return (
                        <a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push(`whitelabel/edit/${props.original.id}`)}}> <Button color="primary">Edit</Button></a>
                    )
                },
                accessor: "edit",
                filterable: false,
                sortable: false
            },
            {
                Header: "Remove",
                Cell: props => {
                    return (
                        <Button color="danger" onClick={() => this.confirmRemove(props.original.id)}>Remove</Button>
                    )
                },
                accessor: "edit",
                filterable: false,
                sortable: false
            }
        ]
        
        return (
            <div>
                <Header></Header>
                <Container>
                    <ListGroup>
                        <ReactTable
                            className="zoom-table"
                            columns={columns}
                            filterable
                            defaultFilterMethod={this.filterCaseInsensitive}
                            showPaginationTop={true}
                            data={this.state.sitesData}>
                        </ReactTable>
                    </ListGroup>
                    <a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push(`whitelabel/create`)}}>
                        <Button className="create-button" color="success" onClick={this.toggle}>
                            Create Site
                        </Button>
                    </a>
                     
                </Container>
            </div>
        )
    }
}

export default withAlert()(Index)