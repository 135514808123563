import React from "react";
import { Container, Row, Col, Button, Card, CardHeader, CardBody } from 'reactstrap';
import CookieConsent from "react-cookie-consent";
import Header from "components/Headers/UserHeader.js";
import GeneralModal from "components/Modals/GeneralModal";
import { getZoomUserAuth, updateUserGdprAcceptDate } from '../services/zoomUserService'
import { cdnPath } from "../services/config"

import Select from 'react-select';

class Index extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      networkData: [],
      openModal: false,
      selectedNetwork: null,
    };
    this.token = window.zoomCrypt.get("token");
    this.sub = window.zoomCrypt.get("sub");
    this.network = window.zoomCrypt.get("networkId");
  }

  getUserNetworks = async () => {
    const response = await getZoomUserAuth(this.token, this.sub);

    if(response.success){
      let initialNetworks = [];

      response.user.Networks.forEach((network) => {
        initialNetworks.push({
          label: network.NetworkName,
          value: network.NetworkId,
          role: network.RoleName
        })
      })

      this.setState({
          networkData: initialNetworks
      })
    }
  }

  handleChangeNetwork = selectedNetwork => {
    this.setState({ selectedNetwork })
  }

  toggle = () => {
    this.setState({openModal: !this.state.openModal})
  }

  refreshPage() {
    window.location.reload();
  }

  onSaveClick = async () => {
    await this.getUserNetworks();

    window.zoomCrypt.set("networkId", this.state.selectedNetwork.value);
    window.zoomCrypt.set("networkName", this.state.selectedNetwork.label);
    window.zoomCrypt.set("roleName", this.state.selectedNetwork.role);

    this.refreshPage();
  }

  onGdprAcceptClick = async () => {    
    const response = await updateUserGdprAcceptDate(this.token, this.sub);
    if(response.status !== 200)
      document.cookie = "ZoominGdprAccept=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  async componentDidMount(){
    this.getUserNetworks();
  }

  render() {
    return (
      <div>
        <Header/>
        <Container className="mt-4">
          <GeneralModal isOpen={this.state.openModal}
                                toggle={this.toggle}
                                title='Select Network'
                                primaryButton='Save'
                                className='modal-content bg-light'
                                secondaryButton='Cancel'
                                onAction={this.onSaveClick}>
          <Select
          value={ this.state.selectedNetwork }
          name="networks"
          options={ this.state.networkData }
          onChange={this.handleChangeNetwork}
          />
        </GeneralModal>
          <Row>
            <Col md="6">
              <Card>
                <CardHeader>Network Select</CardHeader>
                  <CardBody>
                    <Button onClick={this.toggle}>Change Network</Button>
                  </CardBody>
              </Card>
              </Col>
            {this.network === 1 ? 
            <Col md="6">
              <Card>
                <CardHeader>Tasks</CardHeader>
                  <CardBody>
                    <Button onClick={() => this.props.history.replace('/admin/my-tasks')}>Show tasks</Button>
                  </CardBody>
              </Card>
            </Col> : ''}
          </Row>
        </Container>
        <CookieConsent overlay buttonText="Accept" cookieName="ZoominGdprAccept" onAccept={this.onGdprAcceptClick}>
          By using Zoomin CMS, you agree with our <a style={{"color":"yellow", "text-decoration": "underline"}} href={cdnPath + "/zoomin/gdpr/FINAL_PUBLISHER_VIDEO_LICENSOR_AGREEMENT_1_0.pdf"} target="_blank">Publisher</a> and <a style={{"color":"yellow", "text-decoration": "underline"}} href={cdnPath + "/zoomin/gdpr/VIDEO_CONTENT_PROVIDER_LICENSE_AGREEMENT_FINAL_VERSION_1_0.pdf"} target="_blank">Video Content Provider</a> privacy policy.
        </CookieConsent>
      </div>
    );
  }
}

export default Index;
