import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { useKeycloak } from '@react-keycloak/web'

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/zoomin-cms.css";

import AdminLayout from "layouts/Admin";
import LoginArea from "views/Global/LoginArea";
import Callback from "./Callback";

import SecureLS from 'secure-ls';

window.zoomCrypt = new SecureLS({encodingType: 'aes'});

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 9999
  }
}

export const AppRouter = (props) => {
  const { initialized, keycloak } = useKeycloak();

  if (!initialized) {
    return <div></div>
  }
  else {
    return (
      <AlertProvider template={AlertTemplate} {...options}>
        <BrowserRouter>
          <Switch>
            <Route path="/admin">
              {keycloak?.authenticated !== true ? <Redirect to="/" /> : <Route path="/admin" render={props => <AdminLayout {...props} keycloak={keycloak} />} />}
            </Route>
            <Route exact path='/callback' component={Callback}/>
            <Route from="/" component={LoginArea} />
          </Switch>
        </BrowserRouter>
      </AlertProvider>
    )
  }

  
}

