import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { withKeycloak } from '@react-keycloak/web'

class Callback extends Component {


  handleAuthentication = () => {
    

    return new Promise((resolve, reject) => {
      if(this.props.keycloak?.authenticated) {
        
        window.zoomCrypt.set("token", this.props.keycloak.token);
        window.zoomCrypt.set("user", this.props.keycloak.tokenParsed.name);
        window.zoomCrypt.set("sub", this.props.keycloak.tokenParsed.sub);
        //window.zoomCrypt.set("avatar", keycloak.idTokenPayload.picture);

        this.accessToken = this.props.keycloak.token;
        //this.profile = authResult.idTokenPayload;
        this.expiresAt = this.props.keycloak.tokenParsed.exp * 1000;
        resolve();
      }
      else {
        reject();
      }

    })
  }

  handleAuth = async () => {
    await this.handleAuthentication();
    this.props.history.replace('/admin/index');
  }

  async componentDidMount() {
    await this.handleAuth();
  }

  render() {
    return (
      <Spinner color="primary" />
    );
  }
}

export default withKeycloak(withRouter(Callback));