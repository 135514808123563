import React, { Component } from 'react'
import Header from '../../components/Headers/Header';
import { Button, Container, Form, FormGroup, Label, Input, Badge } from 'reactstrap';
import GeneralModal from '../../components/Modals/GeneralModal';
import { getCategoryByCultureId } from 'services/categoryService';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import Select from 'react-select';
import { getStatus } from 'services/roleService'
import CreatableSelect from 'react-select/creatable';
import { getTasks, assignTask, getTaskById, createTask } from 'services/taskService'
import { getCultures } from 'services/cultureService';
import { getCountry } from 'services/roleService'
import { getZoomUsers } from 'services/zoomUserService';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { withAlert } from 'react-alert';
import EllipsisText from "react-ellipsis-text";

class Index extends Component {
    constructor(){
        super();
        this.state = this.defaultState();

        this.token = window.zoomCrypt.get("token");
        this.network = window.zoomCrypt.get("networkId");
    }

    defaultState() {
        return {
            tasksData: [],
            usersData: [],
            cultureData: [],
            taskDetail: {},
            taskEditData: {},
            clickedTaskId: null,
            openCreateModal: false,
            openAssignModal: false,
            openEditModal: false,
            openSubtaskModal: false,
            selectedAssignUser: null,
            editorAssign: false,
            taskCreateTitle: "",
            taskCreateDescription: "",
            taskCreateCountry: "",
            taskCreateCity: "",
            taskCreateIdea: "",
            categories: [],
            keywords: [],
            selectedCategory: null,
            selectedKeywords: null,
            selectedCountry: null,
            selectedNativeLanguage: null,
            categoryOptions: [],
            countryData: [],
            statusesData: [],
            selectedStatus: undefined
        };
    }

    getStatus = async () => {
        const response = await getStatus(this.token);
        let initialStatuses = [];
            response.taskStatuses.forEach((taskStatus) => {
                initialStatuses.push({
                    taskStatus
                })
            })

            this.setState({
                statusesData: initialStatuses
            })
    }

    confirm = () => {
        confirmAlert({
            title: 'Create Task',
            message: 'Are you sure you want to create ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.onCreateClick()
            },
            {
                label: 'No',
            }
            ]
        });
    };

    getCulture = async () =>{
        const token = window.zoomCrypt.get("token");
        const response = await getCultures(token);
        if(response.success){
            let initialCultures = [];
            response.culture.map((culture) => {
                if(culture.IsActive){
                    initialCultures.push({
                        label: culture.Name,
                        value: culture.Id,
                    })
                }
            })

            this.setState({
                cultureData: initialCultures.sort((a, b) => a.label.localeCompare(b.label))
            })
        }
    }

    refreshPage() {
        this.setState(this.defaultState(), () => this.componentDidMount())
        //this.getTasks();
    }

    createToggle = () => {
		this.setState({openCreateModal: !this.state.openCreateModal})
    }

    assignToggle = (taskId, editor) => {
        if(editor){
            this.setState({
                openAssignModal: !this.state.openAssignModal,
                clickedTaskId: taskId,
                editorAssign: true,
            })
        }else {
            this.setState({
                openAssignModal: !this.state.openAssignModal,
                clickedTaskId: taskId,
                editorAssign: false,
            })
        }
    }

    editToggle = (taskId) => {
		this.setState({
            openEditModal: !this.state.openEditModal,
            clickedTaskId: taskId
        }, () => {
            if(this.state.openEditModal){
                this.getTaskById();
            }
        })
    }

    async getTasks(){
        const response = await getTasks(this.token, this.network, this.state.selectedStatus);
        if(response.length > 0){
            let initialTasks = [];
            let gridTaskData = [];
            let initialSubtasks = [];
            initialTasks = response.filter(task => task.data.type === "task");
            initialSubtasks = response.filter(task => task.data.type === "subtask");

            initialTasks.map((task) => {
                let s = this.state.statusesData.find(s => task.data.status === s.taskStatus.id);
                gridTaskData.push({
                        id: task.id,
                        title: task.data.title,
                        description: task.data.description,
                        status: s?.taskStatus?.name
                })
            })

            this.setState({
                tasksData: gridTaskData,
                subtasksData: initialSubtasks,
            })
        }
    }

    async getTaskById(){
        const response = await getTaskById(this.token, this.network, this.state.clickedTaskId);
        this.setState({
            taskEditData: response.data
        })
    }

    async getCountry(){
        const response = await getCountry(this.token);
        let initCountry = [];
        response.countries.forEach((c) => {
            initCountry.push({
                label: c.Name,
                value: c.Id
            })
        })
        this.setState({
            countryData: initCountry
        })
    }

    onAssignClick = async e => {
        e && e.preventDefault();
        const response = await assignTask(this.token, this.network, this.state.clickedTaskId, this.state.selectedAssignUser.value);

        if(response.status === 200){
            this.refreshPage();
        }
        else{
            const alert = this.props.alert;
            alert.show('An error occured while assigning the task')
          }
    }

    onCreateClick = async e => {
        e && e.preventDefault();
        const alert = this.props.alert;
        if(this.state.taskCreateTitle !== "" && this.state.taskCreateDescription !== ""
           && this.state.categories.length > 0 && this.state.keywords.length > 0
           && this.state.taskCreateIdea !== "" && this.state.taskCreateCity !== ""
           && this.state.selectedCountry !== null && this.state.selectedNativeLanguage !== null){
            await createTask(this.token,
                this.network,
                this.state.taskCreateTitle,
                this.state.taskCreateDescription,
                this.state.keywords,
                this.state.categories,
                this.state.taskCreateIdea,
                this.state.taskCreateCity,
                this.state.selectedCountry.label,
                this.state.selectedNativeLanguage.value);

                this.refreshPage();
        }else {
            alert.show("Please fill all fields !")
        }
    }

    getCategories = async () => {
        const responseCategory = await getCategoryByCultureId(this.token, 8);
        let initialCategories = [];
            responseCategory.category.sort((a, b) => a.Name.localeCompare(b.Name)).map((category) => {
                initialCategories.push({
                    value: category.Id,
                    label: category.IsIab ? `${category.Name} ${category.ShortName}` : category.Name
                })
            })

        this.setState({
            categoryOptions: initialCategories,
        })
    }

    handleChangeAssignUser = selectedAssignUser => {
        this.setState({ selectedAssignUser });
    }

    handleChangeStatus = selectedStatus => {
        this.setState({ selectedStatus }, () => this.getTasks());
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    async getUsers(){
        const response = await getZoomUsers(this.token, this.network);

        let initialUsers = [];
        if(response.status === 200){
            response.users.forEach((user) => {
                initialUsers.push({
                    label: user.firstname + ' ' + user.lastname,
                    value: user.Id,
                    roleid: user.roleid,
                })
            })
        }

        this.setState({
            usersData: initialUsers
        })
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toString().toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    handleChangeCategory = selectedCategory => {
        this.setState({ selectedCategory })

        let initCategoryLabel = [];

        if(selectedCategory?.length > 0){
            selectedCategory.map((category) => {
                initCategoryLabel.push(category.value)
            })
        }

        this.setState({ categories: initCategoryLabel });
    }

    handleChangeCountry = selectedCountry => {
        this.setState({ selectedCountry })
    }

    handleChangeNativeLanguage = selectedNativeLanguage => {
        this.setState({ selectedNativeLanguage })
    }

    handleChangeKeywords = selectedKeywords => {
        this.setState({ selectedKeywords })

        let initKeywordLabel = [];

        if(selectedKeywords?.length > 0){
            selectedKeywords.map((keyword) => {
                initKeywordLabel.push(keyword.label)
            })
        }

        this.setState({ keywords: initKeywordLabel });
    }

    componentDidMount(){
        this.getStatus();
        this.getTasks();
        this.getUsers();
        this.getCategories();
        this.getCountry();
        this.getCulture();
    }

    render() {
        const MAX_CHAR = 45;
        const columns = [
            {
                Header: "ID",
                accessor: "id",
                filterable: true,
            },
            {
                Header: "Title",
                accessor: "title",
                Cell: props => {
                    return(
                        <EllipsisText text={ props.original.title } length={MAX_CHAR} />
                    )
                }
            },
            {
                Header: "Description",
                accessor: "description",
                Cell: props => {
                    return(
                        <EllipsisText text={ props.original.description } length={MAX_CHAR} />
                    )
                }
            },
            {
                Header: "Status",
                accessor: "status",
            },
            {
                Header: "Edit",
                Cell: () => {
                    return(
                        <Button color="primary">Edit</Button>
                    )
                },
                accessor: "edit",
                filterable: false,
                sortable: false
            },
        ]

        const onRowClick = (state, rowInfo) => {
            return {
                onClick: e => {
                    this.props.history.push(`parent-detail/${rowInfo.original.id}`)
                }
            }
        }

        return (
            <div>
                <Header></Header>
                <Container>
                <GeneralModal
                        isOpen={this.state.openCreateModal}
                        toggle={this.createToggle}
                        title='Create Task'
                        primaryButton='Create'
                        className='modal-content bg-light'
                        secondaryButton='Cancel'
                        onAction={this.confirm}>
                        <Form>
                            <FormGroup>
                                <Label for="task">Task Title <Badge color="danger" pill>*required</Badge></Label>
                                <Input type="text" name="taskCreateTitle" id="task_create_title" onChange={this.handleChange}/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="task">Task Description <Badge color="danger" pill>*required</Badge></Label>
                                <Input type="text" name="taskCreateDescription" id="task_createdesc" onChange={this.handleChange}/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="task">Task City <Badge color="danger" pill>*required</Badge></Label>
                                <Input type="text" name="taskCreateCity" id="task_create_city" onChange={this.handleChange}/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="task">Task Country <Badge color="danger" pill>*required</Badge></Label>
                                <Select
                                    name="country"
                                    placeholder="Select Country"
                                    value={this.state.selectedCountry}
                                    options={ this.state.countryData }
                                    onChange={ this.handleChangeCountry }
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="task">Native Language <Badge color="danger" pill>*required</Badge></Label>
                                <Select
                                    name="country"
                                    placeholder="Select Native Language"
                                    value={this.state.selectedNativeLanguage}
                                    options={ this.state.cultureData }
                                    onChange={ this.handleChangeNativeLanguage }
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="task">Idea Originator <Badge color="danger" pill>*required</Badge></Label>
                                <Input type="text" name="taskCreateIdea" id="task_create_idea" onChange={this.handleChange}/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="categories">Select Category <Badge color="danger" pill>*required</Badge></Label>
                                <Select
                                    isMulti
                                    value={this.state.selectedCategory}
                                    options={this.state.categoryOptions}
                                    onChange={this.handleChangeCategory}
                                ></Select>
                            </FormGroup>

                            <FormGroup>
                                <Label for="categories">Select Keywords <Badge color="danger" pill>*required</Badge></Label>
                                <CreatableSelect
                                    isMulti
                                    placeholder="Write and Create"
                                    value={this.state.selectedKeywords}
                                    onChange={this.handleChangeKeywords}
                                    components={
                                        {
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null
                                        }
                                    }
                                ></CreatableSelect>
                            </FormGroup>
                        </Form>
                </GeneralModal>

                <GeneralModal
                        isOpen={this.state.openAssignModal}
                        toggle={this.assignToggle}
                        title='Assign Task'
                        primaryButton='Assign'
                        className='modal-content bg-light'
                        secondaryButton='Cancel'
                        onAction={this.onAssignClick}>

                        <Select
                            value={this.selectedAssignUser}
                            options={this.state.editorAssign ? this.state.usersData.filter(user => user.roleid === 4) : this.state.usersData}
                            onChange={this.handleChangeAssignUser}
                        ></Select>

                </GeneralModal>
                    <Label for="categories">Task Status</Label>
                    <Select
                        value={this.state.selectedStatus}
                        options={[
                            {label: 'Created', value: 1},
                            {label: 'Assigned to VJ', value: 2},
                            {label: 'Ready to Approve (VJ)', value: 3},
                            {label: 'Approved (VJ)', value: 4},
                            {label: 'Assigned to Editor', value: 5},
                            {label: 'Ready to Approve (Editor)', value: 6},
                            {label: 'Approved (Editor)', value: 7}
                        ]}
                        onChange={this.handleChangeStatus}
                    ></Select>
                    <hr></hr>
                    <div className="tasks-area">
                        <div className="task-table">
                            <ReactTable
                                className="zoom-table"
                                columns={columns}
                                filterable
                                defaultFilterMethod={this.filterCaseInsensitive}
                                showPaginationTop={true}
                                getTrProps={onRowClick}
                                data={this.state.tasksData}>
                            </ReactTable>
                        </div>
                    </div>
                    <Button className="create-button" color="success" onClick={this.createToggle}>
                        Create Task
                    </Button>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Index)