import { zoomCmsAPI } from './config';
import { validateResponse } from './validateResponse';

export async function getRoles(token){
    const response = await fetch(`${zoomCmsAPI}/helper/role`, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function getStatus(token){
    const response = await fetch(`${zoomCmsAPI}/helper/taskstatus`, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function getCountry(token){
    const response = await fetch(`${zoomCmsAPI}/helper/country`, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

