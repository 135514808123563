import React, { Component } from 'react'
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Header from '../../components/Headers/Header';
import { getCategoryById, updateCategory } from 'services/categoryService';
import { getCultures } from 'services/cultureService';
import Select from 'react-select'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class Edit extends Component {

    constructor(props){
        super(props);
        this.state = {
            cultureData: [],
            categoryName: "",
            shortName: "",
            currentCultureId: 0,
            selectedCulture: null,
            active: null
        }
        this.token = window.zoomCrypt.get("token")
        this.categoryId = this.props.match.params.id;
    }

    confirmUpdate = () => {
        confirmAlert({
            title: 'Update Category',
            message: 'Are you sure you want to update ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.onUpdateClick()
            },
            {
                label: 'No',
            }
            ]
        });
    };

    onUpdateClick = async e => {
        e && e.preventDefault();
        const response = await updateCategory(this.token,
                                              this.categoryId,
                                              this.state.categoryName,
                                              this.state.shortName,
                                              this.state.active,
                                              this.state.selectedCulture.value);
        if(response.status === 204){
            this.refreshPage();
        }
    }

    getCategory = async () => {
        const response = await getCategoryById(this.token, this.categoryId);
        if(response.success){
            this.setState({
                categoryName: response.category.Name,
                shortName: response.category.ShortName,
                active: response.category.IsActive,
                selectedCategoryStatus: {
                    value: response.category.IsActive,
                    label: response.category.IsActive ? "True" : "False"
                },
                currentCultureId: response.category.CultureId
            })
        }
    }

    getCultures = async () => {
        const response = await getCultures(this.token);
        let initialCultures = [];
        if(response.success){
            response.culture.map((culture) => {
                initialCultures.push({
                    value: culture.Id,
                    label: culture.Name,
                })
            })

            this.setState({
                cultureData: initialCultures
            })
        }
    }

    handleChangeName = e => {
        let categoryName = e.target.value;
        this.setState({ categoryName });
    }

    handleChangeShortname = e => {
        let shortName = e.target.value;
        this.setState({ shortName });
    }

    handleChangeStatus = e => {
        this.setState({ selectedCategoryStatus: e });
    }

    handleChangeCulture = selectedCulture => {
        this.setState({ selectedCulture });
    }

    refreshPage() {
        this.props.history.push('/admin/categories');
        
    }

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }

    async componentDidMount(){
        await this.getCategory();
        await this.getCultures();

        const currentCulture = this.state.cultureData.find(c => { return c.value === this.state.currentCultureId })

        if(currentCulture !== undefined){
            this.setState({
                selectedCulture: {
                    value: currentCulture.value,
                    label: currentCulture.label
                }
            })
        }
    }

    render() {
        const { cultureData, selectedCulture } = this.state;
        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                    <Form>
                        <FormGroup>
                            <Label for="culture">Category Name</Label>
                            <Input type="text" name="name" id="category_name" onChange={this.handleChangeName} value={this.state.categoryName}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="culture">Category Shortname</Label>
                            <Input type="text" name="shortName" id="category_short_name" onChange={this.handleChangeShortname} value={this.state.shortName}/>
                        </FormGroup>
                        <FormGroup check className="mb-3">
                            <Label check>
                            <Input type="checkbox" checked={this.state.active || false} onChange={this.handleChangeActive}/>{' '}
                                Active
                            </Label>
                        </FormGroup>
                        <FormGroup>
                            <Label for="categorySelect">Language</Label>
                            <Select
                                options={cultureData}
                                value={selectedCulture}
                                onChange={this.handleChangeCulture}>
                            </Select>
                        </FormGroup>
                        <Button onClick={this.confirmUpdate}>Update Category</Button>
                    </Form>
                </Container>
            </div>
        )
    }
}
