import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class UserHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "600px",
            backgroundImage:
              "url(https://i.ibb.co/48nPgyp/Hollybood.jpg)",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="7" md="10">
                <h1 className="display-2 text-white">Hello { window.zoomCrypt.get("user") },</h1>
                <p className="text-white mt-0 mb-5">
                  Welcome to the Zoomin.TV video publishing management system.
                </p>
                <p className="text-white mt-0">
                If you have any comment, feedback or questions please feel free to reach out: <strong>helpdesk@zoomin.tv</strong>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default UserHeader;
