import React, { Component } from 'react'
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Header from '../../components/Headers/Header';
import { getNetworkById, updateNetwork } from 'services/networkService';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class Edit extends Component {

    constructor(props){
        super(props);
        this.state = {
            networkName: "",
            active: null,
            headerliftcode: ""
        }

        this.token = window.zoomCrypt.get("token")
        this.networkId = this.props.match.params.id;
    }

    confirmUpdate = () => {
        confirmAlert({
            title: 'Update Network',
            message: 'Are you sure you want to update ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.onUpdateClick()
            },
            {
                label: 'No',
            }
            ]
        });
    };

    onUpdateClick = async e => {
        e && e.preventDefault();
        const response = await updateNetwork(this.token, this.networkId, this.state.networkName, this.state.active);
        if(response.status === 204){
            this.refreshPage();
        }
    }

    getNetwork = async () => {
        const response = await getNetworkById(this.token, this.networkId);
        if(response.success){
            this.setState({
                networkName: response.network.Name,
                active: response.network.IsActive,
                headerliftcode: response.network.HeaderliftCode
            })
        }
    }

    handleChangeName = e => {
        let networkName = e.target.value;
        this.setState({ networkName });
    }

    refreshPage() {
        this.props.history.push('/admin/networks');
    }

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }

    async componentDidMount(){
        await this.getNetwork();
    }

    render() {
        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                    <Form>
                        <FormGroup>
                            <Label for="culture">Network Name</Label>
                            <Input type="text" name="name" id="network_name" onChange={this.handleChangeName} value={this.state.networkName}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Headerlift Code</Label>
                            <Input readOnly type="text" name="headerlifcode" id="network_headerliftcode" value={this.state.headerliftcode}/>
                        </FormGroup>
                        <FormGroup check className="mb-3">
                            <Label check>
                            <Input type="checkbox" checked={this.state.active || false} onChange={this.handleChangeActive}/>{' '}
                                Active
                            </Label>
                        </FormGroup>
                        <Button onClick={this.confirmUpdate}>Update Network</Button>
                    </Form>
                </Container>
            </div>
        )
    }
}
