import React, { Component } from 'react'
import { Button, Container, Form, FormGroup, Label, Input } from 'reactstrap';
import Header from '../../components/Headers/Header';
import GeneralModal from '../../components/Modals/GeneralModal';
import { getCategories, removeCategory, createCategory } from 'services/categoryService';
import { getCultures } from 'services/cultureService';
import ReactTable from 'react-table';
import Select from 'react-select';
import "react-table/react-table.css";
import { withAlert } from 'react-alert'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Index extends Component {

    constructor(){
        super();
        this.state = this.defaultState();
        this.token = window.zoomCrypt.get("token");
    }

    defaultState() {
        return {
            categoryData: [],
            cultureData: [],
            openModal: false,
            categoryName: "",
            shortName: "",
            selectedCulture: null,
            active: true,
        };
    }

    refreshPage() {       
        this.setState(this.defaultState(), () => this.getDatas());        
    }

    toggle = () => {
		this.setState({openModal: !this.state.openModal})
    }

    getCategories = async () => {
        const responseCategory = await getCategories(this.token);
        const responseCulture = await getCultures(this.token);
        let initialCategories = [];

        if(responseCategory.success && responseCulture.success){
            responseCategory.category.map((category) => {
                responseCulture.culture.map((culture) => {
                    if(category.CultureId === culture.Id){
                        initialCategories.push({
                            id: category.Id,
                            name: category.Name,
                            cultureName: culture.Name
                        })
                    }
                })
            })
        }

        this.setState({
            categoryData: initialCategories
        })
    }

    getCultures = async () => {
        const response = await getCultures(this.token);
        let initialCultures = [];
        if(response.success){
            response.culture.map((culture) => {
                initialCultures.push({
                    value: culture.Id,
                    label: culture.Name,
                })
            })

            this.setState({
                cultureData: initialCultures
            })
        }
    }

    onSaveClick = async e => {
        e && e.preventDefault();
        const alert = this.props.alert;
        if(this.state.selectedCategoryStatus !== null && this.state.selectedCulture !== null){
            const response = await createCategory(this.token,
                this.state.categoryName,
                this.state.categoryShortname,
                this.state.active,
                this.state.selectedCulture.value);
            if(response.status === 200){
                alert.show('Added.');
                this.refreshPage();
            }
            else {
                alert.show('An error occured while trying to delete. Please try again')
            }
        }else {            
            alert.show('Please fill in all fields !')
        }
    }

    confirm = (categoryId) => {
        confirmAlert({
            title: 'Remove Category',
            message: 'Are you sure you want to delete ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.remove(categoryId)
            },
            {
                label: 'No',
            }
            ]
        });
    };

    confirmCreate = () => {
        confirmAlert({
            title: 'Create Category',
            message: 'Are you sure you want to create ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.onSaveClick()
            },
            {
                label: 'No',
            }
            ]
        });
    };

    remove = async (categoryId) =>{
        const alert = this.props.alert;
        const response = await removeCategory(this.token, categoryId);
        if(response.status === 204) {     
            alert.show('Deleted.');
            this.refreshPage();
        }
        else            
            alert.show('An error occured while trying to delete. Please try again');
    }   

    handleChangeName = e => {
        let categoryName = e.target.value;
        this.setState({ categoryName });
    }

    handleChangeShortname = e => {
        let categoryShortname = e.target.value;
        this.setState({ categoryShortname });
    }

    handleChangeStatus = selectedCategoryStatus => {
        this.setState({ selectedCategoryStatus });
    }

    handleChangeCulture = selectedCulture => {
        this.setState({ selectedCulture });
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    getDatas() {
        this.getCategories();
        this.getCultures();
    }    

    componentDidMount(){
        this.getDatas();
    }

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }

    render() {
        const columns = [
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "Language",
                accessor: "cultureName"
            },
            {
                Header: "Edit",
                Cell: props => {
                    return(
                        <a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push(`category/edit/${props.original.id}`)}}> <Button color="primary">Edit</Button></a>
                    )
                },
                accessor: "edit",
                filterable: false,
                sortable: false
            },
            {
                Header: "Remove",
                Cell: props => {
                    return(
                        <Button onClick={() => this.confirm(props.original.id)} color="danger">Remove</Button>
                    )
                },
                accessor: "remove",
                filterable: false,
                sortable: false
            }
        ]

        const { selectedCulture } = this.state;
        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                    <ReactTable
                        className="zoom-table"
                        columns={columns}
                        filterable
                        defaultFilterMethod={this.filterCaseInsensitive}
                        showPaginationTop={true}
                        data={this.state.categoryData}>
                    </ReactTable>
                    <GeneralModal
                        isOpen={this.state.openModal}
                        toggle={this.toggle}
                        title='Create Category'
                        primaryButton='Create'
                        className='modal-content bg-light'
                        secondaryButton='Cancel'
                        onAction={this.confirmCreate}>
                        <Form>
                            <FormGroup>
                                <Label for="category">Category Name</Label>
                                <Input type="text" name="categoryName" id="category_name" placeholder="Category Name" onChange={this.handleChangeName}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="category">Category Shortname</Label>
                                <Input type="text" name="categorySName" id="category_sname" placeholder="Category Shortname" onChange={this.handleChangeShortname}/>
                            </FormGroup>
                            <FormGroup check className="mb-3">
                                <Label check>
                                <Input type="checkbox" checked={this.state.active || false} onChange={this.handleChangeActive}/>{' '}
                                    Active
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <Label for="category">Category Language</Label>
                                <Select
                                    value={selectedCulture}
                                    options={this.state.cultureData}
                                    onChange={this.handleChangeCulture}
                                ></Select>
                            </FormGroup>
                        </Form>
                    </GeneralModal>
                    <Button className="create-button" color="success" onClick={this.toggle}>
                        Create Category
                    </Button>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Index)
