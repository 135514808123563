import React, { Component } from 'react'
import { Button } from 'reactstrap';
import { useKeycloak } from '@react-keycloak/web'

const LoginArea = (props) => {

    const { keycloak } = useKeycloak();

    const login = () => {
        keycloak.init({onLoad: 'login-required', checkLoginIframe: false, redirectUri: `${process.env.REACT_APP_URL}/callback`}).then(authenticated => { console.log('auth', authenticated); });
    }
    
    
    return (
        <div className="full-screen-login">
            <div className="overlay"></div>
            <div className="login-title">Welcome to Zoomin's content management and publishing platform.</div>
            <div className="login-button-area">
                <Button size="lg" onClick={ login }>LOGIN <i className="ni ni-bold-right"></i></Button>
            </div>
        </div>
    )
}
export default LoginArea;