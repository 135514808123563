import React, { Component } from 'react';
import { Container, Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { getZoomUsers } from 'services/zoomUserService'
import Header from '../../components/Headers/Header';
import ReactTable from 'react-table';
import GeneralModal from '../../components/Modals/GeneralModal';
import { getNetworks } from 'services/networkService';
import { getRoles } from 'services/roleService';
import { createUser, removeUser, userAddRoleNetwork } from 'services/zoomUserService';
import Select from 'react-select';
import "react-table/react-table.css";
import { withAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Index extends Component {

    constructor(props){
        super(props);
        this.state = this.defaultState();

        this.token = window.zoomCrypt.get("token");
        this.network = window.zoomCrypt.get("networkId");
    }

    defaultState() {
        return {
            usersData: [],
            networkData: [],
            rolesData: [],
            openModal: false,
            selectedNetwork: null,
            selectedRole: null,
            selectedNetwork2: null,
            selectedRole2: null,
            selectedNetwork3: null,
            selectedRole3: null,
            selectedNetwork4: null,
            selectedRole4: null,
            userMail: "",
            userFirstname: "",
            userLastname: "",
            active: true,
            display: "d-none",
            display2: "d-none",
            display3: "d-none",
            display4: "d-none",
            buttonName: "Create",
            responseUserId: null,
        };
    }

    refreshPage() {
        this.setState(this.defaultState(), () => this.componentDidMount());
    }

    toggle = () => {
		this.setState({
            openModal: !this.state.openModal,
        })
    }

    confirm = (userId) => {
        confirmAlert({
            title: 'Remove User',
            message: 'Are you sure you want to delete ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.remove(userId)
            },
            {
                label: 'No',
            }
            ]
        });
    };

    remove = async (userId) => {
        const alert = this.props.alert;
        const response = await removeUser(this.token, this.network, userId);
        if(response.status === 204){
            alert.show('Deleted...');
            this.refreshPage();
        }
        else {            
            alert.show('An error occured while deleting the user');
        }
    }

    getRoles = async () => {
        const response = await getRoles(this.token);
        let initialRoles = [];
            response.roles.forEach((role) => {
                initialRoles.push({
                    value: role.Id,
                    label: role.Name,
                })
            })

            this.setState({
                rolesData: initialRoles
            })
    }

    getNetworks = async () => {
        const response = await getNetworks(this.token);
        let initialNetworks = [];
        if(response.success){
            response.networks.forEach((network) => {
                initialNetworks.push({
                    value: network.Id,
                    label: network.Name,
                })
            })

            this.setState({
                networkData: initialNetworks
            })
        }
    }

    roleNameConverter(roleId) {
        let u = []
        this.state.rolesData.map((role) => {
            if(roleId === role.value){
                u.push(role.label)
            }
        })
        return u[0]
    }

      networkNameConverter(networkId) {
        let u = []
        this.state.networkData.map((network) => {
            if(networkId === network.value){
                u.push(network.label)
            }
        })
        return u[0]
    }

    getUsers = async () => {
        const response = await getZoomUsers(this.token, this.network);
        let initialUsers = [];
        if(response.users.length > 0){
            response.users.forEach((user) => {
                initialUsers.push({
                    userId: user.Id,
                    id: user.userid,
                    email: user.email,
                    name: user.firstname + ' ' + user.lastname,
                    networkId: this.networkNameConverter(user.networkid),
                    roleId: this.roleNameConverter(user.roleid),
                })
            })
        }

        this.setState({
            usersData: initialUsers
        })
    }

    onSaveClick = async e => {
        e && e.preventDefault();
        const alert = this.props.alert;
        if(this.state.display !== "d-block"){
            if(this.state.userMail !== "" && this.state.userFirstname !== ""){
                const response = await createUser(this.token,
                                              this.network,
                                              this.state.userMail,
                                              this.state.userFirstname,
                                              this.state.userLastname,
                                              this.state.active);
                if(response.success){
                    this.setState({
                        display: "d-block",
                        responseUserId: response.user,
                        buttonName: "Finish"
                    })
                }else{
                    alert.show("An error occured while creating the user.")
                }
            }else {
                alert.show('Please fill in all fields !')
            }
        }else if(this.state.buttonName === "Finish"){
            this.setState({
                openModal: !this.state.openModal,
            })
            alert.show('User Created !');

            this.refreshPage();
        }
    }

    userRoleNetwork = async() => {
        const alert = this.props.alert;
        if(this.state.selectedNetwork !== null && this.state.selectedRole !== null){
            const response = userAddRoleNetwork(this.token,
                this.network,
                this.state.responseUserId,
                this.state.selectedNetwork.value,
                this.state.selectedRole.value);

                response.then(r => {
                    if(r.status === 200){
                        alert.show("User already has the network")
                    }else if(r.status === 204){
                        this.setState({
                            display2: "d-block"
                        })
                        alert.show("Network & Role Added !")
                    }else if(r.status === 500){
                        alert.show("Network & Role Could Not Be Added !")
                    }
                })
            }else {
                alert.show("Please Select Network & Role")
            }
    }

    userRoleNetwork2 = async() => {
        const alert = this.props.alert;
        if(this.state.selectedNetwork2 !== null && this.state.selectedRole2 !== null){
            const response = userAddRoleNetwork(this.token,
                this.network,
                this.state.responseUserId,
                this.state.selectedNetwork2.value,
                this.state.selectedRole2.value);

                response.then(r => {
                    if(r.status === 200 || r.status === 500){
                        alert.show("User already has the network")
                    }else{
                        this.setState({
                            display3: "d-block"
                        })
                        alert.show("Network & Role Added !")
                    }
                })
            }else {
                alert.show("Please Select Network & Role")
            }
    }

    userRoleNetwork3 = async() => {
        const alert = this.props.alert;
        if(this.state.selectedNetwork3 !== null && this.state.selectedRole3 !== null){
            const response = userAddRoleNetwork(this.token,
                this.network,
                this.state.responseUserId,
                this.state.selectedNetwork3.value,
                this.state.selectedRole3.value);

                response.then(r => {
                    if(r.status === 200 || r.status === 500){
                        alert.show("User already has the network")
                    }else{
                        this.setState({
                            display4: "d-block"
                        })
                        alert.show("Network & Role Added !")
                    }
                })
            }else {
                alert.show("Please Select Network & Role")
            }
    }

    userRoleNetwork4 = async() => {
        const alert = this.props.alert;
        if(this.state.selectedNetwork4 !== null && this.state.selectedRole4 !== null){
            const response = userAddRoleNetwork(this.token,
                this.network,
                this.state.responseUserId,
                this.state.selectedNetwork4.value,
                this.state.selectedRole4.value);

                response.then(r => {
                    if(r.status === 200 || r.status === 500){
                        alert.show("User already has the network")
                    }else{
                        alert.show("Network & Role Added !")
                    }
                })
            }else {
                alert.show("Please Select Network & Role")
            }
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    handleChangeMail = e => {
        let userMail = e.target.value;
        this.setState({ userMail });
    }

    handleChangeFirstname = e => {
        let userFirstname = e.target.value;
        this.setState({ userFirstname });
    }

    handleChangeLastname = e => {
        let userLastname = e.target.value;
        this.setState({ userLastname });
    }

    handleChangeNetwork = selectedNetwork => {
        this.setState({
            selectedNetwork,
        })
    }

    handleChangeRole = selectedRole => {
        this.setState({
            selectedRole,
        })
    }

    handleChangeNetwork2 = selectedNetwork2 => {
        this.setState({
            selectedNetwork2,
        })
    }

    handleChangeRole2 = selectedRole2 => {
        this.setState({
            selectedRole2,
        })
    }

    handleChangeNetwork3 = selectedNetwork3 => {
        this.setState({
            selectedNetwork3,
        })
    }

    handleChangeRole3 = selectedRole3 => {
        this.setState({
            selectedRole3,
        })
    }

    handleChangeNetwork4 = selectedNetwork4 => {
        this.setState({
            selectedNetwork4,
        })
    }

    handleChangeRole4 = selectedRole4 => {
        this.setState({
            selectedRole4,
        })
    }

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }

    async componentDidMount(){
        await this.getNetworks();
        await this.getRoles();
        await this.getUsers();
    }

    render() {
        const columns = [
            {
                Header: "Email",
                accessor: "email",
                width: 400
            },
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "Network",
                accessor: "networkId",
                filterable: true,
            },
            {
                Header: "Role",
                accessor: "roleId",
                filterable: true,
            },
            {
                Header: "Edit",
                Cell: props => {
                    return(
                        <a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push(`user/edit/${props.original.id}`)}}><Button color="primary">Edit</Button></a>
                    )
                },
                accessor: "edit",
                filterable: false,
                sortable: false
            },
            {
                Header: "Remove",
                Cell: props => {
                    return(
                        <Button onClick={() => this.confirm(props.original.userId)} color="danger">Remove</Button>
                    )
                },
                accessor: "remove",
                filterable: false,
                sortable: false
            }
        ]

        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                    <ReactTable
                        className="zoom-table"
                        columns={columns}
                        filterable
                        defaultFilterMethod={this.filterCaseInsensitive}
                        showPaginationTop={true}
                        data={this.state.usersData}>
                    </ReactTable>
                    <GeneralModal
                        isOpen={this.state.openModal}
                        toggle={this.toggle}
                        title='Create User'
                        primaryButton={this.state.buttonName}
                        className='modal-content bg-light'
                        secondaryButton='Cancel'
                        onAction={this.onSaveClick}>
                        <Form>
                            <FormGroup>
                                <Label for="category">Email</Label>
                                <Input type="text" name="userMail" id="user_mail" placeholder="User Mail" onChange={this.handleChangeMail}/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="category">Firstname</Label>
                                <Input type="text" name="userFirstname" id="user_firstname" placeholder="User Firstname" onChange={this.handleChangeFirstname}/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="category">Lastname</Label>
                                <Input type="text" name="userLastname" id="user_lastname" placeholder="User Lastname" onChange={this.handleChangeLastname}/>
                            </FormGroup>

                            <FormGroup className={this.state.display}>
                                <Label for="publisher">Add Network & Role</Label>
                                <Row>
                                    <Col md="4">
                                        <Select
                                        value={ this.state.selectedNetwork }
                                        name="networks"
                                        options={ this.state.networkData }
                                        onChange={this.handleChangeNetwork}
                                        />
                                    </Col>

                                    <Col md="4">
                                        <Select
                                        value={ this.state.selectedRole }
                                        name="roles"
                                        options={ this.state.rolesData }
                                        onChange={this.handleChangeRole}
                                        />
                                    </Col>

                                    <Col md="4">
                                        <Button onClick={this.userRoleNetwork} color="success">Add</Button>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup className={this.state.display2}>
                                <Label for="publisher">Add Network & Role</Label>
                                <Row>
                                    <Col md="4">
                                        <Select
                                        value={ this.state.selectedNetwork2 }
                                        name="networks"
                                        options={ this.state.networkData }
                                        onChange={this.handleChangeNetwork2}
                                        />
                                    </Col>

                                    <Col md="4">
                                        <Select
                                        value={ this.state.selectedRole2 }
                                        name="roles"
                                        options={ this.state.rolesData }
                                        onChange={this.handleChangeRole2}
                                        />
                                    </Col>

                                    <Col md="4">
                                        <Button onClick={this.userRoleNetwork2} color="success">Add</Button>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup className={this.state.display3}>
                                <Label for="publisher">Add Network & Role</Label>
                                <Row>
                                    <Col md="4">
                                        <Select
                                        value={ this.state.selectedNetwork3 }
                                        name="networks"
                                        options={ this.state.networkData }
                                        onChange={this.handleChangeNetwork3}
                                        />
                                    </Col>

                                    <Col md="4">
                                        <Select
                                        value={ this.state.selectedRole3 }
                                        name="roles"
                                        options={ this.state.rolesData }
                                        onChange={this.handleChangeRole3}
                                        />
                                    </Col>

                                    <Col md="4">
                                        <Button onClick={this.userRoleNetwork3} color="success">Add</Button>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup className={this.state.display4}>
                                <Label for="publisher">Add Network & Role</Label>
                                <Row>
                                    <Col md="4">
                                        <Select
                                        value={ this.state.selectedNetwork4 }
                                        name="networks"
                                        options={ this.state.networkData }
                                        onChange={this.handleChangeNetwork4}
                                        />
                                    </Col>

                                    <Col md="4">
                                        <Select
                                        value={ this.state.selectedRole4 }
                                        name="roles"
                                        options={ this.state.rolesData }
                                        onChange={this.handleChangeRole4}
                                        />
                                    </Col>

                                    <Col md="4">
                                        <Button onClick={this.userRoleNetwork4} color="success">Add</Button>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup check>
                                <Label check>
                                <Input type="checkbox" checked={this.state.active || false} onChange={this.handleChangeActive}/>{' '}
                                    Active
                                </Label>
                            </FormGroup>
                        </Form>
                    </GeneralModal>
                    <Button className="create-button" color="success" onClick={this.toggle}>
                        Create User
                    </Button>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Index)