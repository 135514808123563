import React from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "AuthContext";
import { withKeycloak } from '@react-keycloak/web'

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";


class AdminNavbar extends React.Component {
  static contextType = AuthContext;  
  logout = () => {
    this.props.keycloak.logout({ redirectUri: `${process.env.REACT_APP_URL}` });
    window.zoomCrypt.removeAll();
  }


  render() {

    const authContext = this.context;
    
    return (
      <>
        <Navbar className="navbar-top navbar-light" expand="md" id="navbar-main">          
          <Container fluid>
          <img src={window.zoomCrypt.get("networkLogo")} />
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >

            </Link>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">                    
                      <Media className="ml-2 d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold text-white">
                          { window.zoomCrypt.get("user") } | { authContext.networkName } - { authContext.roleName }
                        </span>
                      </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>

                  <DropdownItem divider />
                  <DropdownItem href="#logout" onClick={this.logout}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default withKeycloak(AdminNavbar);
