import React, { Component } from 'react'
import Header from '../../components/Headers/Header';
import { Button, Container, Row, Col, Form, FormGroup, Label, Input} from 'reactstrap';
import { getZoomUser } from 'services/zoomUserService';
import { getPublishers } from 'services/publisherService';
import { getCultures } from 'services/cultureService';
import { getCategories } from 'services/categoryService';
import { getPublishedAssets } from 'services/assetService';
import { createFeed, getFeedsByPid } from 'services/feedService';
import Select from 'react-select';
import { withAlert } from 'react-alert';
import Filter from '../../components/Filters/Filter';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import Iframe from 'react-iframe'
import { isPropertyDeclaration } from 'typescript';

class Index extends Component {
    constructor(){
        super();
        this.state = {
            userNetworkOptions: [],
            networkData: [],
            publisherOptions: [],
            cultureOptions: [],
            categoryOptions: [],
            assetData: [],
            selectedAssets: [],
            filteredAssetData: [],
            selectedRowIndex: [],
            selectedPublisher: null,
            selectedUserNetwork: null,
            selectedCulture: null,
            selectedCategory: [],
            selectedKeywords: [],
            limit: 200,
            offset: 0,
            showIframe: false,
            showPlaylist: false,
            cultureId: localStorage.getItem('TheSelectedCulture') ? JSON.parse(localStorage.getItem('TheSelectedCulture')).value : 0,
            selectedFilterMinDate: null,
            selectedFilterMaxDate: null,
            categoryFilterData: [],
            keywordFilterData: [],
            filterTitle: '',
            filterDescription: '',
            filterAssetId: "",
            playlistOptions: [],
            selectedPlaylist: null,
            selectedPlaylistCode: ""
        }

        this.PLAYER_LINK = "https://play.zoomin.tv/";
        this.token = window.zoomCrypt.get("token");
        this.sub = window.zoomCrypt.get("sub");
        this.network = window.zoomCrypt.get("networkId");
        this.networkName = window.zoomCrypt.get("networkName");
        this.iframe = "";
    }

    handleChangeActive = e => {
        let playlistCheck = e.target.checked;
        this.setState({
            playlistCheck,
            showPlaylist: playlistCheck
        });
    }

    getPublishedAssets = async (limit, offset, cultureId, title, description, category, keyword, minPublicatedDate, maxPublicatedDate) => {
        
        cultureId = this.state.cultureId !== 0 ? this.state.cultureId : undefined;
        let assetId = this.state.filterAssetId != "" ? this.state.filterAssetId : undefined;

        if(this.state.categoryFilterData !== [])
            category = this.state.categoryFilterData;
        
        if(this.state.keywordFilterData !== [])
            keyword = this.state.keywordFilterData;            

        if(this.state.filterTitle !== '') {
            title = this.state.filterTitle;
        }

        if(this.state.filterDescription !== '') {
            description = this.state.filterDescription;
        }

        if(this.state.selectedFilterMinDate !== null) {
            minPublicatedDate = this.state.selectedFilterMinDate;
        }

        if(this.state.selectedFilterMaxDate !== null) {
            maxPublicatedDate = this.state.selectedFilterMaxDate;
        }
        
        let networkId =  this.network;
        if(this.state.selectedUserNetwork !== null)
            networkId = this.state.selectedUserNetwork.value;

        const response = await getPublishedAssets(this.token, parseInt(networkId), limit, offset, cultureId, title, description, category, keyword, minPublicatedDate, maxPublicatedDate, undefined, assetId);
        let initialAssets = [];

        if(response.assets.length >= 0) {
            initialAssets = response.assets.map((asset) => {
                return asset;
            })
        }

        this.setState({
            assetData: initialAssets,
            filteredAssetData: initialAssets,
            totalPage: Math.ceil(response.totalRowCount / 20)
        })
    }

    getUserNetworks = async () => {
        const response = await getZoomUser(this.token, this.sub, this.network);
        if(response.success){
          let initialNetworks = [];
          response.user.Networks.map((network) => {
            initialNetworks.push({
                value: network.NetworkId,
                label: network.NetworkName
            })
          })

          this.setState({
            userNetworkOptions: initialNetworks
          })
        }
      }

    getPublishers = async (selectedNetworkId) => {
        const response = await getPublishers(this.token);
        if(response.success){
            let initialPublishersOptions = [];
            response.publishers.map((publisher) => {
                if(publisher.NetworkId === selectedNetworkId){
                    initialPublishersOptions.push({
                        value: publisher.Id,
                        label: publisher.Name,
                        pid: publisher.Pid,
                    })
                }
            })

            this.setState({
                publisherOptions: initialPublishersOptions
            })
        }
    }

    getPlaylists = async (selectedPid) => {
        const response = await getFeedsByPid(this.token, selectedPid);
        if(response.success){
            let initialPlaylists = [];
            response.publisherFeedSettings.map((playlist) => {
                initialPlaylists.push({
                  value: playlist.PlaylistCode,
                  label: playlist.PlaylistCode
              })
            })

            this.setState({
                playlistOptions: initialPlaylists
            })
        }
    }

    getCultures = async () => {
        const response = await getCultures(this.token);
        let initialCultures = [{label: ' All Cultures', value: 0}];
        if(response.success){
            response.culture.sort((a, b) => a.Name.localeCompare(b.Name)).map((culture) => {
                initialCultures.push({
                    value: culture.Id,
                    label: culture.Name,
                })
            })

            this.setState({
                cultureOptions: initialCultures
            })
        }
    }

    getCategories = async () => {
        const response = await getCategories(this.token);
        let initialCultures = [];
        if(response.success){
            response.category.sort((a, b) => a.Name.localeCompare(b.Name)).map((category) => {
                initialCultures.push({
                    value: category.Id,
                    label: category.IsIab ? `${category.Name} ${category.ShortName}` : category.Name,
                })
            })

            this.setState({
                categoryOptions: initialCultures
            })
        }
    }

    handleChangePublisher = selectedPublisher => {
        this.setState({ selectedPublisher, 
            selectedPlaylistCode: "",
            selectedPlaylist: null,
            playlistOptions: [] });

        this.iframe = this.state.selectedAssets.length === 1
        ? `<iframe src="${this.PLAYER_LINK}?assetId=${this.state.selectedAssets[0]}&publisherId=${selectedPublisher?.pid}&pl=${this.state.showPlaylist}" id="zoomFrame" frameborder="0" scrolling="no" allowfullscreen="true" width="100%" height="100%"></iframe>`
        : `<iframe src="${this.PLAYER_LINK}?publisherId=${selectedPublisher?.pid}&pl=${this.state.showPlaylist}" id="zoomFrame" frameborder="0" scrolling="no" allowfullscreen="true" width="100%" height="100%"></iframe>`
        
        this.getPlaylists(selectedPublisher.pid);
    }

    handleChangePlaylist = playlist => {
        this.setState({
            selectedPlaylistCode: playlist.value,
            selectedPlaylist: playlist
        })
    }

    handleChangePlaylistCode = code => {
        
        this.setState({
            selectedPlaylistCode: code.target.value.replace(/\s/g, '').toLowerCase()
        })
    }

    handleChangeUserNetwork = selectedUserNetwork => {
        const { filteredAssetData } = this.state;

        this.setState({ offset: 0,
            selectedUserNetwork,
            selectedPublisher: null, 
            selectedPlaylistCode: "",
            selectedPlaylist: null,
            playlistOptions: []
         })

        this.getPublishers(selectedUserNetwork.value);

        this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData);

        /*
        const filteredData = filteredAssetData.filter(item => {
            if(item.networkId === selectedUserNetwork.value){
                return item
            }
        })

        this.setState({ filteredAssetData: filteredData });
        */
    }

    handleChangeCategory = data => {
        let category = [];
        if(data !== null){
            data.forEach(d => {
                category.push(d.value)
            })
        }else{
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId);
        }

        this.setState({ offset: 0,
            categoryFilterData: category
        }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData);
        })
    }

    handleChangeKeyword = data => {
        let keyword = [];
        if(data !== null){
            data.forEach(d => {
                keyword.push(d.label)
            })
        }else{
            this.getPublishedAssets(this.state.limit, this.state.offset);
        }
        this.setState({ offset: 0,
            keywordFilterData: keyword
        }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData);
        })
    }

    handleChangeCulture = data => {
        let cultureId = data.value;
        this.setState({ offset: 0, cultureId: cultureId, categoryFilterData: [] }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }

    handleSelectedFilterMinDate = selectedFilterMinDate => {
        if(selectedFilterMinDate.target.value !== ""){
            this.setState({ offset: 0, selectedFilterMinDate: selectedFilterMinDate.target.value }, () => {
                this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData, new Date(this.state.selectedFilterMinDate).toISOString());
            });
        }
    }

    handleSelectedFilterMaxDate = selectedFilterMaxDate => {
        if(selectedFilterMaxDate.target.value !== ""){
            this.setState({ offset: 0, selectedFilterMaxDate: selectedFilterMaxDate.target.value }, () => {
                this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData, new Date(this.state.selectedFilterMinDate).toISOString(), new Date(this.state.selectedFilterMaxDate).toISOString());
            });
        }
    }

    handleFilterTitle = filterTitle => {
        this.setState({ offset: 0, filterTitle }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }

    handleFilterDescription = filterDescription => {
        this.setState({ offset: 0, filterDescription }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }

    handleFilterAssetId = filterAssetId => {
        this.setState({ offset: 0, filterAssetId }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }

    resetDates() {
        this.setState({ offset: 0, selectedFilterMinDate: "" }, () => {
            document.getElementById("minDateTime").value = ""
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData);
        });

        this.setState({ offset: 0, selectedFilterMaxDate: "" }, () => {
            document.getElementById("maxDateTime").value = ""
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData);
        });
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    selectAsset = (props) => {
        if(this.state.selectedRowIndex.includes(props._index) === false){
            this.setState({
                selectedAssets: [...this.state.selectedAssets, props._original.id],
                selectedRowIndex: [...this.state.selectedRowIndex, props._index]
            })
        }
    }

    deselectAsset = (props) => {
        const { selectedRowIndex } = this.state;
        const { selectedAssets } = this.state;

        if(selectedAssets.includes(props._original.id)){
            const deselectedAssets = selectedAssets.filter(function(item) {
                return item !== props._original.id
            })

            this.setState({
                selectedAssets: deselectedAssets
            })
        }

        if(selectedRowIndex.includes(props._index)){
            const deselectedItems = selectedRowIndex.filter(function(item) {
                return item !== props._index
              })

            this.setState({
                selectedRowIndex: deselectedItems
            })
        }
    }

    getTrProps = (state, rowInfo) => {
        if (rowInfo) {
            return {
                style: {
                  background: this.state.selectedRowIndex.includes(rowInfo.row._index) ? '#feca57' : 'white'
                }
              }
          }
        return {};
      }

    componentDidMount(){
        this.getPublishedAssets(this.state.limit, this.state.offset);
        this.getUserNetworks();
        this.getCultures();
        this.getCategories();
    }

    setSelectedAssets = async() => {
        const alert = this.props.alert; 
        this.setState({ showIframe: false });
        if(this.state.selectedPublisher !== null &&
            this.state.selectedUserNetwork !== null &&
            this.state.selectedAssets !== null &&
            this.state.selectedPlaylistCode !== ""){
            const response = await createFeed(this.token,
                                              this.network,
                                              this.state.selectedUserNetwork.value,
                                              this.state.selectedPublisher?.value,
                                              this.state.selectedPlaylistCode,
                                              this.state.selectedAssets.length || 10,
                                              this.state.cultureId === 0 ? [] : [this.state.cultureId],
                                              this.state.categoryFilterData,
                                              this.state.keywordFilterData,
                                              this.state.selectedAssets);
                if(response.success){
                    alert.show("You successfully save your asset's criteria")
                    this.setState({
                        showIframe: true,
                        playlistCheck: this.state.selectedAssets.length > 0 ? false : true,
                        showPlaylist: this.state.selectedAssets.length > 0 ? false : true
                    })
                }else {
                    alert.show("An error occured while saving the assets criteria.")
                }
        }else {
            alert.show("Please fill Select Network, Select Publisher and Playlist Code fields !")
        }
    }

    setAllAssets = async() => {
        const alert = this.props.alert;
        if(this.state.selectedPublisher !== null &&
            this.state.selectedUserNetwork !== null &&
            this.state.selectedAssets !== null){
            const response = await createFeed(this.token,
                                              this.network,
                                              this.state.selectedUserNetwork.value,
                                              this.state.selectedPublisher?.value,
                                              this.state.selectedAssets.length,
                                              [8],
                                              this.state.selectedCategory?.value,
                                              this.state.selectedKeywords,
                                              this.state.filteredAssetData);
                if(response.success){
                    alert.show("Assets Set")
                }else {
                    alert.show("Assets Not Set")
                }
        }else {
            alert.show("Please Select Network, Publisher & Asset !")
        }
    }

    render() {
        const { userNetworkOptions,
                publisherOptions,
                selectedPublisher,
                selectedUserNetwork,
                selectedPlaylist, 
                playlistOptions ,
                selectedPlaylistCode } = this.state;

                this.iframe = this.state.selectedAssets.length === 1
                              ? `<div style="position: relative; padding-bottom: 56.25%; /* 16:9 */ height: 0;"><iframe style="position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;" width="560" height="349" src="${this.PLAYER_LINK}?assetId=${this.state.selectedAssets[0]}&publisherId=${this.state.selectedPublisher?.pid}&pl=${this.state.showPlaylist}" id="zoomFrame" frameborder="0" scrolling="no" allowfullscreen="true" ></iframe></div>`
                              : `<div style="position: relative; padding-bottom: 56.25%; /* 16:9 */ height: 0;"><iframe style="position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;" width="560" height="349" src="${this.PLAYER_LINK}?publisherId=${this.state.selectedPublisher?.pid}&code=${this.state.selectedPlaylistCode}&pl=${this.state.showPlaylist}" id="zoomFrame" frameborder="0" scrolling="no" allowfullscreen="true" ></iframe></div>`

                const columns = [
                    {
                        Header: "Image",
                        accessor: "image",
                        Cell: props => {
                            return(
                                <img src={`${props.original.data.ImageRemotePath}${props.original.data.ImageFileName}`} alt={props.original.data.Title} width="100%" />
                            )
                        },
                        filterable: false,
                        sortable: false
                    },
                    {
                        Header: "Asset Id",
                        Cell: props => {
                            return(
                                <a href={`/admin/published-edit/asset/${props.original.id}`} target="_blank" alt="zoomin">{ props.original.id }</a>                            
                            )
                        },
                        style: { 'whiteSpace': 'unset' }
                    },
                    {
                        Header: "Title",
                        accessor: "data.Title",
                        style: { 'whiteSpace': 'unset' }
                    },
                    {
                        Header: "Select",
                        Cell: props => {
                            return(
                                <Button onClick={() => this.selectAsset(props.row)} color="warning">Select</Button>
                            )
                        },
                        accessor: "edit",
                        filterable: false,
                        sortable: false,
                        width: 100
                    },
                    {
                        Header: "Deselect",
                        Cell: props => {
                            return(
                                <Button onClick={() => this.deselectAsset(props.row)} color="primary">Deselect</Button>
                            )
                        },
                        accessor: "edit",
                        filterable: false,
                        sortable: false,
                        width: 120
                    }
                ]
        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                <Form>
                    <Row>
                        <Col md="9">
                            <ReactTable
                                className="zoom-table mb-5"
                                columns={columns}
                                filterable
                                defaultFilterMethod={this.filterCaseInsensitive}
                                showPaginationTop={true}
                                data={this.state.filteredAssetData}
                                getTrProps={this.getTrProps}
                                defaultPageSize={5}>

                            </ReactTable>
                        </Col>

                        <Col md="3">
                            <FormGroup>
                                <Label for="networks">Select Network</Label>
                                <Select
                                    value={selectedUserNetwork}
                                    options={userNetworkOptions}
                                    onChange={this.handleChangeUserNetwork}
                                ></Select>
                            </FormGroup>

                            <FormGroup>
                                <Label for="publishers">Select Publisher</Label>
                                <Select
                                    value={selectedPublisher}
                                    options={publisherOptions}
                                    onChange={this.handleChangePublisher}
                                ></Select>
                            </FormGroup>

                            <FormGroup>
                                <Label for="publishers">Select Playlist</Label>
                                <Select
                                    value={selectedPlaylist}
                                    options={playlistOptions}
                                    onChange={this.handleChangePlaylist}
                                ></Select>
                            </FormGroup>
                            <FormGroup>
                                <Label for="playlistCode">Playlist Name</Label>
                                <Input type="text"
                                    name="playlistCode"
                                    id="playlistCode"
                                    value={selectedPlaylistCode || ''}
                                    onChange={ this.handleChangePlaylistCode } />
                            </FormGroup>
                            <Filter onChangeCategorySelect={this.handleChangeCategory}
                                    onChangeCultureSelect={this.handleChangeCulture}
                                    onChangeKeywordSelect={this.handleChangeKeyword}
                                    onChangeMinDate={this.handleSelectedFilterMinDate}
                                    onChangeMaxDate={this.handleSelectedFilterMaxDate}
                                    onChangeTitle={this.handleFilterTitle}
                                    onChangeDescription={this.handleFilterDescription}
                                    onChangeAssetId={this.handleFilterAssetId}
                                    showDates={false}>
                            </Filter>
                        </Col>
                    </Row>

                    { /* <Button onClick={this.setAllAssets}>Set All Assets</Button> */ }
                    <Button onClick={this.setSelectedAssets}>Set Selected Assets</Button>
                    { this.state.showIframe ? <>                    
                    <Row>
                        <Col md="12">
                            <h2 style={{borderBottom: "2px solid #000", marginBottom: "20px", marginTop: "20px"}}>Player Generator</h2>

                            <FormGroup check className="mb-3">
                                <Label check>
                                <Input type="checkbox" checked={this.state.playlistCheck || false} onChange={this.handleChangeActive}/>{' '}
                                    Check if you want playlist. If you don't check it, the selected videos will be shown randomly.
                                </Label>
                            </FormGroup>
                            
                            <Iframe
                            url={this.state.playlistCheck ? `${this.PLAYER_LINK}?publisherId=${this.state.selectedPublisher?.pid}&code=${this.state.selectedPlaylistCode}&pl=${this.state.playlistCheck}` : `${this.PLAYER_LINK}?publisherId=${this.state.selectedPublisher?.pid}${this.state.selectedAssets.length > 0 ? '&assetId=' + this.state.selectedAssets[0] : '&pl=false' }`}
                            width="100%"
                            height="450px"
                            id="generatedPlayer"
                            className="generated-player"
                            display="initial"
                            position="relative"/>

                        <Input type="textarea" name="text" id="playerGenerator" value={this.iframe} style={{marginBottom: '20px', height: '100px'}}/>
                        </Col>
                    </Row> </> : ""}
                </Form>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Index)