import React, { Component } from 'react';
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { getCultures, createCulture, removeCulture } from "services/cultureService";
import Header from '../../components/Headers/Header';
import GeneralModal from '../../components/Modals/GeneralModal';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { withAlert } from 'react-alert'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Index extends Component {

    constructor(props){
        super(props);
        this.state = this.defaultState();
        this.token = window.zoomCrypt.get("token")
    }

    defaultState() {
        return {
            cultureData: [],
            cultureName: "",
            openModal: false,
            active: true
        };
    }

    refreshPage() {
        this.setState(this.defaultState(), () => this.getDatas());
    }

    handleChangeStatus = selectedOption => {
        this.setState({ selectedOption });
    }

    handleChangeName = e => {
        let cultureName = e.target.value;
        this.setState({ cultureName });
    }

    onSaveClick = async e => {
        e && e.preventDefault();
        const alert = this.props.alert;
        if(this.state.selectedOption !== null){
            const response = await createCulture(this.token, this.state.cultureName, this.state.active);
            if(response.status === 200){
                this.refreshPage();
            }
            else {
                alert.show('An error occured while trying to delete. Please try again')
            }
        }else {
            alert.show('Please fill in all fields !')
        }
    }

    toggle = () => {
		this.setState({openModal: !this.state.openModal})
    }

    getCultures = async () => {
        const response = await getCultures(this.token);
        let initialCultures = [];
        if(response.success){
            response.culture.map((culture) => {
                initialCultures.push({
                    id: culture.Id,
                    name: culture.Name,
                    status: culture.IsActive ? "Active" : "Not Active"
                })
            })

            this.setState({
                cultureData: initialCultures
            })
        }
    }

    confirm = (cultureId) => {
        confirmAlert({
            title: 'Remove Language',
            message: 'Are you sure you want to delete ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.remove(cultureId)
            },
            {
                label: 'No',
            }
            ]
        });
    };

    confirmCreate = () => {
        confirmAlert({
            title: 'Create Language',
            message: 'Are you sure you want to create ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.onSaveClick()
            },
            {
                label: 'No',
            }
            ]
        });
    };

    remove = async (cultureId) =>{
        const alert = this.props.alert;
        const response = await removeCulture(this.token, cultureId);
        if(response.status === 204)        
            this.refreshPage();
        else            
            alert.show('An error occured while trying to delete. Please try again');

    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    getDatas() {
        this.getCultures();
    } 

    componentDidMount(){
        this.getDatas();
    }

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }

    render() {
        const columns = [
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "Status",
                accessor: "status"
            },
            {
                Header: "Edit",
                Cell: props => {
                    return(
                        <a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push(`culture/edit/${props.original.id}`)}}> <Button color="primary">Edit</Button></a>
                    )
                },
                accessor: "edit",
                filterable: false,
                sortable: false
            },
            {
                Header: "Remove",
                Cell: props => {
                    return(
                        <Button onClick={() => this.confirm(props.original.id)} color="danger">Remove</Button>
                    )
                },
                accessor: "remove",
                filterable: false,
                sortable: false
            }
        ]

        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                    <ReactTable
                        className="zoom-table"
                        columns={columns}
                        filterable
                        defaultFilterMethod={this.filterCaseInsensitive}
                        showPaginationTop={true}
                        data={this.state.cultureData}>
                    </ReactTable>
                    <GeneralModal
                        isOpen={this.state.openModal}
                        toggle={this.toggle}
                        title='Create Language'
                        primaryButton='Create'
                        className='modal-content bg-light'
                        secondaryButton='Cancel'
                        onAction={this.confirmCreate}>
                        <Form>
                            <FormGroup>
                                <Label for="culture">Language</Label>
                                <Input type="text" name="cultureName" id="culture_name" placeholder="Language Name" onChange={this.handleChangeName}/>
                            </FormGroup>
                            <FormGroup check className="mb-3">
                                <Label check>
                                <Input type="checkbox" checked={this.state.active || false} onChange={this.handleChangeActive}/>{' '}
                                    Active
                                </Label>
                            </FormGroup>
                        </Form>
                    </GeneralModal>
                    <Button className="create-button" color="success" onClick={this.toggle}>
                        Create Language
                    </Button>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Index)