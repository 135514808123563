import { zoomCmsAPI } from "./config";
import { validateResponse } from "./validateResponse";

const publisherLanguageApiUri = `${zoomCmsAPI}/publisherlanguage`;

export async function getPublisherLanguages(token) {
  const response = await fetch(publisherLanguageApiUri, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await validateResponse(response);
}

export async function createPublisherLanguage(
  token,
  networkId,
  publisherId,
  languages
) {
  const response = await fetch(`${publisherLanguageApiUri}/create`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      networkId: networkId,
    },
    body: JSON.stringify({
      publisherId,
      languages,
    }),
  });
  return response;
}

export async function removePublisherLanguage(token, networkId, publisherId) {
  const response = await fetch(`${publisherLanguageApiUri}/${publisherId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      networkId: networkId,
    },
    body: JSON.stringify({
      Id: publisherId,
    }),
  });
  return response;
}
