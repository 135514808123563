import React, { Component } from 'react'
import Header from '../../components/Headers/Header';
import { getTaskById, updateTask, assignTask, removeTask } from 'services/taskService';
import { getCategories } from 'services/categoryService';
import { Container, Badge, ListGroup, ListGroupItem, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { getZoomUsers, getZoomUser } from 'services/zoomUserService';
import { getCultures } from 'services/cultureService';
import { getNetworks } from 'services/networkService';
import { getStatus, getCountry } from 'services/roleService'
import { withAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

class ParentDetail extends Component {

    constructor() {
        super();
        this.state = this.defaultState();

        this.token = window.zoomCrypt.get("token")
        this.networkId = window.zoomCrypt.get("networkId");
        this.sub = window.zoomCrypt.get("sub");
    }

    defaultState() {
        return {
            parentTask: [],
            vjTasks: [],
            usersData: [],
            editorTasks: [],
            localizerTasks: [],
            cultureData: [],
            categoryData: [],
            taskEditTitle: "",
            taskEditDescription: "",
            taskEditCountry: "",
            taskEditCity: "",
            taskEditIdea: "",
            categories: [],
            keywords: [],
            networkData: [],
            selectedCategory: [],
            selectedKeywords: [],
            statusesData: [],
            selectedAssignUser: null,
            taskVjPrice: 0,
            currentCategories: [],
            keywordsLabel: [],
            categoriesValue: [],
            taskFound: false,
            countryData: [],
            selectedCountry: [],
            currencyData: [{
                value: 1,
                label: "USD"
            },
            {
                value: 2,
                label: "EUR"
            }],
            selectedCurrency: [],
            userId: null,
        };
    }

    async getUser(){
        const response = await getZoomUser(this.token, this.sub, this.networkId);
        if(response.success){
            this.setState({
                userId: response.user.Id
            })
        }
    }

    getCategories = async () =>{
        const response = await getCategories(this.token);
        const responseTask = await getTaskById(this.token, this.networkId, this.props.match.params.id);

        this.state.countryData.forEach((c) => {
            if(c.label === responseTask[0].data.country){
              this.setState({
                selectedCountry: c
              })
            }
          })

        if(response.success){
            let initialCategories = [];
            let initialCategoriesEdit = [];
            let initialCurrentCategories = [];
            response.category.forEach((category) => {
                if(category !== undefined){
                    initialCategories.push({ category })
                }

                initialCategoriesEdit.push({
                    value: category.Id,
                    label: category.IsIab ? `${category.Name} ${category.ShortName}` : category.Name,
                })

                if(responseTask.length > 0){
                    responseTask[0].data.categories.map((taskCat) => {
                        if(category.Id === taskCat){
                            initialCurrentCategories.push({
                                value: category.Id,
                                label: category.IsIab ? `${category.Name} ${category.ShortName}` : category.Name,
                            })
                        }
                    })
                }
            })

            this.setState({
                categoryData: initialCategories,
                currentCategories: initialCurrentCategories,
                categories: initialCategoriesEdit,
            })
        }
    }

    setKeywords = () => {
        let initKeywords = [];
            if(this.state.parentTask?.data?.keywords?.length > 0){
                this.state.parentTask.data.keywords.map((keyword, index) => {
                    initKeywords.push({
                        value: index,
                        label: keyword
                    })
                })
            }

        if(initKeywords.length > 0){
            this.setState({
                keywords: initKeywords
            })
        }
    }

    getStatus = async () => {
        const response = await getStatus(this.token);
        let initialStatuses = [];
            response.taskStatuses.forEach((role) => {
                initialStatuses.push({
                    role
                })
            })

            this.setState({
                statusesData: initialStatuses
            })
    }

    getNetworks = async () => {
        const response = await getNetworks(this.token);
        let initialNetworks = [];
        if(response.success){
            response.networks.forEach((network) => {
                initialNetworks.push({
                    value: network.Id,
                    label: network.Name,
                })
            })

            this.setState({
                networkData: initialNetworks
            })
        }
    }

    taskDetail = async () => {
        const response = await getTaskById(this.token, this.networkId, this.props.match.params.id);

        let parentTask = {};
        let vjTasks = [];
        let editorTasks = [];
        let localizerTasks = [];

        if(response.length > 0) {
            response.forEach((task) => {
                if(task.data.type === "task") {
                    parentTask = task;
                }else if(task.data.type === "subtask" && task.data?.questType === "vj") {
                    vjTasks.push({ task })
                }else if(task.data.type === "subtask" && task.data?.questType === "editor") {
                    editorTasks.push({ task })
                }else if(task.data.type === "subtask" && task.data?.questType === "localizer") {
                    localizerTasks.push({ task })
                }

                this.setState({
                    parentTask,
                    vjTasks,
                    editorTasks,
                    localizerTasks,
                    taskFound: true
                })
             })
        }
    }

    categoryNameConverter(categoryId) {
        let cats = [];
        if(categoryId !== undefined) {
            this.state.categoryData.map((category) => {
                categoryId.map((c) => {
                    if(c === category?.category.Id){
                        cats.push(category.category.IsIab ? `${category.category.Name} ${category.category.ShortName}` : category.category.Name)
                    }
                })
            })
        }
        return cats.length > 1 ? cats.join(", ") : cats
      }

      refreshPage() {
        this.setState(this.defaultState(), () => this.componentDidMount());
      }

      update = async e => {
        e && e.preventDefault();
        const alert = this.props.alert;
        const response = await updateTask(this.token,
                                          this.networkId,
                                          this.props.match.params.id,
                                          this.state.taskEditTitle,
                                          this.state.taskEditDescription,
                                          this.state.keywordsLabel,
                                          this.state.categoriesValue,
                                          this.state.taskEditIdea,
                                          this.state.taskEditCity,
                                          this.state.selectedCountry !== null ? this.state.selectedCountry.label : this.state.showTaskDetail.country);

        if(response.status === 200){
            alert.show('Task updated.')
            this.refreshPage();
        }else {          
          alert.show('An error occured while updating the task')
        }
    }

    confirm = () => {
        if(this.state.categoriesValue.length > 0){        
            confirmAlert({
                title: 'Update Task',
                message: 'Are you sure you want to update ?',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.update()
                },
                {
                    label: 'No',
                }
                ]
            });
        } else {
            alert.show('An error occured while updating the task: Category field should contain at least one item');
        }
    };

    confirmAssign = (type) => {
        confirmAlert({
            title: 'Assign User',
            message: 'Are you sure you want to assign ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.assign(type)
            },
            {
                label: 'No',
            }
            ]
        });
    }

    confirmRemoveSubTask = (taskId) => {
        confirmAlert({
            title: 'Remove Task',
            message: 'Are you sure you want to remove ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.removeSubTask(taskId)
            },
            {
                label: 'No',
            }
            ]
        });
    }


    confirmRemove = () => {
        confirmAlert({
            title: 'Remove Task',
            message: 'Are you sure you want to remove ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.removeTask()
            },
            {
                label: 'No',
            }
            ]
        });
    }

    removeTask = async() => {
        const alert = this.props.alert;
        const response = await removeTask(this.token, this.networkId, this.props.match.params.id);
        if(response.status === 200){
            alert.show('Task Removed !');
            this.props.history.push('/admin/tasks')
        }else {
            alert.show('Task Could Not Removed !');
        }
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleChangeAssignUser = selectedAssignUser => {
        this.setState({ selectedAssignUser });
    }

    handleChangeKeywords = keywords => {
        let initKeywordsLabel = [];
        if(keywords?.length > 0){
            keywords.map((keyword) => {
                initKeywordsLabel.push(keyword.label)
            })
        }

        this.setState({
            keywords: keywords,
            keywordsLabel: initKeywordsLabel
        })
    }

    handleChangeCategory = currentCategories => {
        let initCategoriesValue = [];
        if(currentCategories?.length > 0){
            currentCategories.map((category) => {
                initCategoriesValue.push(category.value)
            })
        }

        this.setState({
            currentCategories: currentCategories,
            categoriesValue: initCategoriesValue
        })
    }

    handleChangeCurrency = selectedCurrency  => {
        this.setState({
            selectedCurrency
        })
    }

    initKeywordsAndCategories = () => {
        let initKeywordsLabel = [];
        let initCategoriesValue = [];
        this.state.keywords.map((keyword) => {
            initKeywordsLabel.push(keyword.label)
        })

        this.state.currentCategories.map((category) => {
            initCategoriesValue.push(category.value)
        })

        this.setState({
            categoriesValue: initCategoriesValue,
            keywordsLabel: initKeywordsLabel
        })
    }

    handleChangeCountry = selectedCountry => {
        this.setState({ selectedCountry })
    }

    parentTaskListItems() {
        const p = this.state.parentTask.data;
        if(p?.status !== 1) {
            return(
                <ListGroup>
                    <ListGroupItem><strong>Created By:</strong> { this.userNameConverter(p?.createdBy) }</ListGroupItem>
                    <ListGroupItem><strong>Description: </strong>{ p?.description }</ListGroupItem>
                    <ListGroupItem><strong>City: </strong>{ p?.city }</ListGroupItem>
                    <ListGroupItem><strong>Country: </strong>{ p?.country }</ListGroupItem>
                    <ListGroupItem><strong>Category: </strong>{ this.categoryNameConverter(p?.categories) }</ListGroupItem>
                    <ListGroupItem><strong>Keywords: </strong>{ p?.keywords.join(', ') }</ListGroupItem>
                    <ListGroupItem><strong>Idea Originator: </strong>{ p?.ideaOriginator }</ListGroupItem>
                    <ListGroupItem><strong>Status: </strong>{ <span>{ this.state.statusesData.map(s => p?.status === s.role.id ? s.role.name : "")}</span> }</ListGroupItem>
                </ListGroup>
            )
        }else {
            return (
                <div>
                    <Form>
                      <FormGroup>
                          <Label for="task">Task Title</Label>
                          <Input type="text" name="taskEditTitle" id="task_Edit_title" value={this.state.taskEditTitle} onChange={this.handleChange}/>
                      </FormGroup>

                      <FormGroup>
                          <Label for="task">Task Description</Label>
                          <Input type="text" name="taskEditDescription" id="task_Editdesc" value={this.state.taskEditDescription} onChange={this.handleChange}/>
                      </FormGroup>

                      <FormGroup>
                          <Label for="task">Task City</Label>
                          <Input type="text" name="taskEditCity" id="task_Edit_city" value={this.state.taskEditCity} onChange={this.handleChange}/>
                      </FormGroup>

                      <FormGroup>
                          <Label for="task">Task Country</Label>
                          <Select
                                name="country"
                                placeholder="Select Country"
                                value={this.state.selectedCountry}
                                options={ this.state.countryData }
                                onChange={ this.handleChangeCountry }
                            />
                      </FormGroup>

                      <FormGroup>
                            <Label for="asset">Keywords</Label><br></br>
                            <CreatableSelect
                            value={ this.state.keywords }
                            isMulti
                            name="keywords"
                            options={ this.state.keywords }
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleChangeKeywords}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="asset">Category</Label><br></br>
                            <Select
                            value={ this.state.currentCategories }
                            isMulti
                            name="keywords"
                            options={ this.state.categories }
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleChangeCategory}
                            />
                        </FormGroup>

                      <FormGroup>
                          <Label for="task">Idea Originator</Label>
                          <Input type="text" name="taskEditIdea" id="task_Edit_idea" value={this.state.taskEditIdea} onChange={this.handleChange}/>
                      </FormGroup>
                  </Form>

                  <Button onClick={this.confirm}>Update Task</Button>
                </div>
            )
        }
    }

    goTaskDetail(taskId) {
        this.props.history.push(`/admin/task-detail/${taskId}`)
    }

    removeSubTask = async(taskId) => {
        const response = await removeTask(this.token, this.networkId, taskId);
        if(response.status === 200){
            const alert = this.props.alert;
            alert.show('Task Removed !');
            this.taskDetail();
        }
    }

    async getUsers(){
        const response = await getZoomUsers(this.token, this.networkId);

        let initialUsers = [];
        if(response.users.length > 0){
            response.users.forEach((user) => {
                initialUsers.push({
                    label: user.firstname + ' ' + user.lastname + ' - ' + this.networkNameConverter(user.networkid),
                    value: user.Id,
                    roleid: user.roleid,
                })
            })
        }

        this.setState({
            usersData: initialUsers
        })
    }

    assign = async (type) => {
        const alert = this.props.alert;
            if(type === "vj" && this.state.selectedAssignUser !== null && this.state.taskVjPrice > 0) {
                const response = await assignTask(this.token, this.networkId, this.props.match.params.id, this.state.selectedAssignUser.value, type, this.state.taskVjPrice, this.state.selectedCurrency.label);
                if(response.status === 200){
                    alert.show("User Assigned !")
                    this.taskDetail();
                }else{
                    alert.show("Task is not suitable for assigning")
                }
            }else if(type === "vj" && this.state.taskVjPrice <= 0 && this.state.selectedAssignUser === null) {
                alert.show("Please enter a user & price.");
            }

            if(type === "editor" && this.state.selectedAssignUser !== null) {
                const response = await assignTask(this.token, this.networkId, this.props.match.params.id, this.state.selectedAssignUser.value, type);
                if(response.status === 200){
                    alert.show("User Assigned !")
                    this.taskDetail();
                }else{
                    alert.show("Vj tasks is not approved yet !");
                }
            }

    }


    userNameConverter (userId) {
        let u = []
        this.state.usersData.map((user) => {
            if(userId === user?.value){
                u.push(user.label)
            }
        })
        return u[0]
    }

    networkNameConverter(networkId) {
        let u = []
        this.state.networkData.map((network) => {
            if(networkId === network.value){
                u.push(network.label)
            }
        })
        return u[0]
    }

    vjTasksList() {
        return (
            <div className="vjArea">
                <ListGroup>
                    { this.state.vjTasks.map((vj, index) =>
                        <ListGroupItem key={index} className="parenTaskListItem">
                            <span>{ vj.task.data.title }</span>
                            { vj.task.data.assignedUserId !== null ? <span className="taskAssignedUser"><i className="ni ni-circle-08 mr-2"/> <strong>{ this.userNameConverter(vj.task.data.assignedUserId) }</strong></span> : "" }
                            <span>{ vj.task.data.status !== 4 && vj.task.data.status !== 10
                            ? <span className="orangeStatus"></span>
                            : vj.task.data.status === 10
                            ? <span className="redStatus"></span>
                            : <span className="greenStatus"></span> }</span>
                            <span>{ this.state.statusesData.map(s => vj.task.data.status === s.role.id ? s.role.name : "")}</span>
                            <span><Button onClick={ () => this.goTaskDetail(vj.task.id) } color="primary">Details</Button></span>
                            {vj.task.data.status === 1 && vj.task.data.createdBy === this.state.userId && vj.task.data.type === "subtask" ? <span><Button onClick={ () => this.confirmRemoveSubTask(vj.task.id) } color="danger">Remove</Button></span> : ""}
                        </ListGroupItem>
                    )
                }
                </ListGroup>
                <div className="assignmentArea">
                        <Select
                            value={this.selectedAssignUser}
                            options={this.state.usersData}
                            onChange={this.handleChangeAssignUser}
                        ></Select>
                        <span className="price-area"><Input required type="number" name="taskVjPrice" id="task_Edit_price" placeholder="Price" onChange={this.handleChange}/></span>
                        <Select
                            className="currency-select"
                            value={this.selectedCurrency}
                            options={this.state.currencyData}
                            onChange={this.handleChangeCurrency}
                        ></Select>
                        <Button color="success" onClick={() => this.confirmAssign("vj")}>Assign VJ</Button>
                </div>
            </div>
        )
    }

    editorTasksList() {
        return (
                <div className="editorArea">
                    <ListGroup>
                        { this.state.editorTasks.map((e, index) =>
                            <ListGroupItem key={index} className="parenTaskListItem">
                                <span>{ e.task.data.title }</span>
                                { e.task.data.assignedUserId !== null ? <span className="taskAssignedUser"><i className="ni ni-circle-08 mr-2"/> <strong>{ this.userNameConverter(e.task.data.assignedUserId) }</strong></span>  : "" }
                                <span>{ e.task.data.status !== 7 && e.task.data.status !== 9 && e.task.data.status !== 11 && e.task.data.status !== 10
                                ? <span className="orangeStatus"></span>
                                : e.task.data.status === 10
                                ? <span className="redStatus"></span>
                                : <span className="greenStatus"></span> }</span>
                                <span>{ this.state.statusesData.map(s => e.task.data.status === s.role.id ? s.role.name : "")}</span>
                                {e.task.data.assetId !== null ? <Badge className="list-asset-id"><a href={`/admin/production-edit/asset/${e.task.data.assetId}`} target="_blank" alt="zoomin">{ e.task.data.assetId }</a></Badge> : ""}
                                <span><Button onClick={ () => this.goTaskDetail(e.task.id) } color="primary">Details</Button></span>
                                {e.task.data.status === 1 && e.task.data.createdBy === this.state.userId && e.task.data.type === "subtask" ? <span><Button onClick={ () => this.confirmRemoveSubTask(e.task.id) } color="danger">Remove</Button></span> : ""}
                            </ListGroupItem>
                        )
                    }
                    </ListGroup>

                    { this.state.parentTask.data.status < 5 ?
                    <div className="assignmentArea">
                            <Select
                                value={this.selectedAssignUser}
                                options={this.state.usersData}
                                onChange={this.handleChangeAssignUser}
                            ></Select>

                            <Button className="assign-editor-button" color="success" onClick={() => this.confirmAssign("editor")}>A. Editor</Button>
                    </div> : ""}
                </div>
        )
    }

    getCultures = async () =>{
        const response = await getCultures(this.token);
        if(response.success){
            let initialCultures = [];
            initialCultures = response.culture.map((culture) => {
                if(culture.IsActive){
                    return culture;
                }
            })

            this.setState({
                cultureData: initialCultures
            })
        }
    }

    cultureNameConverter(cultureId) {
        return this.state.cultureData.map((culture) => {
            if(cultureId === culture?.Id){
                return culture.Name
            }
        })
    }

    localizerTaskList() {
        return (
            this.state.localizerTasks.length > 0 ?
                <ListGroup>
                    { this.state.localizerTasks.map((l, index) =>
                        <ListGroupItem key={index} className="parenTaskListItem">
                            <span>{ l.task.data.title }</span>
                            <span><strong>{ this.cultureNameConverter(l.task.data.cultureId) }</strong></span>
                            { l.task.data.assignedUserId !== null ? <span className="taskAssignedUser"><i className="ni ni-circle-08 mr-2"/> <strong>{ this.userNameConverter(l.task.data.assignedUserId) }</strong></span> : <span className="taskAssignedUser"><i className="ni ni-circle-08 mr-2"/>No user assigned</span> }
                            <span>{ l.task.data.status !== 9 && l.task.data.status !== 11
                            ? <span className="orangeStatus"></span>
                            : <span className="greenStatus"></span> }</span>
                            {l.task.data.assetId !== null ? <Badge className="list-asset-id"><a href={`/admin/production-edit/asset/${l.task.data.assetId}`} target="_blank" alt="zoomin">{ l.task.data.assetId }</a></Badge> : ""}
                            <span><Button onClick={ () => this.goTaskDetail(l.task.id) } color="primary">Details</Button></span>
                        </ListGroupItem>
                    )
                }
                </ListGroup> : <h3>Localizer tasks not yet available</h3>
        )
    }

    parentTaskList() {
        const p = this.state.parentTask.data;
        return(
            <div className="parentTaskArea">
                <h1>{ p?.title } <Badge color="default" pill>Parent Task</Badge></h1>
                { this.parentTaskListItems() }
                <hr></hr>
                <h2>VJ Tasks</h2>
                { this.vjTasksList() }
                <hr></hr>
                <h2>Editor Tasks</h2>
                { this.editorTasksList() }
                <hr></hr>
                <h2>Localizer Tasks</h2>
                { this.localizerTaskList() }
            </div>
        )
    }

    async getCountry(){
        const response = await getCountry(this.token);
        let initCountry = [];
        response.countries.forEach((c) => {
            initCountry.push({
                label: c.Name,
                value: c.Id
            })
        })

        this.setState({
            countryData: initCountry,
        })
    }

    async componentDidMount() {
        await this.getUser();
        await this.getCountry();
        await this.getCultures();
        await this.getStatus();
        await this.getNetworks();
        await this.getUsers();
        await this.getCategories();
        await this.taskDetail();
        this.setKeywords();
        this.initKeywordsAndCategories();

        this.setState({
            taskEditTitle: this.state.parentTask.data?.title,
            taskEditCity: this.state.parentTask.data?.city,
            taskEditCountry: this.state.parentTask.data?.country,
            taskEditDescription: this.state.parentTask.data?.description,
            taskEditIdea: this.state.parentTask.data?.ideaOriginator
        })
    }

    render() {
        return (
            <div>
                <Header/>
                { this.state.taskFound ? <Container>
                    { this.parentTaskList() }
                    <Button onClick={this.confirmRemove} color="danger" className="mt-3">Remove</Button>
                </Container> : <div className="not-found-area"><img src="https://samherbert.net/svg-loaders/svg-loaders/puff.svg" alt="zoomin"></img></div> }
            </div>
        )
    }
}

export default withAlert()(ParentDetail)