import React, { Component } from 'react'
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Header from '../../components/Headers/Header';
import { getCultureById, updateCulture } from 'services/cultureService';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { withAlert } from 'react-alert'

class Edit extends Component {

    constructor(props){
        super(props);
        this.state = {
            cultureName: "",
            active: null
        }
        this.token = window.zoomCrypt.get("token")
        this.cultureId = this.props.match.params.id;
    }

    handleChangeStatus = selectedOption => {
        this.setState({ selectedOption });
    }

    handleChangeName = e => {
        let cultureName = e.target.value;
        this.setState({ cultureName });
    }

    getCulture = async () => {
        const response = await getCultureById(this.token, this.cultureId);

        if(response.success){
            this.setState({
                cultureName: response.culture.Name,
                active: response.culture.IsActive,
            })
        }
    }

    confirmUpdate = () => {
        confirmAlert({
            title: 'Update Culture',
            message: 'Are you sure you want to update ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.onUpdateClick()
            },
            {
                label: 'No',
            }
            ]
        });
    };

    refreshPage() {
        this.props.history.push('/admin/cultures');
    }

    onUpdateClick = async e => {
        e && e.preventDefault();
        const response = await updateCulture(this.token, this.state.cultureName, this.state.active, this.cultureId);
        if(response.status === 204){
            this.refreshPage();
        }
    }

    componentDidMount(){
        this.getCulture();
    }

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }

    render() {
        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                <Form>
                    <FormGroup>
                        <Label for="culture">Culture</Label>
                        <Input type="text" name="cultureName" id="culture_name" placeholder="Culture Name" onChange={this.handleChangeName} value={this.state.cultureName}/>
                    </FormGroup>
                    <FormGroup check className="mb-3">
                        <Label check>
                        <Input type="checkbox" checked={this.state.active || false} onChange={this.handleChangeActive}/>{' '}
                            Active
                        </Label>
                    </FormGroup>
                    <Button onClick={this.confirmUpdate}>Update Culture</Button>
                    </Form>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Edit)
