import React from "react";

// reactstrap components
import { Container, Breadcrumb, BreadcrumbItem } from "reactstrap";

class Header extends React.Component {

  getCurrentPage(){
    let path = window.location.pathname.replace("/admin/", "").replace(/\//g, ' / ');;
    return path
  }

  render() {
    return (
      <>
        <div className="header bg-gradient-default opacity-8 pb-3 pt-5 pt-md-8 mb-4">
          <Container fluid>
            <Breadcrumb>
              <BreadcrumbItem><a href="/admin/index">Dashboard</a></BreadcrumbItem>
              <BreadcrumbItem active>{ this.getCurrentPage() }</BreadcrumbItem>
            </Breadcrumb>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
