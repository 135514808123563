import React, { Component } from 'react'
import Header from '../../components/Headers/Header';
import { Button, Container } from 'reactstrap';
import { getLocalizerTasks } from 'services/taskService';
import ReactTable from 'react-table';
import "react-table/react-table.css";

export default class Index extends Component {
    constructor(){
        super();
        this.state = {
            localizerTasksData: []
        }

        this.token = window.zoomCrypt.get("token");
        this.network = window.zoomCrypt.get("networkId");
    }

    async getLocalizerTasks(){
        const response = await getLocalizerTasks(this.token, this.network);

        let initialTasks = [];

        initialTasks = response.filter(task => task.data.assignedVjId === this.state.userId || task.data.assignedEditorId === this.state.userId);

        this.setState({
            localizerTasksData: initialTasks,
        })
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    onProcess = (taskId) => {
        this.props.history.push(`process-task/${taskId}`)
    }

    async componentDidMount(){
        await this.getLocalizerTasks();
    }

    render() {
        const columns = [
            {
                Header: "Id",
                accessor: "id",
                filterable: false,
            },
            {
                Header: "Name",
                accessor: "data.title"
            },
            {
                Header: "Language",
                accessor: "Name",                
                filterable: true,
            },
            {
                Header: "Edit",
                Cell: props => {
                    return(
                       <Button onClick={() => this.onProcess(props.original.id + '?task=localizer')} color="primary">Edit</Button>
                    )
                },
                accessor: "edit",
                filterable: false,
                sortable: false
            },
        ]
        return (
            <div>
                <Header></Header>
                <Container>
                    <ReactTable
                        className="zoom-table"
                        columns={columns}
                        filterable
                        defaultFilterMethod={this.filterCaseInsensitive}
                        showPaginationTop={true}
                        data={this.state.localizerTasksData}>
                    </ReactTable>
                </Container>
            </div>
        )
    }
}
