import React, { Component } from 'react'
import { Container, Form, FormGroup, Label, Input, Button, Badge } from 'reactstrap';
import Header from '../../components/Headers/Header';
import { getPublisherSiteById, createPublisherSite, updatePublisherSite } from 'services/publisherSiteService';
import { getNetworks } from 'services/networkService';
import { getPublishers } from 'services/publisherService';
import { getCultures } from 'services/cultureService';
import { getCategoryByCultureId } from "services/categoryService";
import GeneralModal from '../../components/Modals/GeneralModal';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactTable from 'react-table';
import { withAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Edit extends Component {

    constructor(props){
        super(props);
        this.state = {
            publisherSiteData: {},
            networkData: [],
            publisherData: [],
            cultureData: [],
            categoryData: [],
            websiteUrl: '',
            selectedNetwork: null,
            selectedPublisher: null,
            selectedCulture: null,
            selectedCategories: null,
            selectedKeywords: null,
            active: false,
            openModal: false,
            pageCode: '',
            pageCodePrevious: null,
            pageName: '',
            pageOrder: 1,
            onlyMyNetwork: false,
            isMainPage: false
        }
        this.token = window.zoomCrypt.get("token")
        this.publisherSiteId = this.props.match.params.id;
    }

    getPublisherSiteDataById = async () => {
        if(this.publisherSiteId !== undefined) {
            const response = await getPublisherSiteById(this.token, this.publisherSiteId);
            if(response.success){
                this.setState({
                    publisherSiteData: response.publisherSite,
                    active: response.publisherSite.isactive,
                    websiteUrl: response.publisherSite.siteurl,
                    selectedNetwork: {value: response.publisherSite.NetworkId, label: response.publisherSite.NetworkName},
                    selectedPublisher: {value: response.publisherSite.publisherid, label: response.publisherSite.PublisherName}
                }, () => {
                    this.getPublishers(this.state.selectedNetwork.value);
                })
            }
        }        
    }

    getNetworks = async () => {
        const response = await getNetworks(this.token);
        
        let initialNetworks = [];
        if(response.success){
            response.networks.map((network) => {
                initialNetworks.push({
                    value: network.Id,
                    label: network.Name,
                })
            })
            this.setState({
                networkData: initialNetworks
            })
        }
    }

    getPublishers = async () => {
        const response = await getPublishers(this.token);
        if(response.success){
            let initialPublishersOptions = [];
            response.publishers.map((publisher) => {
                if(publisher.NetworkId === this.state.selectedNetwork.value){
                    initialPublishersOptions.push({
                        value: publisher.Id,
                        label: publisher.Name,
                        pid: publisher.Pid,
                    })
                }
            })

            this.setState({
                publisherData: initialPublishersOptions
            })
        }
    }

    confirmUpdate = () => {
        confirmAlert({
            title: 'Update whitelabel site',
            message: `Are you sure you want to update, changes will effect your website(${this.state.websiteUrl}) immediately?`,
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.onUpdateClick()
            },
            {
                label: 'No',
            }
            ]
        });
    };

    onUpdateClick = async e => {
        e && e.preventDefault();
        const alert = this.props.alert;

        if(this.state.websiteUrl === '')
        {
            alert.show(<div style={{ textTransform: 'initial'}}>Please enter your web site url</div> );   
            return;
        }
        if(this.state.selectedNetwork === null)
        {
            alert.show(<div style={{ textTransform: 'initial'}}>Please select a network</div> );   
            return;
        }
        if(this.state.selectedPublisher === null)
        {
            alert.show(<div style={{ textTransform: 'initial'}}>Please select a publisher</div> );   
            return;
        }

        let isMainExists = false;
        let pageCount = 0;
        let mlist = this.state.publisherSiteData.menulist;
        mlist.forEach(list => {
            let cats = list.categories !== null ? list.categories.map(item => item.value) : [];
            let cults = list.cultures !== null ? list.cultures.map(item => item.value) : [];
            let keyws = list.keywords !== null ? list.keywords.map(item => item.value) : [];
            list.categories = cats;
            list.cultures = cults;
            list.keywords = keyws;
            pageCount++;
            if(!isMainExists)
                isMainExists = list.ismain;
        });

        if(!pageCount) {            
            alert.show(<div style={{ textTransform: 'initial'}}>You have to select at least one Main Page</div> );   
            return;
        }

        if(!isMainExists) {
            alert.show(<div style={{ textTransform: 'initial'}}>You have to add at least one page</div> );   
            return;
        }

        let response = null;
        if(this.publisherSiteId === undefined) {
            response = await createPublisherSite(this.token,
                this.state.selectedPublisher.value,
                mlist,
                this.state.websiteUrl,
                this.state.active);
            
            if(response.success)
                this.props.history.push('/admin/whitelabels');
            else {
                alert.show(<div style={{ textTransform: 'initial'}}>Opps! Error occured while saving. Please try again later.</div> );   
                return;
            }
        }
        else {
            response = await updatePublisherSite(this.token,
                this.publisherSiteId,
                mlist,
                this.state.websiteUrl,
                this.state.active);  
            
            if(response.success){
                this.refreshPage();
            }
            else {
                alert.show(<div style={{ textTransform: 'initial'}}>Opps! Error occured while saving. Please try again later.</div> );   
                return;
            }
        }          
    }

    getCategories = async () =>{
        if(this.state.selectedCulture !== null){

            let cults = this.state.selectedCulture.map(item => item.value);
            
            const response = await getCategoryByCultureId(this.token, cults.join(','));
            if(response.success){
                let initialCategories = [];
                response.category.map((category) => {
                    if(category.IsActive){
                        initialCategories.push({
                            label: category.IsIab ? `${category.Name} ${category.ShortName}` : category.Name,
                            value: category.Id,
                        })
                    }
                })

                this.setState({
                    categoryData: initialCategories.sort((a, b) => a.label.localeCompare(b.label))
                })
            }
        }
    }

    getCultures = async () => {
        const response = await getCultures(this.token);
        let initialCultures = [];
        if(response.success){
            response.culture.map((culture) => {
                initialCultures.push({
                    value: culture.Id,
                    label: culture.Name,
                })
            })

            this.setState({
                cultureData: initialCultures.sort((a, b) => a.label.localeCompare(b.label))
            })
        }
    }

    handleChangeWebsiteUrl = e => {
        let websiteUrl = e.target.value;
        this.setState({ websiteUrl });
    }

    handleChangeNetwork = selectedNetwork => {
        this.setState({ selectedNetwork }, () => this.getPublishers(selectedNetwork.value));
    }

    handleChangePublisher = selectedPublisher => {
        this.setState({ selectedPublisher });
    }

    handleChangeCulture = selectedCulture => {
        this.setState({ selectedCulture }, () => this.getCategories());
    }

    handleSelectedCategory = selectedCategories => {
        this.setState({ selectedCategories });
    }

    handleSelectedKeywords = selectedKeywords => {
        this.setState({ selectedKeywords });
    }
    
    handleChangePageName = e => {
        this.setState({ pageName: e.target.value, pageCode: e.target.value.replace(/[^a-zA-Z]/g, '').toLowerCase() });
    }

    handleChangePageOrder = e => {
        let pageOrder = e.target.value;
        this.setState({ pageOrder });
    }
    
    refreshPage() {
        this.getPublisherSiteDataById();
    }

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }

    handleChangeOnlyMyNetwork = e => {
        let onlyMyNetwork = e.target.checked;
        this.setState({ onlyMyNetwork });
    }

    handleChangeMainPage = e => {
        let isMainPage = e.target.checked;
        this.setState({ isMainPage });
    }

    

    toggle = () => {
		this.setState({
            selectedCulture: null,
            selectedCategories: null,
            selectedKeywords: null,
            selectedPageType: null,
            pageName: '',
            pageCode: '',
            pageOrder: 1,
            pageCodePrevious: null,
            onlyMyNetwork: false,
            isMainPage: false,
            openModal: !this.state.openModal
        })
    }

    toggleEdit = (type) => {
        let psData = this.state.publisherSiteData;
        let data = psData.menulist.filter(item => item.type === type)[0];
        let cat = data.categories;
        let cult = data.cultures;
        this.setState({
            selectedCulture: cult,
            selectedCategories: cat,
            selectedKeywords: data.keywords,
            pageName: data.name,
            pageCode: data.type,
            pageCodePrevious: data.type,
            pageOrder: data.pageorder || 1,
            onlyMyNetwork: data.onlyMyNetwork,
            isMainPage: data.ismain,
            openModal: !this.state.openModal
        }, () => this.getCategories())
    }

    toggleRemove = (type) => {
        let psData = this.state.publisherSiteData;
        let menu = psData.menulist.filter(item => item.type !== type);
        psData.menulist = menu;
        this.setState({ publisherSiteData: psData })
        
    }

    onSaveClick = () => {
        let psData = this.state.publisherSiteData;
        let index = -1;

        if(this.state.pageName === '')
        {
            const alert = this.props.alert;
            alert.show(<div style={{ textTransform: 'initial'}}>Please fill the page name</div> );   
            return;
        }

        if(psData.menulist === undefined) {
            psData.menulist = [];
        }

        if(this.state.pageCodePrevious !== null) { 
            index = psData.menulist.findIndex(item => item.type == this.state.pageCodePrevious); 
        }

        if(this.state.isMainPage)
                psData.menulist.forEach(item => item.ismain = false);

        if(index > -1) {
            psData.menulist[index] = {
                "name": this.state.pageName,
                "type": this.state.pageCode,
                "ismain": this.state.isMainPage,
                "cultures": this.state.selectedCulture,
                "keywords": this.state.selectedKeywords,
                "categories": this.state.selectedCategories,
                "pageorder": this.state.pageOrder,
                "onlyMyNetwork": this.state.onlyMyNetwork,
                "videoLoadCount": 18
            };
        }
        else {            
            psData.menulist.push({
                "name": this.state.pageName,
                "type": this.state.pageCode,
                "ismain": this.state.isMainPage,
                "cultures": this.state.selectedCulture,
                "keywords": this.state.selectedKeywords,
                "categories": this.state.selectedCategories,
                "pageorder": this.state.pageOrder,
                "onlyMyNetwork": this.state.onlyMyNetwork,
                "videoLoadCount": 18
            })   
        }
             
        
        this.setState({publisherSiteData: psData, openModal: false });
    }

    async componentDidMount(){
        await this.getNetworks();
        await this.getPublisherSiteDataById();
        await this.getCultures();
    }

    render() {
        
        const columns = [
            {
                Header: () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Page Name</div>),
                accessor: "name",
                filterable: false,
            },
            {
                Header: () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Page Code</div>),
                accessor: "type",
                filterable: false,
            },
            {
                Header: () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Page Order</div>),
                accessor: "pageorder",
                filterable: false,
            },
            {
                id: 'ismain',
                Header: () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Main Page</div>),
                accessor: d => { return d.ismain == true ? <p>&#9989;</p> : <p>&#10060;</p> },
                filterable: false,
            },
            {
                Header: () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Edit</div>),
                Cell: props => {
                    return (
                        <Button color="primary" onClick={() => this.toggleEdit(props.original.type)}>Edit</Button>
                    )
                },
                accessor: "edit",
                filterable: false,
                sortable: false
            },
            {
                Header: () => (
                    <div
                      style={{
                        textAlign:"left"
                      }}
                    >Remove</div>),
                Cell: props => {
                    return (
                        <Button color="danger" onClick={() => this.toggleRemove(props.original.type)}>Remove</Button>
                    )
                },
                accessor: "remove",
                filterable: false,
                sortable: false
            }
        ]
        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                    <Form>
                    <GeneralModal
                        isOpen={this.state.openModal}
                        toggle={this.toggle}
                        title='Add page to your web site'
                        primaryButton={this.state.pageCodePrevious !== null ? 'Update Page' : 'Add Page'}
                        className='modal-content bg-light'
                        secondaryButton='Cancel'
                        onAction={this.onSaveClick}>
                        <Form>
                            <FormGroup>
                                <Label >Page Name<Badge color="danger" pill>*required</Badge></Label>
                                <Input type="text" name="name" id="page_name" onChange={this.handleChangePageName} value={this.state.pageName}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="pageCode">Page Code</Label>
                                <Input readOnly type="text" name="name" id="page_name" value={this.state.pageCode}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="cultureSelect">Culture</Label>
                                <Select
                                    options={this.state.cultureData}
                                    value={this.state.selectedCulture}
                                    onChange={this.handleChangeCulture}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isMulti>
                                </Select>
                            </FormGroup>                            
                            <FormGroup>
                                <Label for="categorySelect">Categories</Label>
                                <Select
                                    value={this.state.selectedCategories}
                                    options={this.state.categoryData}
                                    onChange={this.handleSelectedCategory}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    isMulti
                                ></Select>
                            </FormGroup>
                            <FormGroup>
                                <Label for="keywordSelect">Keywords</Label><br></br>
                                <CreatableSelect
                                value={ this.state.selectedKeywords }
                                isMulti
                                name="keywords"
                                options={ this.state.selectedKeywords }
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={this.handleSelectedKeywords}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label >Page Order No</Label>
                                <Input type="number" name="name" id="page_order" onChange={this.handleChangePageOrder} value={this.state.pageOrder}/>
                            </FormGroup>
                            <FormGroup check className="mb-3">
                                <Label check>
                                <Input type="checkbox" checked={this.state.onlyMyNetwork} onChange={this.handleChangeOnlyMyNetwork}/>{' '}
                                    Show only the videos in my network
                                </Label>
                            </FormGroup> 
                            <FormGroup check className="mb-3">
                                <Label check>
                                <Input type="checkbox" checked={this.state.isMainPage} onChange={this.handleChangeMainPage}/>{' '}
                                    Main page
                                </Label>
                            </FormGroup>  
                        </Form>
                    </GeneralModal>
                        <FormGroup>
                            <Label for="websiteurl">Website Url<Badge color="danger" pill>*required</Badge></Label>
                            <Input readOnly={this.publisherSiteId === undefined ? false : true} type="text" name="name" id="websiteurl" onChange={this.handleChangeWebsiteUrl} value={this.state.websiteUrl}/>
                        </FormGroup>
                        <FormGroup>
                                <Label for="publisher">Networks<Badge color="danger" pill>*required</Badge></Label>
                                <Select isDisabled={this.publisherSiteId === undefined ? false : true}
                                    value={this.state.selectedNetwork}
                                    options={this.state.networkData}
                                    onChange={this.handleChangeNetwork}
                                ></Select>
                        </FormGroup>
                        <FormGroup>
                                <Label for="publisher">Publisher<Badge color="danger" pill>*required</Badge></Label>
                                <Select isDisabled={this.publisherSiteId === undefined ? false : true}
                                    value={this.state.selectedPublisher}
                                    options={this.state.publisherData}
                                    onChange={this.handleChangePublisher}
                                ></Select>
                        </FormGroup>
                        <FormGroup check className="mb-3">
                            <Label check>
                            <Input type="checkbox" checked={this.state.active} onChange={this.handleChangeActive}/>{' '}
                                Active
                            </Label>
                        </FormGroup>                        
                        <div style={{ textAlign:"right", marginBottom: '10px' }}>
                            <Button onClick={this.toggle}>Add Page</Button>
                        </div>
                        <ReactTable
                            style={{height: "50vh"}}
                            className="zoom-table"
                            columns={columns}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            data={this.state.publisherSiteData.menulist}>
                        </ReactTable>
                        <hr></hr>
                        <div style={{ textAlign:"right" }}>
                            <Button onClick={this.confirmUpdate}>Save Whitelabel Site</Button>
                        </div>
                    </Form>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Edit)