import React, { Component } from 'react'
import { Button, Badge, Container, Row, Col, Card, CardBody, CardTitle, CardText, Form, FormGroup, Label, Input  } from 'reactstrap';
import Header from '../../components/Headers/Header';
import Filter from '../../components/Filters/Filter';
import { getPublishedAssets, setSchedule, setWithdraw, removeAsset } from 'services/assetService';
import { getCultures } from 'services/cultureService';
import { getZoomUser } from 'services/zoomUserService';
import { getPublishers } from "services/publisherService";
import EllipsisText from "react-ellipsis-text";
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { withAlert } from 'react-alert'
import GeneralModal from '../../components/Modals/GeneralModal';
import Select from 'react-select';

class Index extends Component {

    constructor(){
        super();
        this.state = {
            assetData: [],
            filteredData: [],
            cultureData: [],
            limit: 20,
            offset: 0,
            totalPage: 0,
            cultureId: localStorage.getItem('TheSelectedCulture') ? JSON.parse(localStorage.getItem('TheSelectedCulture')).value : 0,
            scAssetId: null,
            selectedDate: null,
            categoryFilterData: [],
            keywordFilterData: [],
            selectedFilterMinDate: null,
            selectedFilterMaxDate: null,
            filterTitle: "",
            filterDescription: "",
            currentUserNetworks: [],
            selectedPage: null,
            userId: 0,
            filterAssetId: "",
            iFrameModal: false,
            publisherData: [],
            selectedPublisher: localStorage.getItem('defaultPublisher') ? JSON.parse(localStorage.getItem('defaultPublisher')) : null,
            iFrameCode: "",
            iFrameAssetId: null
        }

        this.token = window.zoomCrypt.get("token");
        this.network = window.zoomCrypt.get("networkId");
        this.sub = window.zoomCrypt.get("sub");

    }

    async getUser(){
        const response = await getZoomUser(this.token, this.sub, this.network);
        if(response.success){
            response.user.Networks.forEach((n) => {
                this.setState({
                    currentUserNetworks: [...this.state.currentUserNetworks, n.NetworkId]
                })
            })
        }
    }

    getPublishedAssets = async (limit, offset, cultureId, title, description, category, keyword, minPublicatedDate, maxPublicatedDate) => {
        
        cultureId = this.state.cultureId !== 0 ? this.state.cultureId : undefined; 
        let userId = this.state.userId !== 0 ? this.state.userId : undefined;
        let assetId = this.state.filterAssetId != "" ? this.state.filterAssetId : undefined;

        if(this.state.filterTitle !== '')
            title = this.state.filterTitle;
        
        if(this.state.filterDescription !== '')
            description = this.state.filterDescription;

        if(this.state.categoryFilterData !== [])
            category = this.state.categoryFilterData;
        
        if(this.state.keywordFilterData !== [])
            keyword = this.state.keywordFilterData;

        if(this.state.selectedFilterMinDate !== null) {
            minPublicatedDate = this.state.selectedFilterMinDate;
        }

        if(this.state.selectedFilterMaxDate !== null) {
            maxPublicatedDate = this.state.selectedFilterMaxDate;
        }

        const response = await getPublishedAssets(this.token, parseInt(this.network), limit, offset, cultureId, title, description, category, keyword, minPublicatedDate, maxPublicatedDate, userId, assetId);
        let initialAssets = [];
        if(response.assets.length > 0) {
            initialAssets = response.assets.map((asset) => {
                return asset;
            })
        }

        this.setState({
            assetData: initialAssets,
            filteredData: initialAssets,
            totalPage: Math.ceil(response.totalRowCount / 20)
        })

        window.scrollTo(0, 0);
    }

    iFrameToggle = (assetId) => {
		this.setState({
            iFrameAssetId: assetId,
            iFrameModal: !this.state.iFrameModal            
        }, () => this.setIframeCode())
    }

    handleSelectedDate = selectedDate => {
        this.setState({ selectedDate: selectedDate.target.value });
    }

    handlePageClick = data => {        
        let offset = data.selected * this.state.limit;
        this.setState({ offset: offset, selectedPage: data.selected}, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset);            
        });
    };

    handleFilterChange = data => {
        let cultureId = data;
        this.setState({ cultureId: cultureId }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.filterTitle, this.state.filterDescription, this.state.cultureId);
        });
    }

    handleFilterTextChange = data => {
        let searchedText = data;
        const currentAssetData = this.state.assetData;
        if(searchedText.length > 2){
            const filteredData = currentAssetData.filter(item =>
                item.data.Title.toLowerCase().includes(searchedText.toLowerCase())).map(searchedItems => {
                    return searchedItems;
                });

                this.setState({
                    filteredData
                })
        }else{
            this.setState({
                filteredData: this.state.assetData
            })
        }
    }

    refreshPage() {
        this.getPublishedAssets(this.state.limit, this.state.offset);
    }

    setScheduled = async () => {
        if(this.state.scAssetId !== null && this.state.selectedDate !== null){
            const response = await setSchedule(this.token, this.network, this.state.scAssetId, new Date(this.state.selectedDate).toISOString());
            if(response.status === 200){
                this.refreshPage();
            }
            else{
                const alert = this.props.alert;
                alert.show('An error occured while scheduling the asset');
            }
        }
    }

    setWithdrawn = async (assetId) => {
        const response = await setWithdraw(this.token, this.network, assetId);
        if(response.status === 200){
            this.refreshPage();
        }
        else{
            const alert = this.props.alert;
            alert.show('An error occured while withdrawing the asset');
        }
    }

    confirmSetWithdrawn = (assetId) => {
        confirmAlert({
            title: 'Set Withdrawn',
            message: 'Are you sure you want to withdrawn ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.setWithdrawn(assetId)
            },
            {
                label: 'No',
            }
            ]
        });
    };

    confirm = (assetId) => {
        confirmAlert({
            title: 'Remove Published',
            message: 'Are you sure you want to delete ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.remove(assetId)
            },
            {
                label: 'No',
            }
            ]
        });
    };

    remove = async (assetId) => {
        const response = await removeAsset(this.token, this.network, assetId);
        if(response.status === 200){
            this.refreshPage();
        }
        else{
            const alert = this.props.alert;
            alert.show('An error occured while removing the asset');
        }
    }

    cultureNameConverter(cultureId) {
        return this.state.cultureData.map((culture) => {
            if(cultureId === culture?.value){
                return culture.label
            }
        })
    }

    handleFilterTitle = filterTitle => {
        this.setState({ offset: 0, selectedPage: 0, filterTitle }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }

    handleFilterDescription = filterDescription => {
        this.setState({ offset: 0, selectedPage: 0, filterDescription }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }

    resetDates() {
        this.setState({ offset: 0, selectedPage: 0, selectedFilterMinDate: null }, () => {
            document.getElementById("minDateTime").value = ""
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId);
        });

        this.setState({ offset: 0, selectedPage: 0, selectedFilterMaxDate: null }, () => {
            document.getElementById("maxDateTime").value = ""
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId);
        });
    }

    getCultures = async () => {
        const response = await getCultures(this.token);
        let initialCultures = [];
        if(response.success){
            response.culture.forEach((culture) => {
                initialCultures.push({
                    value: culture.Id,
                    label: culture.Name,
                })
            })

            this.setState({
                cultureData: initialCultures
            })
        }
    }

    handleChangeCulture = data => {
        let cultureId = data.value;
        this.setState({ offset: 0, selectedPage: 0, cultureId: cultureId,  categoryFilterData: [] }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }

    handleChangeUser = data => {
        let userId = data.value;
        this.setState({ offset: 0, selectedPage: 0, userId: userId }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }   

    handleFilterAssetId = filterAssetId => {
        this.setState({ offset: 0, selectedPage: 0, filterAssetId }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }

    handleChangeCategory = data => {
        let category = [];
        if(data !== null){
            data.forEach(d => {
                category.push(d.value)
            })
        }else{
            this.getPublishedAssets(this.state.limit, this.state.offset);
        }

        this.setState({ offset: 0, selectedPage: 0,
            categoryFilterData: category
        }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData);
        })
    }

    handleChangeKeyword = data => {
        let keyword = [];
        if(data !== null){
            data.forEach(d => {
                keyword.push(d.label)
            })
        }else{
            this.getPublishedAssets(this.state.limit, this.state.offset);
        }
        this.setState({ offset: 0, selectedPage: 0,
            keywordFilterData: keyword
        }, () => {
            this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData);
        })
    }

    handleSelectedFilterMinDate = selectedFilterMinDate => {
        if(selectedFilterMinDate.target.value !== ""){
            this.setState({ offset: 0, selectedPage: 0, selectedFilterMinDate: selectedFilterMinDate.target.value }, () => {
                this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData, new Date(this.state.selectedFilterMinDate).toISOString());
            });
        }
    }


    handleSelectedFilterMaxDate = selectedFilterMaxDate => {
        if(selectedFilterMaxDate.target.value !== ""){
            this.setState({ offset: 0, selectedPage: 0, selectedFilterMaxDate: selectedFilterMaxDate.target.value }, () => {
                this.getPublishedAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData, new Date(this.state.selectedFilterMinDate).toISOString(), new Date(this.state.selectedFilterMaxDate).toISOString());
            });
        }
    }

    handleSelectedPublisher = selectedPublisher => {
        localStorage.setItem('defaultPublisher', JSON.stringify(selectedPublisher));
        this.setState({ selectedPublisher }, () => this.setIframeCode());
    }

    setIframeCode = () => {
        
        if(this.state.selectedPublisher !== null)
            this.setState({                
                iFrameCode: `<div style="position: relative; padding-bottom: 56.25%; /* 16:9 */ height: 0;"><iframe style="position: absolute;  top: 0;  left: 0;  width: 100%;  height: 100%;" width="560" height="349" src="https://play.zoomin.tv?assetId=${this.state.iFrameAssetId}&publisherId=${this.state.selectedPublisher.pid}" id="zoomFrame" frameborder="0" scrolling="no" allowfullscreen="true"></iframe></div>`
            });
    }

    getPublishers = async () => {
        const response = await getPublishers(this.token);
        if(response.success){
            let initialPublishersOptions = [];
            response.publishers.map((publisher) => {               
                if(publisher.NetworkId === this.network){
                initialPublishersOptions.push({
                    value: publisher.Id,
                    pid: publisher.Pid,
                    label: publisher.Name.concat(" - ", publisher.Pid),
                })
                }
            })

            this.setState({
                publisherData: initialPublishersOptions.sort((a, b) => a.label.localeCompare(b.label))
            })
        }
    }


    assetCardList(){
        return this.state.filteredData.map((asset, index) => {
            return (
                <React.Fragment key={ index } >
                 
                    <div className="assetActionArea" key={index}>
                        <span className="mr-2"><strong>ID: </strong> {asset.id} </span>                        
                        { this.network === asset.networkId ? <Button onClick={() => this.confirmSetWithdrawn(asset.id)} size="sm" color="danger">Set Withdrawn</Button> : "" }
                        <Button onClick={() => this.iFrameToggle(asset.id)} size="sm" color="info">Get iframe code</Button>
                    </div>
                
                <a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push(`published-edit/asset/${asset.id}`)}}>
                    <Card className="mb-4" >
                        <Row className="no-gutters">
                            <Col md="4">
                                <img src={ asset.data.ImageRemotePath !== null ? asset.data.ImageRemotePath.replace('http://images.','https://images.') + asset.data.ImageFileName : "https://dummyimage.com/600x400/000/fff.png&text=Image+Not+Found"} alt="" className="card-img-top"/>
                            </Col>

                            <Col md="7" className="mt-2">
                                <CardBody>
                                    <CardTitle className="mb-0">{ asset.data.Title }</CardTitle>
                                    <Badge className="badge-default" pill>
                                    { this.cultureNameConverter(asset.cultureId) }
                                    </Badge>
                                    <CardText>
                                        { asset.data.Description !== undefined
                                        ? <EllipsisText text={ asset.data.Description } length={180} />
                                        : asset.data.Description }
                                    </CardText>                                    
                                </CardBody>
                            </Col>

                            <Col md="1" className="text-center mt-5">
                                <div className="large">{ asset.data.PublicatedDate !== null ? moment(asset.data.PublicatedDate).format("DD") : moment(asset.data.CreatedDate).format("DD") }</div>
                                <div className="text-muted">{ asset.data.PublicatedDate !== null ? moment(asset.data.PublicatedDate).format("MMM") : moment(asset.data.CreatedDate).format("MMM") }</div>
                                <div className="text-muted">
                                    <span className="small">{ asset.data.PublicatedDate !== null ? moment(asset.data.PublicatedDate).format("YYYY") : moment(asset.data.CreatedDate).format("YYYY") }</span>
                                </div>
                                <div className="text-muted">
                                    <Badge className="badge-default">{ asset.data.PublicatedDate !== null ? moment(asset.data.PublicatedDate).format("HH:mm") : moment(asset.data.CreatedDate).format("HH:mm") }</Badge>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </a>
                </React.Fragment>
            )
          })
    }

    componentDidMount(){
        this.getUser();
        this.getPublishedAssets(this.state.limit, this.state.offset);
        this.getCultures();
        this.getPublishers();
    }

    render() {
        return (
            <div>
                <Header/>
                <Container className="mt-10">
                    <GeneralModal
                        isOpen={this.state.iFrameModal}
                        toggle={this.iFrameToggle}
                        title='Get iframe code'
                        className='modal-content bg-light'
                        secondaryButton='Close'>
                        <Form>
                            <FormGroup>
                                <Label for="publisherSelect">Publisher</Label>
                                <Select
                                    value={this.state.selectedPublisher}
                                    options={this.state.publisherData}
                                    onChange={this.handleSelectedPublisher}
                                ></Select>
                            </FormGroup>
                            <FormGroup>
                                <Label for="iframecode">Iframe Code (Embed Code)</Label>
                                <Input readOnly type="textarea" name="text" id="iframecode" value={this.state.iFrameCode} style={{height:"100px"}}/>
                            </FormGroup>
                        </Form>
                    </GeneralModal>
                    <h1>Published Latest Videos</h1>
                    <Row>
                        <Col md="9">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.state.totalPage}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            breakLinkClassName={'page-link'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            forcePage={this.state.selectedPage}
                        ></ReactPaginate>
                        {this.state.assetData.length > 0 ?
                            this.assetCardList() :
                            <h1>No Content</h1>
                        }
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.state.totalPage}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            breakLinkClassName={'page-link'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            forcePage={this.state.selectedPage}
                        ></ReactPaginate>
                        </Col>
                        <Col md="3">
                        <Filter onChangeCategorySelect={this.handleChangeCategory}
                                    onChangeCultureSelect={this.handleChangeCulture}
                                    onChangeKeywordSelect={this.handleChangeKeyword}
                                    onChangeMinDate={this.handleSelectedFilterMinDate}
                                    onChangeMaxDate={this.handleSelectedFilterMaxDate}
                                    onChangeTitle={this.handleFilterTitle}
                                    onChangeDescription={this.handleFilterDescription}
                                    onChangeUserSelect={this.handleChangeUser}
                                    onChangeAssetId={this.handleFilterAssetId}
                                    showUserFilter={true}>
                            </Filter>
                            <FormGroup>
                                <Button onClick={this.resetDates.bind(this)}>Reset Dates</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Index)