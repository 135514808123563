import React, { Component } from 'react'
import { Container, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import Header from '../../components/Headers/Header';
import { getAssetById, updateAsset} from 'services/assetService';
import { getCategoryByCultureId } from 'services/categoryService';
import ReactPlayer from 'react-player';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { confirmAlert } from 'react-confirm-alert';
import { saveAs } from 'file-saver';
import { downloadFileSolo } from 'services/downloadService'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { withAlert } from 'react-alert';
import moment from 'moment';

class Edit extends Component {

    constructor(){
        super();
        this.state = {
            assetData: [],
            video: [],
            keywords: [],
            keywordsLabel: [],
            categories: [],
            categoriesValue: [],
            currentCategories: [],
            assetCultureId: null,
            downloadStartedImage: false,
            downloadStartedVideo: false,
            videoFileName: "",
            active: null
        }

        this.token = window.zoomCrypt.get("token");
        this.network = window.zoomCrypt.get("networkId");
    }

    getAssetDetail = async () => {
        const assetId = this.props.match.params.id;
        const response = await getAssetById(this.token, assetId, this.network);        
            this.setState({
                assetCultureId: response.cultureId,
                assetData: response.data,
                video: response.files.files.length > 0 ? response.files.files[0].RemotePath.replace('http://stream', 'https://ssl-stream') + response.files.files[0].FileName + '.' + response.files.files[0].Extension : [],
                videoFileName: response.files.files.length > 0 ? response.files.files[0].FileName + '.' + response.files.files[0].Extension : "",
                active: response.data.IsCommercialAllowed,
                assetImage: response.data.ImageRemotePath.replace('http://images.','https://images.') + response.data.ImageFileName,
                assetNetworkId: response.networkId
            })
    }

    getCategories = async () => {
        const assetId = this.props.match.params.id;
        const responseCategory = await getCategoryByCultureId(this.token, this.state.assetCultureId);
        const responseAsset = await getAssetById(this.token, assetId, this.network);
        let initialCurrentCategories = [];
        let initialCategories = [];
            responseCategory.category.map((category) => {
                initialCategories.push({
                    value: category.Id,
                    label: category.IsIab ? `${category.Name} ${category.ShortName}` : category.Name
                })
                responseAsset.data.Category.map((assetCat) => {
                    if(category.Id === assetCat){
                        initialCurrentCategories.push({
                            value: category.Id,
                            label: category.IsIab ? `${category.Name} ${category.ShortName}` : category.Name
                        })
                    }
                })
            })

        this.setState({
            categories: initialCategories,
            currentCategories: initialCurrentCategories
        })
    }

    refreshPage() {
        this.props.history.push(this.props.match.params.id);
    }

    setKeywords = () => {
        let initKeywords = [];
            this.state.assetData.Keyword.map((keyword, index) => {
                initKeywords.push({
                    value: index,
                    label: keyword
                })
            })

        if(initKeywords.length > 0){
            this.setState({
                keywords: initKeywords
            })
        }
    }

    onUpdateClick = async e => {
        const assetId = this.props.match.params.id;
        const { assetData, keywordsLabel, categoriesValue } = this.state;
        e && e.preventDefault();
        const response = await updateAsset(this.token,
                                        this.network,
                                        assetId,
                                        assetData.Title,
                                        keywordsLabel,
                                        categoriesValue,                                        
                                        assetData.Description,
                                        assetData.ContentPartnerId,
                                        this.state.active);

        if(response.status === 200){
            this.refreshPage();
        }
        else{
            const alert = this.props.alert;
            alert.show('An error occured while updating the asset');
        }
    }

    handleChangeTitle = e => {        
        let assetTitle = e.target.value;
        let _assetData = this.state.assetData;
        _assetData['Title'] = assetTitle;
        this.setState({
            assetData: _assetData
        });        
    }

    handleChangeDescription = e => {        
        let assetDescription = e.target.value;
        let _assetData = this.state.assetData;
        _assetData['Description'] = assetDescription;
        this.setState({
            assetData: _assetData
        });
    }

    initKeywordsAndCategories = () => {
        let initKeywordsLabel = [];
        let initCategoriesValue = [];
        this.state.keywords.map((keyword) => {
            initKeywordsLabel.push(keyword.label)
        })

        this.state.currentCategories.map((category) => {
            initCategoriesValue.push(category.value)
        })

        this.setState({
            categoriesValue: initCategoriesValue,
            keywordsLabel: initKeywordsLabel
        })
    }

    handleChangeKeywords = keywords => {
        let initKeywordsLabel = [];   
        let splitKeywords = [];
        let splitted_labels = [];
        let storedLabels = [];

        if (keywords != null) {
            keywords.map((keyword) => {
                if (keyword.__isNew__){
                    splitted_labels = keyword.label.split(/[,]/gi)
                    splitted_labels.map((label) => {
                        label = label.replace(/[^\w\s]/gi, '');
                        label = label.replace(/[\s*]/, '')
                        initKeywordsLabel.push(label)
                        splitKeywords.push({
                            label:label,
                            value:label,
                            __isNew__: true
                        })
                    })
                }
                else
                {
                    splitKeywords.push(keyword)
                    initKeywordsLabel.push(keyword.label)
                    storedLabels.push(keyword.label)
                }
            })
            let cleaned_splitKeywords = splitKeywords
            splitKeywords.map((keyword) =>{
                if (keyword.__isNew__){
                    if (storedLabels.includes(keyword.label)){                       
                        let index = cleaned_splitKeywords.indexOf(keyword)
                        cleaned_splitKeywords.splice(index, 1);
                        index = initKeywordsLabel.indexOf(keyword.label)
                        initKeywordsLabel.splice(index,1);
                    }
                }
            })
    
            this.setState({
                keywords: cleaned_splitKeywords,
                keywordsLabel: initKeywordsLabel
            })   
        }
               
    }

    handleChangeCategory = currentCategories => {
        let initCategoriesValue = [];
        currentCategories = currentCategories ?? [];

        currentCategories.map((category) => {
            initCategoriesValue.push(category.value)
        })

        this.setState({
            currentCategories: currentCategories,
            categoriesValue: initCategoriesValue
        })
    }

    onDownloadVideo = async () => {
        const alert = this.props.alert;
        const fileName = this.state.videoFileName;
        const file = this.state.assetData.BucketFolderId + "/" + this.state.assetCultureId + "/" + fileName;

        this.setState({
            downloadStartedVideo: true
        })

        if(this.state.assetData.hasOwnProperty('BucketFolderId') ){
            const response = await downloadFileSolo(this.token, this.network, file);
            fetch(response.presignedUrl, { method: 'GET' })
            .then(res => res.blob())
            .then(blob => {
                saveAs(blob, fileName)

                this.setState({
                    downloadStartedVideo: false
                })
            })
            .then(alert.show('Download is started. Please Wait !'))
        }else {
            fetch(this.state.video, { method: 'GET' })
            .then(res => res.blob())
            .then(blob => {
                saveAs(blob, fileName)

                this.setState({
                    downloadStartedVideo: false
                })
            })
            .then(alert.show('Download is started. Please Wait !'))
        }
    }

    onDownloadImage = async () => {
        const alert = this.props.alert;
        const fileName = this.state.assetData.ImageFileName;
        const file = this.state.assetData.BucketFolderId + "/" + this.state.assetCultureId + "/" + fileName;

        this.setState({
            downloadStartedImage: true
        })

        if(this.state.assetData.hasOwnProperty('BucketFolderId') ){
            const response = await downloadFileSolo(this.token, this.network, file);
            fetch(response.presignedUrl, { method: 'GET' })
            .then(res => res.blob())
            .then(blob => {
                saveAs(blob, fileName)

                this.setState({
                    downloadStartedImage: false
                })
            })
            .then(alert.show('Download is started. Please Wait !'))
        }else {
            let imageFile = this.state.assetImage;
            fetch(imageFile, { method: 'GET' })
            .then(res => res.blob())
            .then(blob => {
                saveAs(blob, fileName)

                this.setState({
                    downloadStartedImage: false
                })
            })
            .then(alert.show('Download is started. Please Wait !'))
        }
    }

    async componentDidMount(){
        await this.getAssetDetail();
        await this.getCategories();
        this.initKeywordsAndCategories();
        this.setKeywords();
        this.initKeywordsAndCategories();
    }

    confirm = () => {
        if(this.state.currentCategories.length > 0) { 
            confirmAlert({
                title: 'Update Asset',
                message: 'Are you sure you want to update ?',
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.onUpdateClick()
                },
                {
                    label: 'No',
                }
                ]
            });
        } else {
            this.props.alert.error('Category field should contain at least one item');
        }
    };

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }

    render() {        
        const { assetData, video, keywords, currentCategories, categories } = this.state;
        return (
            <div>
                <Header/>

                <Container className="mt-10">
                    <Form>
                        <FormGroup>
                            <Label for="asset">Title</Label>
                            <Input type="text" name="assetTitle" id="asset_title" onChange={this.handleChangeTitle} value={assetData.Title || ""}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="asset">Description</Label><br></br>
                            <Input type="textarea" name="assetDesc" id="asset_desc" onChange={this.handleChangeDescription} value={assetData.Description}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="asset">Keywords</Label><br></br>
                            <CreatableSelect
                            value={ keywords }
                            isMulti
                            name="keywords"
                            options={ keywords }
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleChangeKeywords}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="asset">Category</Label><br></br>
                            <Select
                            value={ currentCategories }
                            isMulti
                            name="keywords"
                            options={ categories }
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleChangeCategory}
                            />
                        </FormGroup>
                        <FormGroup check className="mb-3">
                            <Label check>
                            <Input type="checkbox" checked={this.state.active || false} onChange={this.handleChangeActive}/>{' '}
                                <strong>Is Commercial Allowed</strong>
                            </Label>
                        </FormGroup>
                        <Label><strong>Guid :</strong> {this.state.assetData.Guid}</Label>
                        { assetData.TaskId !== undefined ? 
                            <FormGroup>
                                <Label><strong>Task Id: <a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push(`/admin/process-task/${assetData.TaskId}`)}}>{assetData.TaskId}</a></strong></Label>
                            </FormGroup> : ''
                        }
                        <hr></hr>
                        <FormGroup>
                        <Label for="asset">Current Video</Label> 
                        { (this.network === this.state.assetNetworkId || this.network === 1) ? <Button onClick={this.onDownloadVideo} color="primary" className="asset-download" data-clipboard-text="cloud-download-95" type="button" size="sm"><div className="download-button"><i className="ni ni-cloud-download-95"></i><span>Download Video</span> { this.state.downloadStartedVideo ? <img src="https://samherbert.net/svg-loaders/svg-loaders/oval.svg" className="ml-2" width="14" alt="zoomin"></img> : "" } </div></Button> : '' }
                            <ReactPlayer width="100%" height="100%" url={ video } controls />
                        </FormGroup>
                        <FormGroup>
                        <Label for="asset">Current Image</Label>
                        { (this.network === this.state.assetNetworkId || this.network === 1) ? <Button onClick={this.onDownloadImage} color="primary" className="asset-download" data-clipboard-text="cloud-download-95" type="button" size="sm"><div className="download-button"><i className="ni ni-cloud-download-95"></i><span>Download Image</span> { this.state.downloadStartedImage ? <img src="https://samherbert.net/svg-loaders/svg-loaders/oval.svg" className="ml-2" width="14" alt="zoomin"></img> : "" } </div></Button> : '' } <br></br>
                            <img src={this.state.assetImage} alt="" width="100%" height="100%" />
                        </FormGroup>
                        <FormGroup>
                            <Label>Published By</Label>
                            <Input readOnly type="text" name="publishedBy" id="publishedBy" value={`${this.state.assetData.PublishedUserName} - ${this.state.assetData.PublicatedDate !== null ? moment(this.state.assetData.PublicatedDate).format("LLLL") : ' - '}`}/>                            
                        </FormGroup>
                        <FormGroup>
                            <Label>Created By</Label>
                            <Input readOnly type="text" name="createdBy" id="createdBy" value={`${this.state.assetData.CreatedUserName} - ${moment(this.state.assetData.CreatedDate).format("LLLL")}`}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Modified By</Label>
                            <Input readOnly type="text" name="modifiedBy" id="modifiedBy" value={`${this.state.assetData.ModifiedUserName} - ${this.state.assetData.ModifiedDate !== null ? moment(this.state.assetData.ModifiedDate).format("LLLL") : ' - '}`}/>                            
                        </FormGroup>                        
                    </Form>
                    {(this.network === this.state.assetNetworkId || this.network === 1) ? <Button onClick={this.confirm}>Update Asset</Button> : '' }                    
                </Container>
            </div>
        )
    }
}

export default withAlert()(Edit)