import { zoomUserAPI, zoomUserNetworkAPI } from './config';
import { validateResponse } from './validateResponse';

export async function userAddRoleNetwork(token, network, userId, networkId, roleId){
    const response = await fetch(zoomUserNetworkAPI + '/add', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': network,
        },
        body: JSON.stringify({
            userId: userId,
            networkId: networkId,
            roleId: roleId,
        })
    });
    return response;
}
export async function getZoomUser(token, sub, networkId){
    const response = await fetch(`${zoomUserAPI}/${sub}`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
		},
    });
    return await validateResponse(response);
}

export async function getZoomUserAuth(token, sub){
    const response = await fetch(`${zoomUserAPI}/me/${sub}`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
		},
    });
    return await validateResponse(response);
}

export async function updateUserGdprAcceptDate(token, userId) {
    const response = await fetch(zoomUserAPI + '/me/gdpr/' + userId, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
    return response;
}

export async function getZoomUsers(token, networkId){
    const response = await fetch(`${zoomUserAPI}`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId
		},
    });
    return await validateResponse(response);
}

export async function createUser(token, networkId, email, firstname, lastname, active) {
    const response = await fetch(zoomUserAPI + '/create', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body: JSON.stringify({
            email: email,
            firstName: firstname,
            lastName: lastname,
            isActive: active,
        })
    });
    return await validateResponse(response);
}

export async function removeUser(token, networkId, userId) {
    const response = await fetch(`${zoomUserAPI}/${userId}`, {
        method: "DELETE",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId
        }
    });
    return response;
}

export async function removeNetworkRole(token, networkId, id) {
    const response = await fetch(`${zoomUserNetworkAPI}/${id}`, {
        method: "DELETE",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId
        }
    });
    return response;
}

export async function updateUser(token, networkId, userId, firstname, lastname, active) {
    const response = await fetch(zoomUserAPI + '/' + userId, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body: JSON.stringify({
            firstName: firstname,
            lastName: lastname,
            isActive: active,
        })
    });
    return response;
}

export async function getZoomNetworkUsers(token, networkId){
    const response = await fetch(`${zoomUserAPI}/network`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId
		},
    });
    return validateResponse(response);
}