import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'

const keycloakConfig = {
    url: 'https://idp.azerionconnect.com/auth/', 
    realm: 'azerion', 
    clientId: '3e50588e-7261-4895-a0d8-b71a6e48420c'
  }

const keycloak = Keycloak(keycloakConfig);

export default keycloak;