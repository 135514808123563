import {
    zoomAssetAPI
} from './config';
import {
    validateResponse
} from './validateResponse';

const assetAPI = `${zoomAssetAPI}/tasks`;

export async function getTasks(token, networkId, status) {
    const response = await fetch(assetAPI + '/list' + (status !== undefined ? '?status=' + status.value : ''), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        }
    });
    return await validateResponse(response);;
}

export async function assignToMe(token, networkId, taskId) {
    const response = await fetch(assetAPI + '/assigntome/' + taskId, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        }
    });
    return response;
}

export async function myTasks(token, networkId) {
    const response = await fetch(assetAPI + '/mytasks', {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        }
    });
    return await validateResponse(response);;
}

export async function getLocalizerTasks(token, networkId) {
    const response = await fetch(assetAPI + '/localizerlist', {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        }
    });
    return await validateResponse(response);;
}

export async function addToCms(token, networkId, taskId) {
    const response = await fetch(assetAPI + '/newasset/' + taskId, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        }
    });
    return response;
}

export async function assignTask(token, networkId, taskId, assignedUserId, type, price, currency) {
    const response = await fetch(`${assetAPI}/assign/${taskId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body: JSON.stringify({
            assignedUserId: assignedUserId,
            type: type,
            price: price,
            currency: currency
        })
    });
    return response;
}

export async function createTask(token, networkId, title, description, keywords, categories, ideaOriginator, city, country, nativeLanguage) {
    const response = await fetch(assetAPI + '/create', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body: JSON.stringify({
            networkId: networkId,
            title: title,
            description: description,
            keywords: keywords,
            categories: categories,
            ideaOriginator: ideaOriginator,
            city: city,
            country: country,
            nativeLanguage: nativeLanguage,
        })
    });
    return await validateResponse(response);
}

export async function updateTask(token,
    networkId,
    taskId,
    title,
    description,
    keywords,
    categories,
    ideaOriginator,
    city,
    country,
    price) {
    const response = await fetch(`${assetAPI}/save/${taskId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body: JSON.stringify({
            title: title,
            description: description,
            keywords: keywords,
            categories: categories,
            ideaOriginator: ideaOriginator,
            city: city,
            country: country,
            price: price
        })
    });
    return response;
}

export async function removeTask(token, networkId, taskId) {
    const response = await fetch(`${assetAPI}/delete/${taskId}`, {
        method: "DELETE",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId
        }
    });
    return response;
}

export async function getTaskById(token, networkId, taskId) {
    const response = await fetch(`${assetAPI}/get/${taskId}`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
    });
    return await validateResponse(response);
}

export async function readyToApproveTask(token, networkId, taskId, assetFiles) {
    const response = await fetch(`${assetAPI}/readytoapprove/${taskId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body: JSON.stringify({
            assetFilesArray: assetFiles
        })
    });
    return response;
}

export async function readyToApproveEditorTask(token, networkId, taskId, assetFiles) {
    const response = await fetch(`${assetAPI}/readytoapprove/${taskId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body: JSON.stringify({
            assetFiles: assetFiles
        })
    });
    return response;
}

export async function localizerComplete(token, networkId, taskId, assetFiles) {
    const response = await fetch(`${assetAPI}/complete/${taskId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body: JSON.stringify({
            assetFiles: assetFiles
        })
    });
    return response;
}

export async function approveTask(token, networkId, taskId) {
    const response = await fetch(`${assetAPI}/approve/${taskId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
    });
    return response;
}

export async function rejectTask(token, networkId, taskId) {
    const response = await fetch(`${assetAPI}/reject/${taskId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
    });
    return response;
}