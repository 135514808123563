import { zoomCmsAPI } from './config';
import { validateResponse } from './validateResponse';

const cultureAPI = `${zoomCmsAPI}/culture`;

export async function getCultures(token){
    const response = await fetch(cultureAPI, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function getCultureById(token, cultureId){
    const response = await fetch(`${cultureAPI}/${cultureId}`, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function createCulture(token, cultureName, isActive){
    const response = await fetch(`${cultureAPI}/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify({
            name: cultureName,
            isActive: isActive
        })
    });
    return response;
}

export async function updateCulture(token, cultureName, isActive, cultureId){
    const response = await fetch(`${cultureAPI}/${cultureId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify({
            name: cultureName,
            isActive: isActive
        })
    });
    return response;
}

export async function removeCulture(token, cultureId){
    const response = await fetch(`${cultureAPI}/${cultureId}`, {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            Id: cultureId,
        })
    });
    return response;
}