import { zoomMediaAPI } from './config';
import { validateResponse } from './validateResponse';

export async function downloadFile(token, networkId, bucketFolderId, cultureId, key){
    const response = await fetch(zoomMediaAPI + '/download/' + bucketFolderId + '/' + cultureId + '/' + key, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
		},
    });
    return await validateResponse(response);
}

export async function downloadFileSolo(token, networkId, file){
    const response = await fetch(zoomMediaAPI + '/download/' + file, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
    });
    return await validateResponse(response);
}