import { zoomCmsAPI } from './config';
import { validateResponse } from './validateResponse';

const networkAPI = `${zoomCmsAPI}/network`;

export async function getNetworks(token){
    const response = await fetch(networkAPI, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function getNetworkById(token, networkId){
    const response = await fetch(`${networkAPI}/${networkId}`, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function createNetwork(token, name, isActive ){
    const response = await fetch(`${networkAPI}/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify({
            name: name,
            isActive: isActive,
        })
    });
    return response;
}

export async function updateNetwork(token, networkId, name, isActive){
    const response = await fetch(`${networkAPI}/${networkId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify({
            name: name,
            isActive: isActive,
        })
    });
    return response;
}

export async function removeNetwork(token, networkId){
    const response = await fetch(`${networkAPI}/${networkId}`, {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            id: networkId,
        })
    });
    return response;
}