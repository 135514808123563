import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

export default class GeneralModal extends Component {
	render() {
		return (
			<Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className} size={!this.props.small ? 'lg' : 'sm'} centered={!!this.props.centered}>
				<ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
				<ModalBody>
					{this.props.children}
				</ModalBody>
				<ModalFooter>
					{this.props.primaryButton ? <Button color="primary" onClick={this.props.onAction}>{this.props.primaryButton}</Button> : null}
					{' '}
					{this.props.secondaryButton ? <Button color="secondary" onClick={this.props.toggle}>{this.props.secondaryButton}</Button> : null}
				</ModalFooter>
			</Modal>
		);
	}
}
