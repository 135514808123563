// RollbarErrorTracking.js 
import Rollbar from 'rollbar';
export const RollbarErrorTracking = (() => {
    const RollbarObj = new Rollbar({
        accessToken: 'fa14660c218340f6b97c705c26feb5ae',
        captureUncaught: true,
        captureUnhandledRejections: true,
    });
    const logErrorInfo = (info) => {
        RollbarObj.info(info);
    };
    const logErrorInRollbar = (error) => {
        //throw new Error(error);
        RollbarObj.error(error)
    };
    return { logErrorInfo, logErrorInRollbar };
})();
export default RollbarErrorTracking;