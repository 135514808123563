import { zoomCmsAPI } from './config';
import { validateResponse } from './validateResponse';

const feedAPI = `${zoomCmsAPI}/publisher/feed`;

export async function getFeeds(token){
    const response = await fetch(feedAPI, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function getFeedsByPid(token, pid){
    const response = await fetch(feedAPI + '/pid/' + pid, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function getFeedById(token, feedId){
    const response = await fetch(`${feedAPI}/${feedId}`, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function createFeed(token, networkId, nId, publisherId, playlistCode, rowCount, culture, category, keyword, asset){
    const response = await fetch(`${feedAPI}/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
		},
		body: JSON.stringify({
            networkId: nId,
            publisherId: publisherId,
            playlistCode: playlistCode,
            rowCount: rowCount,
            culture: culture,
            category: category,
            keyword: keyword,
            asset: asset
        })
    });
    return await validateResponse(response);
}

export async function updatePublisher(token, networkId, feedId, publisherId, rowCount, culture, category, keyword, asset){
    const response = await fetch(`${feedAPI}/${feedId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body: JSON.stringify({
            networkId: networkId,
            publisherId: publisherId,
            rowCount: rowCount,
            culture: culture,
            category: category,
            keyword: keyword,
            asset: asset
        })
    })
    return response;
}

export async function removePublisher(token, feedId){
    const response = await fetch(`${feedAPI}/${feedId}`, {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            Id: feedId,
        })
    });
    return response;
}