import React, { Component } from 'react'
import Header from '../../components/Headers/Header';
import { getFeeds, getFeedById, updatePublisher } from '../../services/feedService';
import { getPublishers, getPublisherById } from 'services/publisherService';
import { getCultures } from 'services/cultureService';
import { getCategories } from 'services/categoryService';
import { getPublishedAssets, getAssetById } from 'services/assetService';
import CreatableSelect from 'react-select/creatable';
import { Button, Container, Form, FormGroup, Label, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, ListGroup } from 'reactstrap';
import Select from 'react-select';
import { withAlert } from 'react-alert';
import ReactTable from 'react-table';
import "react-table/react-table.css";

class Index extends Component {
    constructor(){
        super();
        this.state = {
            feedData: [],
            selectedFeed: null,
            selectedFeedData: [],
            networkData: [],
            publisherOptions: [],
            cultureOptions: [],
            categoryOptions: [],
            assetData: [],
            feedAssetData: [],
            feedAssetDataId: [],
            selectedRowIndex: [],
            selectedPublisher: null,
            selectedUserNetwork: null,
            selectedCulture: null,
            selectedCategory: null,
            selectedKeywords: null,
            limit: 200,
            offset: 0,
            activeTab: '1',
        }

        this.token = window.zoomCrypt.get("token");
        this.networkId = window.zoomCrypt.get("networkId");
        this.networkName = window.zoomCrypt.get("networkName");
    }

    getAllFeeds = async () => {
        const response = await getFeeds(this.token, this.networkId);
            let initialFeeds = [];
            if(response.status === 200){
                response.publisherFeedSettings.map((feed) => {
                    initialFeeds.push({
                        value: feed.Id,
                        label: feed.Id,
                    })
                })
            }

            this.setState({
                feedData: initialFeeds
            })
    }

    getCurrentFeedById = async (id) => {
        this.setState({
            feedAssetData: []
        })

        const response = await getFeedById(this.token, id);
        let initialKeywords = [];
        response.publisherFeedSettings.SettingsData.Keyword.map((keyword) => {
            initialKeywords.push({
                value: keyword,
                label: keyword
            })
        })

        let initialPublisher = [];
        const publisherResponse = await getPublisherById(this.token, response.publisherFeedSettings.PublisherId);
        initialPublisher.push({
            value: publisherResponse.publisher.Id,
            label: publisherResponse.publisher.Name,
        })

        let initialCulture = [];
        this.state.cultureOptions.map((culture) => {
            if(culture?.value === response.publisherFeedSettings.SettingsData?.Culture){
                initialCulture.push({
                    value: culture.value,
                    label: culture.label,
                })
            }
        })

        let initialCategory = [];
        this.state.categoryOptions.map((category) => {
            if(response.publisherFeedSettings.SettingsData.Category !== undefined){
                response.publisherFeedSettings.SettingsData.Category.map((feedCategory) => {
                    if(category.value === feedCategory){
                        initialCategory.push({
                            value: category.value,
                            label: category.label,
                        })
                    }
                })
            }
        })

        if(response.publisherFeedSettings.SettingsData.hasOwnProperty('Asset')){
            response.publisherFeedSettings.SettingsData.Asset.map(async (feedAsset) => {
                const response = await getAssetById(this.token, feedAsset, this.networkId);
                const feedAssetData = await response;
                this.setState({
                    feedAssetData: [...this.state.feedAssetData, feedAssetData],
                    feedAssetDataId: [...this.state.feedAssetDataId, feedAssetData.id],
                })
            })
        }

        this.setState({
            selectedKeywords: initialKeywords,
            selectedPublisher: initialPublisher,
            selectedCulture: initialCulture,
            selectedCategory: initialCategory,
        })
    }

    handleChangeFeed = selectedFeed => {
        this.setState({ selectedFeed });
        this.getCurrentFeedById(selectedFeed.value)
    }

    getPublishedAssets = async () => {
        const response = await getPublishedAssets(this.token, parseInt(this.networkId), this.state.limit, this.state.offset);

        let initialAssets = [];
        initialAssets = response.assets.map((asset) => {
            return asset;
        })

        this.setState({
            assetData: initialAssets,
        })
    }

    getPublishers = async (selectedNetworkId) => {
        const response = await getPublishers(this.token);
        if(response.success){
            let initialPublishersOptions = [];
            response.publishers.map((publisher) => {
                if(publisher.NetworkId === selectedNetworkId){
                    initialPublishersOptions.push({
                        value: publisher.Id,
                        label: publisher.Name,
                    })
                }
            })

            this.setState({
                publisherOptions: initialPublishersOptions
            })
        }
    }

    getCultures = async () => {
        const response = await getCultures(this.token);
        let initialCultures = [];
        if(response.success){
            response.culture.map((culture) => {
                initialCultures.push({
                    value: culture.Id,
                    label: culture.Name,
                })
            })

            this.setState({
                cultureOptions: initialCultures
            })
        }
    }

    getCategories = async () => {
        const response = await getCategories(this.token);
        let initialCultures = [];
        if(response.success){
            response.category.map((category) => {
                initialCultures.push({
                    value: category.Id,
                    label: category.IsIab ? `${category.Name} ${category.ShortName}` : category.Name,
                })
            })

            this.setState({
                categoryOptions: initialCultures
            })
        }
    }

    handleChangePublisher = selectedPublisher => {
        this.setState({ selectedPublisher });
    }

    handleChangeCulture = selectedCulture => {
        this.setState({ selectedCulture});
    }

    handleChangeCategory = selectedCategory => {
        this.setState({ selectedCategory });
    }

    handleChangeKeywords = selectedKeywords => {
        this.setState({ selectedKeywords });
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    addToFeed = async (id) => {
        const response = await getFeedById(this.token, this.state.selectedFeed.value);

        if(response.publisherFeedSettings.SettingsData.hasOwnProperty('Asset')){
            const response = await getAssetById(this.token, id, this.networkId);
            const feedAssetData = await response;
            this.setState({
                feedAssetData: [...this.state.feedAssetData, feedAssetData],
                feedAssetDataId: [...this.state.feedAssetDataId, feedAssetData.id],
            })
        }

        this.setState({
            activeTab: '1'
        })
    }

    updateFeed = async() => {
        const alert = this.props.alert;
        if(this.state.feedAssetData !== null){
            const response = await updatePublisher(this.token,
                                              this.networkId,
                                              this.state.selectedFeed.value,
                                              this.state.selectedPublisher[0].value,
                                              this.state.feedAssetData.length,
                                              this.state.selectedCulture?.value,
                                              this.state.selectedCategory?.value,
                                              this.state.selectedKeywords,
                                              this.state.feedAssetDataId);
                if(response.success){
                    alert.show("Assets Set")
                }else {
                    alert.show("Assets Not Set")
                }
        }else {
            alert.show("Please Select Asset !")
        }
    }

    removeByAttr = (arr, attr, value) => {
        var i = arr.length;
        while(i--){
           if( arr[i]
               && arr[i].hasOwnProperty(attr)
               && arr[i][attr] === value){
               arr.splice(i,1);
           }
        }
        return arr;
    }

    removeFromFeed = (id) => {
        let feedArr = [...this.state.feedAssetData];
        this.removeByAttr(feedArr, 'id', id);
        this.setState({
            feedAssetData: feedArr
        })
    }

    toggle = tab => {
        this.setState({
            activeTab: this.state.activeTab === '1' ? '2' : '1'
        })
      }

    getTrProps = (state, rowInfo) => {
        if (rowInfo) {
            return {
                style: {
                  background: this.state.selectedRowIndex.includes(rowInfo.row._index) ? '#feca57' : 'white'
                }
              }
          }
        return {};
      }

    async componentDidMount() {
        await this.getAllFeeds();
        this.getCultures();
        this.getCategories();
        this.getPublishedAssets();
    }

    render() {
        const { publisherOptions,
            selectedPublisher,
            cultureOptions,
            selectedCulture,
            categoryOptions,
            selectedCategory,
            selectedKeywords } = this.state;

            const columnsCurrent = [
                {
                    Header: "Image",
                    accessor: "image",
                    Cell: props => {
                        return(
                            <img src={`${props.original.data?.ImageRemotePath}/${props.original.data?.ImageFileName}`} alt={props.original.data?.Title} width="100%" />
                        )
                    },
                    filterable: false,
                    sortable: false
                },
                {
                    Header: "Title",
                    accessor: "data.Title",
                    style: { 'whiteSpace': 'unset' }
                },
                {
                    Header: "Remove",
                    Cell: props => {
                        return(
                            <Button onClick={() => this.removeFromFeed(props.original.id)} color="danger">Remove</Button>
                        )
                    },
                    accessor: "edit",
                    filterable: false,
                    sortable: false,
                    width: 120
                }
            ]

            const columnsOther = [
                {
                    Header: "Image",
                    accessor: "image",
                    Cell: props => {
                        return(
                            <img src={`${props.original.data?.ImageRemotePath}/${props.original.data?.ImageFileName}`} alt={props.original.data?.Title} width="100%" />
                        )
                    },
                    filterable: false,
                    sortable: false
                },
                {
                    Header: "Title",
                    accessor: "data.Title",
                    style: { 'whiteSpace': 'unset' }
                },
                {
                    Header: "Add",
                    Cell: props => {
                        return(
                            <Button onClick={() => this.addToFeed(props.original.id)} color="warning">Add to Feed</Button>
                        )
                    },
                    accessor: "edit",
                    filterable: false,
                    sortable: false,
                    width: 140
                }
            ]

            let tabClass = "active:" + this.state.activeTab === '1';
        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                <h1>Select Feed</h1>
                <hr></hr>
                    <Select
                        value={this.state.selectedFeed}
                        options={this.state.feedData}
                        onChange={this.handleChangeFeed}
                    ></Select>
                <hr></hr>
                <h1>Selected Feed</h1>
                <hr></hr>
                <Form>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label for="publishers">Select Publisher</Label>
                                <Select
                                    value={selectedPublisher}
                                    options={publisherOptions}
                                    onChange={this.handleChangePublisher}
                                ></Select>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="6">
                            <FormGroup>
                                <Label for="cultures">Select Culture</Label>
                                <Select
                                    value={selectedCulture}
                                    options={cultureOptions}
                                    onChange={this.handleChangeCulture}
                                ></Select>
                            </FormGroup>
                        </Col>

                        <Col md="6">
                        <FormGroup>
                            <Label for="categories">Select Category</Label>
                            <Select
                                isMulti
                                value={selectedCategory}
                                options={categoryOptions}
                                onChange={this.handleChangeCategory}
                            ></Select>
                        </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label for="categories">Select Keywords</Label>
                        <CreatableSelect
                            isMulti
                            placeholder="Write and Create"
                            value={selectedKeywords}
                            onChange={this.handleChangeKeywords}
                            components={
                                {
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null
                                }
                            }
                        ></CreatableSelect>
                    </FormGroup>

                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={tabClass.toString()}
                                onClick={() => { this.toggle('1'); }}
                            >
                                Current Feed Assets
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={tabClass.toString()}
                                onClick={() => { this.toggle('2'); }}
                            >
                                Other Assets
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <ListGroup>
                                <ReactTable
                                    className="zoom-table mb-5"
                                    columns={columnsCurrent}
                                    filterable
                                    defaultFilterMethod={this.filterCaseInsensitive}
                                    showPaginationTop={true}
                                    data={this.state.feedAssetData}
                                    getTrProps={this.getTrProps}>
                                </ReactTable>
                            </ListGroup>
                        </TabPane>
                        <TabPane tabId="2">
                            <ListGroup>
                                <ReactTable
                                    className="zoom-table mb-5"
                                    columns={columnsOther}
                                    filterable
                                    defaultFilterMethod={this.filterCaseInsensitive}
                                    showPaginationTop={true}
                                    data={this.state.assetData}
                                    getTrProps={this.getTrProps}>
                                </ReactTable>
                            </ListGroup>
                        </TabPane>
                    </TabContent>

                    <Button onClick={this.updateFeed}>Update Feed</Button>
                </Form>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Index)