import React, { Component } from "react";
import { Button, Container, Form, FormGroup, Label } from "reactstrap";
import ReactTable from "react-table";
import Select from "react-select";

import Header from "components/Headers/Header";
import GeneralModal from "components/Modals/GeneralModal";
import { getPublishers } from "services/publisherService";
import { getCultures } from "services/cultureService";
import {
  createPublisherLanguage,
  getPublisherLanguages,
} from "services/publisherLanguage";
import { withAlert } from "react-alert";
import { confirmAlert } from "react-confirm-alert";
import { removePublisherLanguage } from "services/publisherLanguage";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState();
    this.token = window.zoomCrypt.get("token");
    this.networkId = window.zoomCrypt.get("networkId");
  }

  componentDidMount() {
    this.loadDefaultData();
  }

  defaultState = () => {
    return {
      publisherLanguages: [],
      publishers: [],
      selectedPublisher: null,
      languages: [],
      selectedLanguages: [],
      openModal: false,
    };
  };

  toggle = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  handlePublisherSelection = (selectedPublisher) => {
    this.setState({ selectedPublisher });
  };

  handleLanguageSelection = (selectedLanguages) => {
    this.setState({ selectedLanguages });
  };

  getPublisherLanguages = async () => {
    const response = await getPublisherLanguages(this.token, this.networkId);
    let languages = [];

    if (response?.success) {
      languages = response.publisherLanguages.map((language) => {
        return {
          id: language.Id,
          languageId: language.LanguageId,
          publisherId: language.PublisherId,
          languageName: language.LanguageName,
          publisherName: language.PublisherName,
        };
      });
    }

    this.setState({
      publisherLanguages: languages,
    });
  };

  getPublishers = async () => {
    const response = await getPublishers(this.token);

    if (response?.success) {
      this.setState({
        publishers: response.publishers
          .map((publisher) => {
            return {
              value: publisher.Id,
              label: publisher.Name.concat(" - ", publisher.Pid),
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      });
    }
  };

  getLanguages = async () => {
    const response = await getCultures(this.token);

    if (response?.success) {
      this.setState({
        languages: response?.culture
          ?.filter((language) => language.IsActive === true)
          .map((language) => {
            return {
              label: language?.Name,
              value: language?.Id,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      });
    }
  };

  refreshPage = () => {
    this.setState(this.defaultState(), () => this.loadDefaultData());
  };

  loadDefaultData = () => {
    this.getPublishers();
    this.getLanguages();
    this.getPublisherLanguages();
  };

  filterData(filter, row) {
    const { pivotId, id, value } = filter;
    const index = pivotId || id;

    return row[index] !== undefined
      ? String(row[index].toLowerCase()).startsWith(value.toLowerCase())
      : true;
  }

  onCreate = async (e) => {
    e.preventDefault();

    const alert = this.props.alert;
    const publisherId = this.state?.selectedPublisher?.value;
    const languages = Object.values(
      this.state?.selectedLanguages?.map((language) => language.value)
    );

    if (publisherId && languages.length) {
      const response = await createPublisherLanguage(
        this.token,
        this.networkId,
        publisherId,
        languages
      );

      if (response.status === 200) this.refreshPage();
      else
        alert.show("An error occured while trying to create. Please try again");
    } else
      alert.show(
        "Please select valid values while creating publisher languages"
      );
  };

  confirm = (publisherLanguageId) => {
    confirmAlert({
      title: "Remove Publisher Language",
      message: "Are you sure you want to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.remove(publisherLanguageId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  remove = async (publisherLanguageId) => {
    const alert = this.props.alert;
    const response = await removePublisherLanguage(
      this.token,
      this.networkId,
      publisherLanguageId
    );

    if (response?.status === 204) this.refreshPage();
    else
      alert.show("An error occured while trying to delete. Please try again");
  };

  render() {
    const columns = [
      {
        Header: "Publisher",
        accessor: "publisherName",
      },
      {
        Header: "Language",
        accessor: "languageName",
      },
      {
        Header: "Remove",
        accessor: "remove",
        filterable: false,
        sortable: false,
        Cell: (props) => {
          return (
            <Button
              onClick={() => this.confirm(props.original.id)}
              color="danger"
            >
              Remove
            </Button>
          );
        },
      },
    ];

    return (
      <div>
        <Header></Header>
        <Container className="mt-10">
          <ReactTable
            className="zoom-table"
            columns={columns}
            filterable
            showPaginationTop={true}
            defaultFilterMethod={this.filterData}
            data={this.state.publisherLanguages}
          ></ReactTable>
          <GeneralModal
            isOpen={this.state.openModal}
            toggle={this.toggle}
            title="Create Publisher Language"
            primaryButton="Create"
            secondaryButton="Cancel"
            className="modal-content bg-light"
            onAction={this.onCreate}
          >
            <Form>
              <FormGroup>
                <Label for="publisher">Publishers</Label>
                <Select
                  options={this.state.publishers}
                  value={this.state.selectedPublisher}
                  onChange={this.handlePublisherSelection}
                ></Select>
              </FormGroup>
              <FormGroup>
                <Label for="publisher">Languages</Label>
                <Select
                  closeMenuOnSelect={false}
                  options={this.state.languages}
                  value={this.state.selectedLanguages}
                  onChange={this.handleLanguageSelection}
                  isMulti
                ></Select>
              </FormGroup>
            </Form>
          </GeneralModal>
          <Button className="create-button" color="success" onClick={this.toggle}>
            Create Publisher Language
          </Button>
        </Container>
      </div>
    );
  }
}

export default withAlert()(Index);
