import { zoomAssetAPI } from './config';
import { validateResponse } from './validateResponse';

const assetAPI = `${zoomAssetAPI}/assets`;

export async function getProductionAssets(token, networkId, limit, offset, culture, title, description, category, keyword, minPublicatedDate, maxPublicatedDate, assetId){
    let bodyObj = {
            status: "C",
            networkId: networkId,
            limit: limit,
            offset: offset,
            cultureId: culture,
            category: category,
            keyword: keyword,
            minPublicatedDate: minPublicatedDate,
            maxPublicatedDate: maxPublicatedDate,
            assetId: assetId
        }

    title !== "" ? bodyObj.title = title : delete bodyObj.title
    description !== "" ? bodyObj.description = description : delete bodyObj.description

    const response = await fetch(assetAPI + '/list', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body:  JSON.stringify( bodyObj )
    });
    return await validateResponse(response);
}

export async function getPublishedAssets(token, networkId, limit, offset, culture, title, description, category, keyword, minPublicatedDate, maxPublicatedDate, userId, assetId){
    let bodyObj = {
        status: "P",
        networkId: networkId,
        limit: limit,
        offset: offset,
        cultureId: culture,
        category: category,
        keyword: keyword,
        minPublicatedDate: minPublicatedDate,
        maxPublicatedDate: maxPublicatedDate,
        assetUserId: userId,
        assetId: assetId
    }

    title !== "" ? bodyObj.title = title : delete bodyObj.title
    description !== "" ? bodyObj.description = description : delete bodyObj.description

    const response = await fetch(assetAPI + '/list', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body:  JSON.stringify( bodyObj )
    });
    return await validateResponse(response);
}

export async function getScheduledAssets(token, networkId, limit, offset, culture, title, description, category, keyword, minPublicatedDate, maxPublicatedDate, assetId){
    let bodyObj = {
        status: "S",
        networkId: networkId,
        limit: limit,
        offset: offset,
        cultureId: culture,
        category: category,
        keyword: keyword,
        minPublicatedDate: minPublicatedDate,
        maxPublicatedDate: maxPublicatedDate,
        assetId: assetId        
    }

    title !== "" ? bodyObj.title = title : delete bodyObj.title
    description !== "" ? bodyObj.description = description : delete bodyObj.description

    const response = await fetch(assetAPI + '/list', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body:  JSON.stringify( bodyObj )
    });
    return await validateResponse(response);
}

export async function getWithdrawnAssets(token, networkId, limit, offset, culture, title, description, category, keyword, minPublicatedDate, maxPublicatedDate, assetId){
    let bodyObj = {
        status: "W",
        networkId: networkId,
        limit: limit,
        offset: offset,
        cultureId: culture,
        category: category,
        keyword: keyword,
        minPublicatedDate: minPublicatedDate,
        maxPublicatedDate: maxPublicatedDate,
        assetId: assetId
    }

    title !== "" ? bodyObj.title = title : delete bodyObj.title
    description !== "" ? bodyObj.description = description : delete bodyObj.description

    const response = await fetch(assetAPI + '/list', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body:  JSON.stringify( bodyObj )
    });
    return await validateResponse(response);
}

export async function createAsset(token, cultureId, networkId, title){
    const response = await fetch(assetAPI + '/create', {
        method: "POST",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId
        },
        body:  JSON.stringify({            
            Title: title,
            CultureId: cultureId,
        })
    });
    return response;
}

export async function updateAsset(token,
                                  networkId,
                                  assetId,
                                  title,
                                  keyword,
                                  category,
                                  description,
                                  contentPartnerId,
                                  isCommercialAllowed,
                                  imageFileName,
                                  videoFileName){
    const response = await fetch(`${assetAPI}/save/${assetId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body:  JSON.stringify({
            Title: title,
            Keyword: keyword,
            Category: category,
            Description: description,
            ContentPartnerId: contentPartnerId,
            IsCommercialAllowed: isCommercialAllowed,
            ImageFileName: imageFileName,
            VideoFileName: videoFileName
        })
    });
    return response;
}

export async function setPublish(token, networkId, assetId){
    const response = await fetch(`${assetAPI}/publish/${assetId}`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
		},
    });
    return response;
}

export async function setWithdraw(token, networkId, assetId){
    const response = await fetch(`${assetAPI}/withdraw/${assetId}`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
		},
    });
    return response;
}

export async function setSchedule(token, networkId, assetId, date){
    const response = await fetch(`${assetAPI}/schedule/${assetId}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body:  JSON.stringify({
            PublicatedDate: date
        })
    });
    return response;
}

export async function removeAsset(token, networkId, assetId){
    const response = await fetch(`${assetAPI}/delete/${assetId}`, {
        method: "DELETE",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        }
    });
    return response;
}

export async function getAssetById(token, assetId, networkId){
    const response = await fetch(`${assetAPI}/get/${assetId}`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
		},
    });
    return await validateResponse(response);
}