import { zoomCmsAPI } from './config';
import { validateResponse } from './validateResponse';

const categoryAPI = `${zoomCmsAPI}/category`;

export async function getIabCategories(token) {
  const response = await fetch(`${categoryAPI}/iab-category`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await validateResponse(response);
}

export async function getCategories(token){
    const response = await fetch(categoryAPI, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function getCategoryById(token, categoryId){
    const response = await fetch(`${categoryAPI}/${categoryId}`, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function createCategory(token, name, shortname, isActive, cultureId){
    const response = await fetch(`${categoryAPI}/create`, {
        method: "POST",
        headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify({
            name: name,
            shortName: shortname,
            isActive: isActive,
            cultureId: cultureId
        })
    });
    return response;
}

export async function getCategoryByCultureId(token, cultureId){
    const response = await fetch(`${categoryAPI}/culture/${cultureId}`, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function removeCategory(token, categoryId){
    const response = await fetch(`${categoryAPI}/${categoryId}`, {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            Id: categoryId,
        })
    });
    return response;
}

export async function updateCategory(token, categoryId, name, shortname, isActive, cultureId){
    const response = await fetch(`${categoryAPI}/${categoryId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify({
            name: name,
            shortName: shortname,
            isActive: isActive,
            cultureId: cultureId
        })
    });
    return response;
}