import React from "react";
import ReactDOM from "react-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'
import { AppRouter } from './AppRouter'

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/zoomin-cms.css";


import SecureLS from 'secure-ls';

window.zoomCrypt = new SecureLS({encodingType: 'aes'});

const eventLogger = (event, error) => { 
}

const tokenLogger = (tokens) => {  
  
  if(tokens.token)
    window.zoomCrypt.set("token", tokens.token);  
}

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 9999
  }
}

ReactDOM.render(  
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        checkLoginIframe: false,
      }}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <AppRouter />
    </ReactKeycloakProvider>,
  document.getElementById("root")
);
