import { zoomCmsAPI } from './config';
import { validateResponse } from './validateResponse';

const publisherAPI = `${zoomCmsAPI}/publisher`;

export async function getPublishers(token){
    const response = await fetch(publisherAPI, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function getPublisherById(token, publisherId){
    const response = await fetch(`${publisherAPI}/${publisherId}`, {
        method: "GET",
        headers: {
			'Authorization': `Bearer ${token}`
		},
    });
    return await validateResponse(response);
}

export async function createPublisher(token, networkId, pid, name, isActive){
    const response = await fetch(`${publisherAPI}/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify({
            networkId: networkId,
            pid: pid,
            name: name,
            isActive: isActive
        })
    });
    return response;
}

export async function updatePublisher(token, networkId, publisherId, pid, name, isActive, network){
    const response = await fetch(`${publisherAPI}/${publisherId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body: JSON.stringify({
            PublisherId: pid,
            Name: name,
            isActive: isActive,
            NetworkId: network,
        })
    })
    return response;
}

export async function removePublisher(token, networkId, publisherId){
    const response = await fetch(`${publisherAPI}/${publisherId}`, {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId
        },
        body: JSON.stringify({
            Id: publisherId,
        })
    });
    return response;
}
