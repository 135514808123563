import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Button } from "reactstrap";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar";
import AdminFooter from "../components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { AuthContext } from "AuthContext";
import routes from "routes.js";
import { getZoomUserAuth } from '../services/zoomUserService';
import { withKeycloak } from '@react-keycloak/web';

class Admin extends React.Component {

  constructor(){
    super();

    this.state = {
      hasNetworkRole: false,
      roleName: "",
      networkName: "",
    }

    
  }

  logout = () => {    
    this.props.keycloak.logout({ redirectUri: `${process.env.REACT_APP_URL}` });
    window.zoomCrypt.removeAll();
  }

  setNetworkRole = async () => {
    const response = await getZoomUserAuth(window.zoomCrypt.get("token"), window.zoomCrypt.get("sub"));

    window.zoomCrypt.set("user", response?.user?.FirstName + ' ' + response?.user?.LastName);

    if(window.zoomCrypt.get("networkId") === ""){
      window.zoomCrypt.set("networkId", response?.user?.Networks[0]?.NetworkId);
    }

    if(window.zoomCrypt.get("roleName") === ""){
      window.zoomCrypt.set("roleName", response?.user?.Networks[0]?.RoleName);
    }

    if(window.zoomCrypt.get("networkName") === ""){
      window.zoomCrypt.set("networkName", response?.user?.Networks[0]?.NetworkName)
    }

    if(window.zoomCrypt.get("networkLogo") === ""){
      window.zoomCrypt.set("networkLogo", response?.user?.Networks[0]?.Logo)
    }

    this.setState({
      roleName: window.zoomCrypt.get("roleName"),
      networkName: window.zoomCrypt.get("networkName"),
    })
  }

  async componentDidMount(){
    setTimeout(() => {
      if(window.zoomCrypt.get("networkName") === "" || window.zoomCrypt.get("roleName") === ""){
      this.setState({
        hasNetworkRole: true
      })
    }
   }, 800);
    await this.setNetworkRole();
  }

  componentDidUpdate(e) {
    if(this.props.keycloak?.authenticated !== true) {
      this.logout();
    }
    
    if(!this.state.hasNetworkRole){
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    return (
      <AuthContext.Provider value={this.state}>
        <div>
          { this.state.hasNetworkRole === true ?
          <div className="network-message">
            <img width="15%" src="https://cms.zoomin.tv/images/zoomin-logo.png" alt="zoomin"></img>
            <br/>
            <h1>You don't have any network or role in your account. Please contact with Zoomin.</h1>
            <Button className="mt-3" onClick={this.logout}>Logout</Button>
          </div>
          : <>
            <Sidebar
              {...this.props}
              routes={routes}
              logo={{
                innerLink: "/admin/index",
                imgSrc: require("assets/img/brand/zoomin-logo.png"),
                imgAlt: "zoomin"
              }}
            />
            <div className="main-content" ref="mainContent">
              <AdminNavbar
                {...this.props} keycloak={this.props.keycloak}
                brandText={this.getBrandText(this.props.location.pathname)}
              />
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/admin/index" />
              </Switch>
              <Container fluid>
                <AdminFooter />
              </Container>
            </div>
          </>
            }
        </div>
      </AuthContext.Provider>
    );
  }
}

export default withKeycloak(Admin);
