import { zoomCmsAPI } from './config';
import { validateResponse } from './validateResponse';

const publisherSiteAPI = `${zoomCmsAPI}/publisher/site`;

export async function getPublisherSites(token) {
    const response = await fetch(publisherSiteAPI, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`
        },
    });
    return await validateResponse(response);
}

export async function getPublisherSiteById(token, publisherSiteId) {
    const response = await fetch(`${publisherSiteAPI}/${publisherSiteId}`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`
        },
    });
    return await validateResponse(response);
}

export async function createPublisherSite(token, publisherId, menulist, siteurl, isActive) {
    const response = await fetch(`${publisherSiteAPI}/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            publisherid: publisherId,
            menulist: menulist,
            siteurl: siteurl,
            isactive: isActive
        })
    });
    return await validateResponse(response);
}

export async function updatePublisherSite(token, publisherSiteId, menulist, siteurl, isActive) {
    const response = await fetch(`${publisherSiteAPI}/${publisherSiteId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            menulist: menulist,
            siteurl: siteurl,
            isactive: isActive
        })
    })
    return await validateResponse(response);
}

export async function deletePublisherSite(token, publisherSiteId) {
    const response = await fetch(`${publisherSiteAPI}/${publisherSiteId}`, {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    return response;
}
