import React, { Component } from 'react'
import { Container, Form, FormGroup, Label, Input, Button, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import Header from '../../components/Headers/Header';
import { getZoomUser, removeNetworkRole, userAddRoleNetwork, updateUser } from 'services/zoomUserService';
import { getNetworks } from 'services/networkService';
import { getRoles } from 'services/roleService';
import Select from 'react-select';
import { withAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Edit extends Component {

    constructor(props){
        super(props);
        this.state = {
            userId: null,
            userMail: "",
            userFirstname: "",
            userLastname: "",
            userPassword: "",
            userNetworks: [],
            userRoles: [],
            networkData: [],
            rolesData: [],
            selectedNetwork: null,
            selectedRole: null,
            active: null,
            networksRoleList: []
        }
        this.token = window.zoomCrypt.get("token");
        this.networkId = window.zoomCrypt.get("networkId")
        this.userId = this.props.match.params.id;
    }

    getNetworks = async () => {
        const response = await getNetworks(this.token);
        let initialNetworks = [];
        if(response.success){
            response.networks.forEach((network) => {
                initialNetworks.push({
                    value: network.Id,
                    label: network.Name,
                })
            })

            this.setState({
                networkData: initialNetworks
            })
        }
    }

    confirmRemove = (id) => {
        confirmAlert({
            title: 'Remove Network & Role',
            message: 'Are you sure you want to remove ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.handleRemove(id)
            },
            {
                label: 'No',
            }
            ]
        });
    };

    getRoles = async () => {
        const response = await getRoles(this.token);
        let initialRoles = [];
            response.roles.forEach((role) => {
                initialRoles.push({
                    value: role.Id,
                    label: role.Name,
                })
            })

            this.setState({
                rolesData: initialRoles
            })
    }


    getZoomUser = async () => {
        const response = await getZoomUser(this.token, this.userId, this.networkId);

        if(response.success){
            this.setState({
                userMail: response.user.Email,
                userFirstname: response.user.FirstName,
                userLastname: response.user.LastName,
                userPassword: response.user.Password,
                userId: response.user.Id,
                active: response.user.IsActive,
            })

            let userNetworks = [];
            let userRoles = [];
            let networksRoleList = [];

            response.user.Networks.forEach((network) => {
                userNetworks.push({
                    id: network.Id,
                    value: network.NetworkId,
                    label: network.NetworkName
                })

                userRoles.push({
                    id: network.Id,
                    value: network.RoleId,
                    label: network.RoleName
                })

                networksRoleList.push({
                    id: network.Id,
                    name: network.NetworkName + " - " + network.RoleName
                })
            })

            this.setState({
                userNetworks,
                userRoles,
                networksRoleList
            })
        }
    }

    handleChangeNetworkNew = selectedNetwork => {
        this.setState({
            selectedNetwork,
        })
    }

    handleChangeRoleNew = selectedRole => {
        this.setState({
            selectedRole,
        })
    }

    handleRemove = async id => {
        const alert = this.props.alert;
        if(window.zoomCrypt.get("role") === "Admin"){
            const response = await removeNetworkRole(this.token, this.networkId, id);

            if(response.status === 204){
                alert.show("Network & Role Deleted !")
                this.getZoomUser();
            }
        }else if(window.zoomCrypt.get("role") !== "Admin"){
            if(this.state.networksRoleList.length > 1){
                const response = await removeNetworkRole(this.token, this.networkId, id);

                if(response.status === 204){
                    alert.show("Network & Role Deleted !")
                    this.getZoomUser();
                }
            }else {
                alert.show("If you want to delete the last network & role, add a new one.")
            }
        }
    }

    handleChangeNetwork = async userNetworks => {
        const alert = this.props.alert;

        if(this.state.userNetworks.length > 1){
            let difference = this.state.userNetworks.filter(x => !userNetworks.includes(x));
            const response = await removeNetworkRole(this.token, this.networkId, difference[0].id);

            if(response.status === 204){
                alert.show("Network & Role Deleted !")
                this.getZoomUser()
            }

            let initNetworksLabel = [];
            if(userNetworks !== null){
                userNetworks.forEach((network) => {
                    initNetworksLabel.push(network)
                })
            }

            this.setState({
                userNetworks,
            })
        }else {
            alert.show("Please add a new authorization and delete the authorization you do not want")
        }
    }

    handleChangeRole = async userRoles => {
        const alert = this.props.alert;
        if(this.state.userRoles.length > 1){
            let difference = this.state.userRoles.filter(x => !userRoles.includes(x));
            const response = await removeNetworkRole(this.token, this.networkId, difference[0].id);

            if(response.status === 204){
                alert.show("Network & Role Deleted !")
                this.getZoomUser()
            }

            let initRolesLabel = [];
            if(userRoles !== null){
                userRoles.forEach((role) => {
                    initRolesLabel.push(role)
                })
            }

            this.setState({
                userRoles,
            })
        }else {
            alert.show("Please add a new authorization and delete the authorization you do not want")
        }
    }

    handleChangeMail = e => {
        let userMail = e.target.value;
        this.setState({ userMail });
    }

    handleChangeFirstname = e => {
        let userFirstname = e.target.value;
        this.setState({ userFirstname });
    }

    handleChangeLastname = e => {
        let userLastname = e.target.value;
        this.setState({ userLastname });
    }

    handleChangePassword = e => {
        let userPassword = e.target.value;
        this.setState({ userPassword });
    }

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }
    

    onUpdateClick = async() => {
        const alert = this.props.alert;
        const response = await updateUser(this.token,
                                            this.networkId,
                                            this.state.userId,
                                            this.state.userFirstname,
                                            this.state.userLastname,
                                            this.state.active);
        if(response.status === 204) {
            alert.show("User Updated !")
        }else {
            alert.show("User Not Updated !")
        }
    }

    userRoleNetwork = async() => {
        const alert = this.props.alert;
        if(this.state.selectedNetwork !== null && this.state.selectedRole !== null){
            const response = userAddRoleNetwork(this.token,
                this.networkId,
                this.state.userId,
                this.state.selectedNetwork.value,
                this.state.selectedRole.value);

                response.then(r => {
                    if(r.status === 200 || r.status === 500){
                        alert.show("User already has the network")
                    }else{
                        alert.show("Network & Role Added !")
                        this.getZoomUser();
                    }
                })
            }else {
                alert.show("Please Select Network & Role")
            }
    }

    async componentDidMount(){
        await this.getNetworks();
        await this.getRoles();
        await this.getZoomUser();
    }

    render() {
        return (
            <div>
                <Header></Header>
                <Container className="mt-10">
                    <Form>
                            <FormGroup>
                                <Label for="category">Email</Label>
                                <Input type="text" name="userMail" defaultValue={this.state.userMail} id="user_mail" placeholder="User Mail"/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="category">Firstname</Label>
                                <Input type="text" name="userFirstname" value={this.state.userFirstname} id="user_firstname" placeholder="User Firstname" onChange={this.handleChangeFirstname}/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="category">Lastname</Label>
                                <Input type="text" name="userLastname" value={this.state.userLastname} id="user_lastname" placeholder="User Lastname" onChange={this.handleChangeLastname}/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="category">Password</Label>
                                <Input type="text" name="userPassword" defaultValue={this.state.userPassword} id="user_password" placeholder="User Password" />
                            </FormGroup>

                            <FormGroup check>
                                <Label check>
                                <Input type="checkbox" checked={this.state.active || false} onChange={this.handleChangeActive}/>{' '}
                                    Active
                                </Label>
                            </FormGroup>

                            <Button className="mt-4" onClick={this.onUpdateClick}>Update User</Button>

                            <hr></hr>

                            <FormGroup className={this.state.display}>
                                <Label for="publisher">Add Network & Role</Label>
                                <Row>
                                    <Col md="4">
                                        <Select
                                        value={ this.state.selectedNetwork }
                                        name="networks"
                                        options={ this.state.networkData }
                                        onChange={this.handleChangeNetworkNew}
                                        />
                                    </Col>

                                    <Col md="4">
                                        <Select
                                        value={ this.state.selectedRole }
                                        name="roles"
                                        options={ this.state.rolesData }
                                        onChange={this.handleChangeRoleNew}
                                        />
                                    </Col>

                                    <Col md="4">
                                        <Button onClick={this.userRoleNetwork} color="success">Add</Button>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                {this.state.networksRoleList.map((item, index) => (
                                    <ListGroup  key={index}>
                                        <ListGroupItem>
                                        <span>{item.name}</span>
                                        <Button type="button" className="float-right" color="danger" size="sm" onClick={() => this.confirmRemove(item.id)}>
                                            Remove
                                        </Button>
                                        </ListGroupItem>
                                    </ListGroup>
                                ))}
                            </FormGroup>
                    </Form>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Edit)