console.log(`REACT_APP_ENV: ${process.env.REACT_APP_ENV}`);
const _environment = process.env.REACT_APP_ENV === "production" ? "production" : "development";
console.log(`_environment: ${_environment}`);

const environment = "production";

const userDomain =
  environment === "production"
    ? "usermanagement.zoomin.tv"
    : "usermanagement-dev.zoomin.tv";
const cmsDomain =
  environment === "production"
    ? "cmsmanagement.zoomin.tv"
    : "cmsmanagement-dev.zoomin.tv";
const assetDomain =
  environment === "production"
    ? "assetmanagement.zoomin.tv"
    : "assetmanagement-dev.zoomin.tv";
const filemediaDomain =
  environment === "production"
    ? "filemedia.zoomin.tv"
    : "filemedia-dev.zoomin.tv";
const filecdnDomain =
  environment === "production" ? "filecdn.zoomin.tv" : "filecdn-dev.zoomin.tv";

export const zoomUserAPI = `https://${userDomain}/api/users`;
export const zoomUserNetworkAPI = `https://${userDomain}/api/networkusers`;
export const zoomCmsAPI = `https://${cmsDomain}/api`;
export const zoomAssetAPI = `https://${assetDomain}/api`;
export const zoomMediaAPI = `https://${filemediaDomain}/api`;
export const cdnPath = `https://${filecdnDomain}`;
