import React, { Component } from 'react'
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { getCultures } from 'services/cultureService';
import { getCategories, getCategoryByCultureId } from 'services/categoryService';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getZoomNetworkUsers } from 'services/zoomUserService';

export default class Filter extends Component {

    constructor(){
        super();       
        this.state = {
            cultureData: [],
            categoryData: [],
            userData: [],
            defaultCultureId: 8, // English culture id
            selectedCulture: null,
            selectedCategory: null,
            selectedKeyword: null,
            selectedMinDate: null,
            selectedMaxDate: null,
            filterTitle: "",
            filterDescription: "",
            selectedUser: 0,
            filterAssetId: ""
        }
    }

    getCategoriesByCultureId = async cultureId => {
        const token = window.zoomCrypt.get("token");
        if(cultureId === 0) {
            this.setState({
                categoryData: [],
                selectedCategory: null
            })
            return;
        }
        const response = await getCategoryByCultureId(token, cultureId);
        if(response.success){
            let initialCategories = [];
            response.category.map((category) => {
                if(category.IsActive){
                    initialCategories.push({
                        label: category.IsIab ? `${category.Name} ${category.ShortName}` : category.Name,
                        value: category.Id,
                    })
                }
            })

            this.setState({
                categoryData: initialCategories.sort((a, b) => a.label.localeCompare(b.label)),
                selectedCategory: null
            })

        }else {
            this.setState({
                categoryData: [],
                selectedCategory: null
            })
        }
    }

    getCulture = async () =>{
        if(this.state.selectedCulture === null){
            const token = window.zoomCrypt.get("token");
            const response = await getCultures(token);
            if(response.success){
                let initialCultures = [{label: ' All Cultures', value: 0}];
                response.culture.map((culture) => {
                    if(culture.IsActive){
                        initialCultures.push({
                            label: culture.Name,
                            value: culture.Id,
                        })
                    }
                })

                this.setState({
                    cultureData: initialCultures.sort((a, b) => a.label.localeCompare(b.label))
                })
            }       
        }
        let culture = localStorage.getItem('TheSelectedCulture') ? JSON.parse(localStorage.getItem('TheSelectedCulture')) : '';
         
        this.setState({selectedCulture: culture})
        await this.getCategoriesByCultureId(culture.value ? culture.value : 0)
        
         
    }

    getCategories = async () =>{
        const token = window.zoomCrypt.get("token");
        const response = await getCategories(token);
        if(response.success){
            let initialCategories = [];
            response.category.map((category) => {
                if(category.IsActive){
                    initialCategories.push({
                        label: category.Name,
                        value: category.Id,
                    })
                }
            })

            this.setState({
                categoryData: initialCategories.sort((a, b) => a.label.localeCompare(b.label))
            })
        }
    }

    getUsers = async () =>{
        const token = window.zoomCrypt.get("token");        
        const networkId = window.zoomCrypt.get("networkId");
        const response = await getZoomNetworkUsers(token, networkId);  
        if(response.success){
            let initialUsers = [{label: ' All Users', value: 0}];            
            response.users.map((user) => {
                if(user.isactive){
                    initialUsers.push({
                        label: user.firstname + ' ' + user.lastname,
                        value: user.Id,
                    })
                }
            })

            this.setState({
                userData: initialUsers.sort((a, b) => a.label.localeCompare(b.label))
            })
        }
    }

    handleChangeCulture = async selectedCulture => {        
        this.props.onChangeCultureSelect(selectedCulture);
        this.setState({ selectedCulture })
        
        localStorage.setItem('TheSelectedCulture', JSON.stringify(selectedCulture));
        await this.getCategoriesByCultureId(selectedCulture.value)        
        
    }

    handleChangeCategory = selectedCategory => {
        this.props.onChangeCategorySelect(selectedCategory);
        this.setState({ selectedCategory })
    }

    handleChangeKeywords = selectedKeyword => {
        this.props.onChangeKeywordSelect(selectedKeyword);
        this.setState({ selectedKeyword })
    }

    handleMinSelectedDate = selectedMinDate => {
        this.props.onChangeMinDate(selectedMinDate);
        this.setState({ selectedMinDate })
    }

    handleMaxSelectedDate = selectedMaxDate => {
        this.props.onChangeMaxDate(selectedMaxDate);
        this.setState({ selectedMaxDate })
    }

    handleChangeTitle = e => {
        this.props.onChangeTitle(e.target.value);
        let filterTitle = e.target.value;
        this.setState({ filterTitle })
    }

    handleChangeDescription = e => {
        this.props.onChangeDescription(e.target.value);
        let filterDescription = e.target.value;
        this.setState({ filterDescription });
    }

    handleChangeUser = selectedUser => {
        this.props.onChangeUserSelect(selectedUser);
        this.setState({ selectedUser })
    }

    handleChangeAssetId = e => {
        this.props.onChangeAssetId(e.target.value);
        let filterAssetId = e.target.value;
        this.setState({ filterAssetId })
    }

    async componentDidMount(){
       await this.getCulture();
       if(this.props.showUserFilter)
        await this.getUsers();
       //await this.getCategories();
    }

    render() {
        return (
            <Form>
                <h2 style={{borderBottom: "2px solid #000", marginBottom: "20px"}}>Assets Filter</h2>
                <FormGroup>
                    <Label for="filterSelect">Asset Id</Label>
                    <Input type="number"
                           name="filterAssetId"
                           id="filterAssetId"
                           placeholder="Asset Id"
                           onChange={ this.handleChangeAssetId } />
                </FormGroup>
                <FormGroup>
                    <Label for="filterSelect">Title</Label>
                    <Input type="text"
                           name="filterTitle"
                           id="filterTitle"
                           placeholder="Title"
                           onChange={ this.handleChangeTitle } />
                </FormGroup>

                <FormGroup>
                    <Label for="filterSelect">Description</Label>
                    <Input type="text"
                           name="filterDescription"
                           id="filterDesc"
                           placeholder="Description"
                           onChange={ this.handleChangeDescription }/>
                </FormGroup>

                <FormGroup>
                    <Label for="filterSelect">Language</Label>
                    <Select
                        value={ this.state.selectedCulture }
                        name="culture"
                        placeholder="Select Culture"
                        options={ this.state.cultureData }
                        onChange={ this.handleChangeCulture }
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="filterSelect">Category</Label>
                    <CreatableSelect
                        value={ this.state.selectedCategory }
                        isMulti
                        name="keywords"
                        options={ this.state.categoryData }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={ this.handleChangeCategory }
                    />
                </FormGroup>

                <FormGroup>
                    <Label for="filterSelect">Keyword</Label>
                    <CreatableSelect
                        value={ this.state.selectedKeyword }
                        isMulti
                        name="keywords"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={ this.handleChangeKeywords }
                        components={
                            {
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null
                            }
                        }
                    />
                </FormGroup>
                {this.props.showUserFilter ?
                <FormGroup>
                    <Label for="filterSelect">User</Label>
                    <Select
                        value={ this.state.selectedUser }
                        name="user"
                        placeholder="Select User"
                        options={ this.state.userData }
                        onChange={ this.handleChangeUser }
                    />
                </FormGroup> : ''}
                {this.props.showDates !== false ? 
                <FormGroup>
                    <Label for="filterSelect">Min. Published Videos Date</Label>
                    <Input
                        max="9999-12-31"
                        onKeyPress={this.resetInput}
                        type="date"
                        name="date"
                        id="minDateTime"
                        placeholder="Min Publicated Date"
                        onChange={ this.handleMinSelectedDate }
                    />
                </FormGroup> : ''}
                {this.props.showDates !== false ? 
                <FormGroup>
                    <Label for="filterSelect">Max. Published Videos Date</Label>
                    <Input
                        max="9999-12-31"
                        type="date"
                        name="date"
                        id="maxDateTime"
                        placeholder="Max Publicated Date"
                        onChange={ this.handleMaxSelectedDate }
                    />
                </FormGroup> : ''}            
            </Form>
        )
    }
}
