import React, { Component } from 'react'
import { Badge, Button, Container, Row, Col, Card, CardBody, CardTitle, CardText, Form, FormGroup, Input, Label, } from 'reactstrap';
import Header from '../../components/Headers/Header';
import Filter from '../../components/Filters/Filter';
import { getScheduledAssets, setSchedule, setWithdraw, removeAsset  } from 'services/assetService';
import GeneralModal from '../../components/Modals/GeneralModal';
import { getCultures } from 'services/cultureService';
import { getZoomUser } from 'services/zoomUserService';
import EllipsisText from "react-ellipsis-text";
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { withAlert } from 'react-alert'

class Index extends Component {

    constructor(){
        super();
        this.state = {
            assetData: [],
            filteredData: [],
            cultureData: [],
            limit: 20,
            offset: 0,
            totalPage: 0,
            cultureId: localStorage.getItem('TheSelectedCulture') ? JSON.parse(localStorage.getItem('TheSelectedCulture')).value : 0,
            categoryFilterData: [],
            keywordFilterData: [],
            selectedFilterMinDate: null,
            selectedFilterMaxDate: null,
            filterTitle: "",
            filterDescription: "",
            currentUserNetworks: [],
            scheduleModal: false,
            selectedDate: null,
            selectedTime: null,
            scAssetId: null,
            filterAssetId: ""
        }

        this.token = window.zoomCrypt.get("token");
        this.network = window.zoomCrypt.get("networkId");
        this.sub = window.zoomCrypt.get("sub");
    }

    async getUser(){
        const response = await getZoomUser(this.token, this.sub, this.network);
        if(response.success){
            response.user.Networks.forEach((n) => {
                this.setState({
                    currentUserNetworks: [...this.state.currentUserNetworks, n.NetworkId]
                })
            })
        }
    }

    getScheduledAssets = async (limit, offset, cultureId, title, description, category, keyword, minPublicatedDate, maxPublicatedDate) => {
        cultureId = this.state.cultureId !== 0 ? this.state.cultureId : undefined;
        let assetId = this.state.filterAssetId != "" ? this.state.filterAssetId : undefined;

        if(this.state.filterTitle !== '')
            title = this.state.filterTitle;
        
        if(this.state.filterDescription !== '')
            description = this.state.filterDescription;

        if(this.state.categoryFilterData !== [])
            category = this.state.categoryFilterData;
        
        if(this.state.keywordFilterData !== [])
            keyword = this.state.keywordFilterData;

        if(this.state.selectedFilterMinDate !== null) {
            minPublicatedDate = this.state.selectedFilterMinDate;
        }

        if(this.state.selectedFilterMaxDate !== null) {
            maxPublicatedDate = this.state.selectedFilterMaxDate;
        }
        
        const response = await getScheduledAssets(this.token, parseInt(this.network), limit, offset, cultureId, title, description, category, keyword, minPublicatedDate, maxPublicatedDate, assetId);
            let initialAssets = [];
            if(response.assets.length > 0) {
                initialAssets = response.assets.map((asset) => {
                    return asset;
                })
            }

                this.setState({
                    assetData: initialAssets,
                    filteredData: initialAssets,
                    totalPage: Math.ceil(response.totalRowCount / 20)
                })
    }

    getCultures = async () => {
        const response = await getCultures(this.token);
        let initialCultures = [];
        if(response.success){
            response.culture.forEach((culture) => {
                initialCultures.push({
                    value: culture.Id,
                    label: culture.Name,
                })
            })

            this.setState({
                cultureData: initialCultures
            })
        }
    }

    refreshPage() {
        this.getScheduledAssets(this.state.limit, this.state.offset);
    }

    handlePageClick = data => {
        let offset = data.selected * this.state.limit;
        this.setState({ offset: offset }, () => {
            this.getScheduledAssets(this.state.limit, this.state.offset);
        });
    };

    handleFilterChange = data => {
        let cultureId = data;
        this.setState({ cultureId: cultureId }, () => {
            this.getScheduledAssets(this.state.limit, this.state.offset, this.state.cultureId);
        });
    }

    handleFilterTextChange = data => {
        let searchedText = data;
        const currentAssetData = this.state.assetData;
        if(searchedText.length > 2){
            const filteredData = currentAssetData.filter(item =>
                item.data.Title.toLowerCase().includes(searchedText.toLowerCase())).map(searchedItems => {
                    return searchedItems;
                });

                this.setState({
                    filteredData
                })
        }else{
            this.setState({
                filteredData: this.state.assetData
            })
        }
    }   
    
    handleSelectedDate = selectedDate => {        
        this.setState({ selectedDate: selectedDate.target.value });
    }

    handleSelectedTime = selectedTime => {
        this.setState({ selectedTime: selectedTime.target.value });
    }

    setScheduled = async () => {
        const alert = this.props.alert;
        let dateTime = moment(this.state.selectedDate + ' ' + this.state.selectedTime, 'DD/MM/YYYY HH:mm');
        if(this.state.scAssetId !== null && this.state.selectedDate !== null && this.state.selectedTime !== null){
            if(new Date(dateTime._i) < new Date()){                
                alert.show('You can not select a date smaller than today');
            }
            else {                
                const response = await setSchedule(this.token, this.network, this.state.scAssetId, new Date(dateTime._i).toISOString());
                if(response.status === 200){
                    this.refreshPage();
                }else{                    
                    alert.show('An error occured while scheduling the asset');
                }
            }            
        }
        else {
            alert.show('Please select date & time');
        }
    }

    scheduleToggle = (assetId) => {
		this.setState({
            scheduleModal: !this.state.scheduleModal,
            scAssetId: assetId
        })
    }

    confirmSetScheduled = (assetId) => {
        confirmAlert({
            title: 'Set Scheduled',
            message: 'Are you sure you want to reschedule the asset ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.setScheduled(assetId)
            },
            {
                label: 'No',
            }
            ]
        });
    };

    confirmSetWithdrawn = (assetId) => {
        confirmAlert({
            title: 'Set Withdrawn',
            message: 'Are you sure you want to withdrawn ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.setWithdrawn(assetId)
            },
            {
                label: 'No',
            }
            ]
        });
    };    

    setWithdrawn = async (assetId) => {
        const response = await setWithdraw(this.token, this.network, assetId);
        if(response.status === 200){
            this.refreshPage();
        }
        else{
            const alert = this.props.alert;
            alert.show('An error occured while withdrawing the asset');
        }
    }

    confirm = (assetId) => {
        confirmAlert({
            title: 'Remove Scheduled',
            message: 'Are you sure you want to delete ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.remove(assetId)
            },
            {
                label: 'No',
            }
            ]
        });
    };

    resetDates() {
        this.setState({ selectedFilterMinDate: null }, () => {
            document.getElementById("minDateTime").value = ""
            this.getScheduledAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.categoryFilterData, this.state.keywordFilterData);
        });

        this.setState({ selectedFilterMaxDate: null }, () => {
            document.getElementById("maxDateTime").value = ""
            this.getScheduledAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.categoryFilterData, this.state.keywordFilterData);
        });
    }

    remove = async (assetId) => {
        const response = await removeAsset(this.token, this.network, assetId);
        if(response.status === 200){
            this.refreshPage();
        }
        else{
            const alert = this.props.alert;
            alert.show('An error occured while removing the asset');
        }
    }

    handleFilterTitle = filterTitle => {
        this.setState({ offset: 0, selectedPage: 0, filterTitle }, () => {
            this.getScheduledAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }

    handleFilterDescription = filterDescription => {
        this.setState({ offset: 0, selectedPage: 0, filterDescription }, () => {
            this.getScheduledAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }


    cultureNameConverter(cultureId) {
        return this.state.cultureData.map((culture) => {
            if(cultureId === culture?.value){
                return culture.label
            }
        })
    }

    handleChangeCulture = data => {
        let cultureId = data.value;
        this.setState({ offset: 0, selectedPage: 0, cultureId: cultureId,  categoryFilterData: [] }, () => {
            this.getScheduledAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }

    handleChangeCategory = data => {
        let category = [];
        if(data !== null){
            data.forEach(d => {
                category.push(d.value)
            })
        }else{
            this.getScheduledAssets(this.state.limit, this.state.offset);
        }

        this.setState({ offset: 0, selectedPage: 0,
            categoryFilterData: category
        }, () => {
            this.getScheduledAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData);
        })
    }

    handleChangeKeyword = data => {
        let keyword = [];
        if(data !== null){
            data.forEach(d => {
                keyword.push(d.label)
            })
        }else{
            this.getScheduledAssets(this.state.limit, this.state.offset);
        }
        this.setState({ offset: 0, selectedPage: 0,
            keywordFilterData: keyword
        }, () => {
            this.getScheduledAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData);
        })
    }

    handleSelectedFilterMinDate = selectedFilterMinDate => {
        if(selectedFilterMinDate.target.value !== ""){
            this.setState({ offset: 0, selectedPage: 0, selectedFilterMinDate: selectedFilterMinDate.target.value }, () => {
                this.getScheduledAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData, new Date(this.state.selectedFilterMinDate).toISOString());
            });
        }
    }

    handleSelectedFilterMaxDate = selectedFilterMaxDate => {
        if(selectedFilterMaxDate.target.value !== ""){
            this.setState({ offset: 0, selectedPage: 0, selectedFilterMaxDate: selectedFilterMaxDate.target.value }, () => {
                this.getScheduledAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription, this.state.categoryFilterData, this.state.keywordFilterData, new Date(this.state.selectedFilterMinDate).toISOString(), new Date(this.state.selectedFilterMaxDate).toISOString());
            });
        }
    }

    handleFilterAssetId = filterAssetId => {
        this.setState({ offset: 0, selectedPage: 0, filterAssetId }, () => {
            this.getScheduledAssets(this.state.limit, this.state.offset, this.state.cultureId, this.state.filterTitle, this.state.filterDescription);
        });
    }

    assetCardList(){
        return this.state.filteredData.map((asset, index) => {
            return (
                <React.Fragment key={ index }>
                { this.network === asset.networkId ? 
                    <div className="assetActionArea" key={index}>
                        <span className="mr-2"><strong>ID: </strong> {asset.id} </span>
                        <Button onClick={() => this.scheduleToggle(asset.id)} size="sm" color="info">ReSchedule</Button>
                        <Button onClick={() => this.confirmSetWithdrawn(asset.id)} size="sm" color="secondary">Set Withdrawn</Button>
                        <Button onClick={() => this.confirm(asset.id)} size="sm" color="danger">Remove</Button>
                    </div> : ""
                }
                <a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push(`scheduled-edit/asset/${asset.id}`)}}>
                    <Card className="mb-4" >
                        <Row className="no-gutters">
                            <Col md="4">
                                <img src={ asset.data.ImageRemotePath !== null ? asset.data.ImageRemotePath.replace('http://images.','https://images.') + asset.data.ImageFileName : "https://dummyimage.com/600x400/000/fff.png&text=Image+Not+Found"} alt="" className="card-img-top"/>
                            </Col>

                            <Col md="7" className="mt-2">
                                <CardBody>
                                    <CardTitle className="mb-0">{ asset.data.Title }</CardTitle>
                                    <Badge className="badge-default" pill>
                                    { this.cultureNameConverter(asset.cultureId) }
                                    </Badge>
                                    <CardText>
                                        { asset.data.Description !== undefined
                                        ? <EllipsisText text={ asset.data.Description } length={180} />
                                        : asset.data.Description }
                                    </CardText>
                                </CardBody>
                            </Col>

                            <Col md="1" className="text-center mt-5">
                                <div className="large">{ asset.data.PublicatedDate !== null ? moment(asset.data.PublicatedDate).format("DD") : moment(asset.data.CreatedDate).format("DD") }</div>
                                <div className="text-muted">{ asset.data.PublicatedDate !== null ? moment(asset.data.PublicatedDate).format("MMM") : moment(asset.data.CreatedDate).format("MMM") }</div>
                                <div className="text-muted">
                                    <span className="small">{ asset.data.PublicatedDate !== null ? moment(asset.data.PublicatedDate).format("YYYY") : moment(asset.data.CreatedDate).format("YYYY") }</span>
                                </div>
                                <div className="text-muted">
                                    <Badge className="badge-default">{ asset.data.PublicatedDate !== null ? moment(asset.data.PublicatedDate).format("HH:mm") : moment(asset.data.CreatedDate).format("HH:mm") }</Badge>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </a>
                </React.Fragment>
            )
          })
    }

    componentDidMount(){
        this.getUser();
        this.getScheduledAssets(this.state.limit, this.state.offset);
        this.getCultures();
    }

    render() {
        return (
            <div>
                <Header/>
                <Container className="mt-10">
                    <h1>Scheduled Latest Videos</h1>
                    <Row>
                        <Col md="9">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.state.totalPage}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            breakLinkClassName={'page-link'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                        ></ReactPaginate>
                        {this.state.assetData.length > 0 ?
                            this.assetCardList() :
                            <h1>No Content</h1>
                        }

                        </Col>
                        <Col md="3">
                        <Filter onChangeCategorySelect={this.handleChangeCategory}
                                    onChangeCultureSelect={this.handleChangeCulture}
                                    onChangeKeywordSelect={this.handleChangeKeyword}
                                    onChangeMinDate={this.handleSelectedFilterMinDate}
                                    onChangeMaxDate={this.handleSelectedFilterMaxDate}
                                    onChangeTitle={this.handleFilterTitle}
                                    onChangeDescription={this.handleFilterDescription}
                                    onChangeAssetId={this.handleFilterAssetId}>
                            </Filter>
                            <FormGroup>
                                <Button onClick={this.resetDates.bind(this)}>Reset Dates</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    <GeneralModal
                        isOpen={this.state.scheduleModal}
                        toggle={this.scheduleToggle}
                        title='Set Schedule'
                        primaryButton='Set Schedule'
                        className='modal-content bg-light'
                        secondaryButton='Cancel'
                        onAction={() => this.confirmSetScheduled()}>
                        <Form>
                        <FormGroup>
                            <Label for="scheduleDateTime">Datetime</Label>
                            <Input
                            type="date"
                            name="date"
                            id="scheduleDateTime"
                            placeholder="Datetime"
                            onChange={this.handleSelectedDate}
                            />

                            <Input
                            className="mt-4"
                            type="time"
                            name="time"
                            id="scheduleTime"
                            placeholder="Time"
                            onChange={this.handleSelectedTime}
                            />
                        </FormGroup>
                        </Form>
                    </GeneralModal>
                </Container>
            </div>
        )
    }
}

export default withAlert()(Index)