import { zoomCmsAPI } from './config';
import { validateResponse } from './validateResponse';

const contractAPI = `${zoomCmsAPI}/contract`;

export async function getContracts(token, networkId){
    const response = await fetch(contractAPI, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
		},
    });
    return await validateResponse(response);
}

export async function getContractById(token, networkId, contractId){
    const response = await fetch(`${contractAPI}/${contractId}`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
		},
    });
    return await validateResponse(response);
}

export async function createContract(token, isActive, networkIdFirst, networkIdSecond ){
    const response = await fetch(`${contractAPI}/create`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify({
            isActive: isActive,
            networkId1: networkIdFirst,
            networkId2: networkIdSecond
        })
    });
    return response;
}

export async function updateContract(token, contractId, name, isActive, networkIdFirst, networkIdSecond){
    const response = await fetch(`${contractAPI}/${contractId}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify({
            name: name,
            isActive: isActive,
            NetworkId1: networkIdFirst,
            NetworkId2: networkIdSecond
        })
    });
    return response;
}

export async function removeContract(token, networkId, contractId){
    const response = await fetch(`${contractAPI}/${contractId}`, {
        method: "DELETE",
        headers: {
            'Accept': 'application/json',
			'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'networkId': networkId,
        },
        body: JSON.stringify({
            id: contractId,
        })
    });
    return response;
}