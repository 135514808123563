import { zoomCmsAPI } from './config';
import { validateResponse } from './validateResponse';

const publisherCategoryAPI = `${zoomCmsAPI}/publishercategory`;

export async function getPublisherCategories (token) {
  const response = await fetch(publisherCategoryAPI, {
    method: "GET",
    headers: {
      'Authorization': `Bearer ${token}`
    },
  });
  return await validateResponse(response);
}


export async function createPublisherCategory (token, networkId, publisherId, categoryId) {
  return await fetch(`${publisherCategoryAPI}/create`, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'networkId': networkId
    },
    body: JSON.stringify({
      publisherId: publisherId,
      categoryId: categoryId
    })
  });
}

export async function removePublisherCategory (token, networkId, publisherId) {
  return await fetch(`${publisherCategoryAPI}/${publisherId}`, {
    method: "DELETE",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'networkId': networkId
    },
    body: JSON.stringify({
      Id: publisherId,
    })
  });
}
