import React, {Component} from 'react';
import {Button, Container, Form, FormGroup, Label} from 'reactstrap';
import {createPublisherCategory, getPublisherCategories, removePublisherCategory} from "services/publisherCategory";
import {getPublishers} from "services/publisherService";
import {getIabCategories} from "services/categoryService";
import Header from '../../components/Headers/Header';
import GeneralModal from '../../components/Modals/GeneralModal';
import Select from 'react-select';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import {withAlert} from 'react-alert'
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'

class Index extends Component {
  constructor (props) {
    super(props);
    this.state = this.defaultState();

    this.selectedCategories = [];
    this.token = window.zoomCrypt.get("token");
    this.networkId = window.zoomCrypt.get("networkId");
  }

  defaultState () {
    return {
      publisherCategoryData: [],
      publisherData: [],
      categoryData: [],
      categoryTree: {},
      selectedPublisher: null,
      selectedCategories: [{ label: 'All', value: 'all' }],
      selectedCategoryTree: [],
      openModal: false,
    };
  }

  refreshPage () {
    this.setState(this.defaultState(), () => this.getData());
  }

  handleSelectedPublisher = (selectedPublisher) => {
    this.setState({ selectedPublisher });
  };

  onSaveClick = async (e) => {
    e && e.preventDefault();

    const alert = this.props.alert;
    const publisherId = this.state.selectedPublisher?.value;
    const categories = this.revealSelectedCategoriesIds();

    if (publisherId && categories.length) {
      const response = await createPublisherCategory(this.token, this.networkId, publisherId, categories);

      if (response.status === 200) {
        this.refreshPage();
      } else {
        alert.show("An error occurred while trying to create. Please try again");
      }
    } else {
      alert.show("Please select valid values while creating publisher categories");
    }
  };

  revealSelectedCategoriesIds () {
    const categories = this.selectedCategories;
    const tree = this.state.categoryTree;
    let nodes = categories;

    if (categories.length === 1 && categories[0].value === 'all') {
      nodes = (Array.isArray(tree) ? tree[0].children : tree.children) || [];
    }

    return nodes.map(({ value }) => value);
  }

  toggle = () => {
    this.setState({ openModal: !this.state.openModal });
  }

  confirm = (pCategoryId) => {
    confirmAlert({
      title: 'Remove Publisher Category',
      message: 'Are you sure you want to delete ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.remove(pCategoryId)
        },
        {
          label: 'No',
        }
      ]
    });
  };

  remove = async (pCategoryId) => {
    const alert = this.props.alert;
    const response = await removePublisherCategory(this.token, this.networkId, pCategoryId);

    if (response.status === 204) {
      this.refreshPage();
    } else {
      alert.show('An error occurred while trying to delete. Please try again');
    }
  }

  filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;

    return row[id] === 'undefined' || String(row[id].toLowerCase()).includes(filter.value.toLowerCase());
  }

  async componentDidMount () {
    await this.getData();
  }

  getData () {
    return Promise.all([
      this.getPublisherCategories(),
      this.getPublishers(),
      this.getActiveCategories()
    ]);
  }

  getPublisherCategories = async () => {
    const response = await getPublisherCategories(this.token, this.networkId);
    let initialPCategories = [];

    if (response.success) {
      response.publisherCategories.map((pCategory) => {
        initialPCategories.push({
          id: pCategory.Id,
          categoryId: pCategory.CategoryId,
          publisherId: pCategory.PublisherId,
          categoryName: pCategory.CategoryName,
          publisherName: pCategory.PublisherName,
        })
      })
    }

    this.setState({ publisherCategoryData: initialPCategories });
  };

  getPublishers = async () => {
    const response = await getPublishers(this.token);
    if (response.success) {
      const initialPublishersOptions = response.publishers.map(({ Id, Name, Pid }) => {
        return { value: Id, label: `${Name} - ${Pid}` };
      });

      this.setState({
        publisherData: initialPublishersOptions.sort((a, b) => a.label.localeCompare(b.label))
      });
    }
  };

  getActiveCategories = async () => {
    const response = await getIabCategories(this.token);

    if (response.success) {
      const activeCategories = response.category.filter((category) => category.IsActive );
      const categoryDict = Index._buildDictFromCollection(activeCategories, 'Id');
      const categoryTree = Index._buildInitialCategoryTree(categoryDict);

      const selectedCategoryTree = [{
        label: 'All',
        value: 'all',
        expanded: true,
        checked: true,
        parentId: null,
        children: categoryTree
      }];

      this.selectedCategories = [{ label: 'All', value: 'all' }];
      this.setState({ categoryTree: selectedCategoryTree, selectedCategoryTree });
    }
  };

  static _buildDictFromCollection (items = [], key = 'id') {
    if (!Array.isArray(items) || !items.length) {
      return {};
    }

    return items.reduce((accumulator, item) => {
      if (item[key]) {
        accumulator[item[key]] = item;
      }

      return accumulator;
    }, {});
  }

  // Note: handles only two layers (depth = 2).
  static _buildInitialCategoryTree (categoryDict) {
    const tree = Object.values(categoryDict).reduce((accumulator, item) => {
      const { Id, ParentId: parentId, IsIab, Name, ShortName } = item;
      const label = IsIab ? `${Name} ${ShortName}` : Name;

      if (parentId) {
        accumulator[parentId] = accumulator[parentId] || {};
        accumulator[parentId].children = accumulator[parentId].children || {};
        accumulator[parentId].children[Id] = { label, value: Id, parentId };
      } else {
        accumulator[Id] = { label, value: Id, parentId };
      }

      return accumulator;
    }, {});

    return Index._buildDropdownTreeData(tree);
  }

  static _buildDropdownTreeData (tree = {}) {
    return Object.values(tree).map((parent) => {
      if (parent.children) {
        const children = Index._buildDropdownTreeData(parent.children)
          .sort((a, b) => a.label.localeCompare(b.label));

        return {...parent, children };
      }

      return parent;
    }).sort((a, b) => a.label.localeCompare(b.label));
  }

  handleSelectedCategories = (currentNode, selectedNodes) => {
    this.selectedCategories = selectedNodes.map(({ value, label }) => ({ value, label }));
  }

  render() {
    const columns = [
      {
        Header: "Publisher",
        accessor: "publisherName"
      },
      {
        Header: "Category",
        accessor: "categoryName"
      },
      {
        Header: "Remove",
        Cell: props => {
          return (
            <Button onClick={() => this.confirm(props.original.id)} color="danger">Remove</Button>
          )
        },
        accessor: "remove",
        filterable: false,
        sortable: false
      }
    ];

    return (
      <div>
        <Header />
        <Container className="mt-10">
          <ReactTable
            className="zoom-table"
            columns={columns}
            filterable
            defaultFilterMethod={this.filterCaseInsensitive}
            showPaginationTop={true}
            data={this.state.publisherCategoryData}>
          </ReactTable>
          <GeneralModal
            isOpen={this.state.openModal}
            toggle={this.toggle}
            title='Create Publisher Category'
            primaryButton='Create'
            className='modal-content bg-light'
            secondaryButton='Cancel'
            onAction={this.onSaveClick}>
            <Form>
              <FormGroup>
                <Label for="publisherSelect">Publishers</Label>
                <Select
                  value={this.state.selectedPublisher}
                  options={this.state.publisherData}
                  onChange={this.handleSelectedPublisher}
                />
              </FormGroup>
              <FormGroup>
                <Label for="categoryTreeSelect">Categories</Label>
                <DropdownTreeSelect
                  data={this.state.categoryTree}
                  texts={{ placeholder: 'Search...', noMatches: 'No options' }}
                  onChange={this.handleSelectedCategories}
                  keepTreeOnSearch={true}
                  keepChildrenOnSearch={true}
                  clearSearchOnChange={false}
                  showPartiallySelected={true}
                  inlineSearchInput={false}
                />
              </FormGroup>
            </Form>
          </GeneralModal>
          <Button className="create-button" color="success" onClick={this.toggle}>
            Create Publisher Category
          </Button>
        </Container>
      </div>
    )
  }
}

export default withAlert()(Index)
