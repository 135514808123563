import Index from "views/Index.js";
import Categories from "views/Category/Index";
import CategoryEdit from "views/Category/Edit";
import Production from "views/Production/Index";
import ProductionEdit from "views/Production/Edit";
import Published from "views/Published/Index";
import PublishedEdit from "views/Published/Edit";
import Publishers from "views/Publisher/Index";
import PublisherEdit from "views/Publisher/Edit";
import Contracts from "views/Contract/Index";
import Networks from "views/Networks/Index";
import NetworkEdit from "views/Networks/Edit";
import Scheduled from "views/Scheduled/Index";
import ScheduledEdit from "views/Scheduled/Edit";
import Users from "views/Users/Index";
import UserEdit from "views/Users/Edit";
import Withdrawn from "views/Withdrawn/Index";
import WithdrawnEdit from "views/Withdrawn/Edit";
import Cultures from "views/Culture/Index";
import CultureEdit from "views/Culture/Edit";
import SetAsset from "views/SetAsset/Index";
import PublisherAssets from "views/PublisherAssets/Index";
import Tasks from "views/Tasks/Index";
import LocalizerTasks from "views/LocalizerTasks/Index";
import TaskDetail from "views/Tasks/Detail";
import ParentTaskDetail from "views/Tasks/ParentDetail";
import MyTasks from "views/MyTasks/Index";
import ProcessTask from "views/MyTasks/ProcessTask";
import PublisherCategory from "views/PublisherCategory/Index";
import WhiteLabel from "views/WhiteLabel/Index";
import WhiteLabelEdit from "views/WhiteLabel/Edit";
import PublisherLanguage from "views/PublisherLanguage/index"

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/production",
    name: "Production",
    icon: "ni ni-building text-blue",
    component: Production,
    layout: "/admin"
  },
  {
    path: "/published",
    name: "Published",
    icon: "ni ni-spaceship text-indigo",
    component: Published,
    layout: "/admin"
  },
  {
    path: "/scheduled",
    name: "Scheduled",
    icon: "ni ni-time-alarm text-purple",
    component: Scheduled,
    layout: "/admin"
  },
  {
    path: "/withdrawn",
    name: "Withdrawn",
    icon: "ni ni-archive-2 text-pink",
    component: Withdrawn,
    layout: "/admin"
  },
  {
    path: "/cultures",
    name: "Languages",
    icon: "ni ni-planet text-red",
    component: Cultures,
    layout: "/admin",
    zoomin: true
  },
  // todo: commented out but once we will test new iab categorization then we should remove route along with view component.
  /*
  {
    path: "/categories",
    name: "Categories",
    icon: "ni ni-books text-orange",
    component: Categories,
    layout: "/admin",
    zoomin: true
  },
  */
  {
    path: "/publishers",
    name: "Publishers",
    icon: "ni ni-camera-compact text-yellow",
    component: Publishers,
    layout: "/admin"
  },
  {
    path: "/publisher-category",
    name: "Publisher Category",
    icon: "ni ni-ui-04 text-c8",
    component: PublisherCategory,
    layout: "/admin",
    zoomin: true
  },
  {
    path: "/publisher-language",
    name: "Publisher Language",
    icon: "ni ni-world text-c3",
    component: PublisherLanguage,
    layout: "/admin",
    zoomin: true
  },
  {
    path: "/contracts",
    name: "Contracts",
    icon: "ni ni-badge text-green",
    component: Contracts,
    layout: "/admin",
    zoomin: true
  },
  {
    path: "/networks",
    name: "Networks",
    icon: "ni ni-ungroup text-c4",
    component: Networks,
    layout: "/admin",
    zoomin: true
  },
  {
    path: "/localizer-tasks",
    name: "Localizer Tasks",
    icon: "ni ni-world text-c3",
    component: LocalizerTasks,
    layout: "/admin",
    zoomin: true
  },
  {
    path: "/set-assets",
    name: "Select Videos",
    icon: "ni ni-app text-teal",
    component: SetAsset,
    layout: "/admin"
  },
  {
    path: "/publisher-assets",
    name: "Publisher Assets",
    icon: "ni ni-collection text-cyan",
    component: PublisherAssets,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/tasks",
    name: "Tasks",
    icon: "ni ni-active-40 text-c1",
    component: Tasks,
    layout: "/admin",
    zoomin: true
  },
  {
    path: "/my-tasks",
    name: "My Tasks",
    icon: "ni ni-user-run text-white",
    component: MyTasks,
    layout: "/admin",
    zoomin: true
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-light",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/process-task/:id",
    component: ProcessTask,
    layout: "/admin",
    invisible: true,
    zoomin: true
  },
  {
    path: "/culture/edit/:id",
    component: CultureEdit,
    layout: "/admin",
    invisible: true,
    zoomin: true
  },
  {
    path: "/category/edit/:id",
    component: CategoryEdit,
    layout: "/admin",
    invisible: true,
    zoomin: true
  },
  {
    path: "/network/edit/:id",
    component: NetworkEdit,
    layout: "/admin",
    invisible: true,
    zoomin: true
  },
  {
    path: "/user/edit/:id",
    component: UserEdit,
    layout: "/admin",
    invisible: true,
    zoomin: true
  },
  {
    path: "/publisher/edit/:id",
    component: PublisherEdit,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/production-edit/asset/:id",
    component: ProductionEdit,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/published-edit/asset/:id",
    component: PublishedEdit,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/scheduled-edit/asset/:id",
    component: ScheduledEdit,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/withdrawn-edit/asset/:id",
    component: WithdrawnEdit,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/task-detail/:id",
    component: TaskDetail,
    layout: "/admin",
    invisible: true,
    zoomin: true
  },
  {
    path: "/parent-detail/:id",
    component: ParentTaskDetail,
    layout: "/admin",
    invisible: true,
    zoomin: true
  },
  {
    path: "/whitelabels",
    name: "Whitelabel Site",
    icon: "ni ni-building text-blue",
    component: WhiteLabel,
    layout: "/admin"
  },
  {
    path: "/whitelabel/edit/:id",
    component: WhiteLabelEdit,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/whitelabel/create",
    component: WhiteLabelEdit,
    layout: "/admin",
    invisible: true
  }
];

export default routes;
