import React, { Component } from 'react'
import { Button, Container, Form, FormGroup, Label, Input } from 'reactstrap';
import Header from '../../components/Headers/Header';
import GeneralModal from '../../components/Modals/GeneralModal';
import { getNetworks, removeNetwork, createNetwork } from 'services/networkService';
import { getZoomUser } from 'services/zoomUserService';
import ReactTable from 'react-table';
import "react-table/react-table.css";
import { withAlert } from 'react-alert'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Index extends Component {

    constructor(){
        super();
        this.state = this.defaultState();

        this.token = window.zoomCrypt.get("token");
        this.network = window.zoomCrypt.get("networkId");
        this.sub = window.zoomCrypt.get("sub");
    }

    defaultState() {
        return {
            networkData: [],
            openModal: false,
            networkName: "",
            userId: null,
            active: true,
        };
    }

    refreshPage() {
        this.setState(this.defaultState(), () => this.getDatas());  
    }

    toggle = () => {
		this.setState({openModal: !this.state.openModal})
    }

    getNetworks = async () => {
        const responseNetwork = await getNetworks(this.token);
        let initialCategories = [];

        if(responseNetwork.success){
            responseNetwork.networks.map((network) => {
                initialCategories.push({
                    id: network.Id,
                    name: network.Name,
                })
            })
        }

        this.setState({
            networkData: initialCategories
        })
    }

    async getUser(){
        const response = await getZoomUser(this.token, this.sub, this.network);
        if(response.success){
            this.setState({
                userId: response.user.Id
            })
        }
    }


    onSaveClick = async e => {
        e && e.preventDefault();
        const alert = this.props.alert;
        if(this.state.selectedCategoryStatus !== null && this.state.selectedCulture !== null){
            const response = await createNetwork(this.token, this.state.networkName, this.state.active);
            if(response.status === 200){
                this.refreshPage();
            }
            else {
                alert.show('An error occured while trying to delete. Please try again')
            }
        }else {
            alert.show('Please fill in all fields !')
        }
    }

    confirm = (networkId) => {
        confirmAlert({
            title: 'Remove Network',
            message: 'Are you sure you want to network ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.remove(networkId)
            },
            {
                label: 'No',
            }
            ]
        });
    };

    confirmCreate = () => {
        confirmAlert({
            title: 'Create Network',
            message: 'Are you sure you want to create ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => this.onSaveClick()
            },
            {
                label: 'No',
            }
            ]
        });
    };

    remove = async (networkId) =>{
        const alert = this.props.alert;
        const response = await removeNetwork(this.token, networkId);
        if(response.status === 204)        
            this.refreshPage();
        else            
            alert.show('An error occured while trying to delete. Please try again');

    }

    handleChangeName = e => {
        let networkName = e.target.value;
        this.setState({ networkName });
    }

    filterCaseInsensitive(filter, row) {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    getDatas() {
        this.getNetworks();
        this.getUser();
    } 

    componentDidMount(){
       this.getDatas();
    }

    handleChangeActive = e => {
        let active = e.target.checked;
        this.setState({ active });
    }

    render() {
        const columns = [
            {
                Header: "Name",
                accessor: "name"
            },
            {
                Header: "Edit",
                Cell: props => {
                    return(
                        <a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push(`network/edit/${props.original.id}`)}}> <Button color="primary">Edit</Button></a>
                    )
                },
                accessor: "edit",
                filterable: false,
                sortable: false
            },
            {
                Header: "Remove",
                Cell: props => {
                    return(
                        <Button onClick={() => this.confirm(props.original.id)} color="danger">Remove</Button>
                    )
                },
                accessor: "remove",
                filterable: false,
                sortable: false
            }
        ]

        return (
            <div>
                <Header></Header>
                { window.zoomCrypt.get("roleName") === "Admin" ?
                <Container className="mt-10">
                    <ReactTable
                        className="zoom-table"
                        columns={columns}
                        filterable
                        defaultFilterMethod={this.filterCaseInsensitive}
                        showPaginationTop={true}
                        data={this.state.networkData}>
                    </ReactTable>
                    <GeneralModal
                        isOpen={this.state.openModal}
                        toggle={this.toggle}
                        title='Create Network'
                        primaryButton='Create'
                        className='modal-content bg-light'
                        secondaryButton='Cancel'
                        onAction={this.confirmCreate}>
                        <Form>
                            <FormGroup>
                                <Label for="category">Network Name</Label>
                                <Input type="text" name="networkName" id="network_name" placeholder="Network Name" onChange={this.handleChangeName}/>
                            </FormGroup>
                            <FormGroup check className="mb-3">
                                <Label check>
                                <Input type="checkbox" checked={this.state.active || false} onChange={this.handleChangeActive}/>{' '}
                                    Active
                                </Label>
                            </FormGroup>
                        </Form>
                    </GeneralModal>
                    <Button className="create-button" color="success" onClick={this.toggle}>
                        Create Network
                    </Button>
                </Container> : <h1 className="text-center">You must be an administrator to view this page.</h1> }
            </div>
        )
    }
}

export default withAlert()(Index)
